import React, { useEffect, useState } from "react";
import styles from "./AdminCard.module.sass";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { AdminCardItem } from "./AdminCardItem";
import { URL } from "api";
import PlanCard from "./PlanCard";
import Modal from "../../../components/Modal";
import {
  useTransactionDispatch,
  useTransactionState,
  useUserState,
} from "context";
import { PayModal } from "./PayModal";
import { AdminModal } from "./AdminModal";
import classNames from "classnames";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Icon from "components/Icon";
const AdminCard = ({ onClick, admin, showBox }) => {
  const state = useUserState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [box, setBox] = useState(false);
  const [adminModal, setAdminModal] = useState(null);
  const history = useHistory();
  const onTrx = useTransactionDispatch();
  const { trx } = useTransactionState();
  const onClose = () => {
    setVisibleModal(false);
  };
  const onCloseAdmin = () => {
    setAdminModal(false);
  };
  useEffect(() => {
    if (showBox) {
      setBox(showBox);
    }
  }, [showBox]);
  const onPaid = async (plan) => {
    if (!state?.isAuth) {
      return history.push("/sign-in");
    }
    if (trx) {
      onTrx?.lastTransaction();
      onTrx?.setTrxModal(true);
      return;
    }
    setVisibleModal(plan);
  };

  return (
    <div className={styles.adminItem}>
      <div className={styles.head}>
        <AdminCardItem
          src={`${URL.CdnUrl}${admin?.profile?.image}`}
          title={admin?.name || "Trader"}
          createdAt={admin?.createdAt}
          stats={admin?.stats}
          onShow={() => setAdminModal(true)}
        />
      </div>
      <div
        className={classNames(styles.body, {
          [styles.activeBox]: box,
        })}
      >
        {admin.plans.map((plan, index) => (
          <PlanCard key={index} plan={plan} onClick={() => onPaid(plan)} />
        ))}
      </div>
      <div
        className={classNames(styles.boxBtn, {
          [styles.activeBoxBtn]: box,
        })}
        onClick={() => setBox(!box)}
      >
        <Icon name={box ? "arrow-up" : "arrow-down"} size={28} />
      </div>
      <Modal visible={visibleModal} onClose={onClose}>
        <>
          {visibleModal && (
            <PayModal plan={visibleModal} onClick={onClick} onClose={onClose} />
          )}
        </>
      </Modal>
      <Modal visible={adminModal} onClose={onCloseAdmin}>
        <>{adminModal && <AdminModal item={admin} onClose={onCloseAdmin} />}</>
      </Modal>
    </div>
  );
};

export default AdminCard;
