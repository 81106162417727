import React, { useState } from "react";
import styles from "./ShowSetting.module.sass";
import cn from "classnames";
import Card from "components/Card";

const ShowSetting = ({ title, className, data }) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={title}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div
          className={styles.table}
          dangerouslySetInnerHTML={{
            __html: data?.txt?.replace(/\n/g, "<br />"),
          }}
        ></div>
      </Card>
    </>
  );
};

export default ShowSetting;
