import React from "react";
import styles from "../PayModal.module.sass";
import cn from "classnames";

import { useUserState } from "context";
import Icon from "components/Icon";
export  function Wallet({
    plan,
    onBuyBalance,
    onOtherWallet,
    onWalletConnect,
  }) {
    const user = useUserState();
    return (
      <>
        <div className={styles.foot}>
          {user?.user?.balance >= plan.price && (
            <button
              onClick={onBuyBalance}
              className={cn("button", "button-purple", "mr-4")}
            >
              <span className="white">buy with {user?.user?.balance}</span> $
            </button>
          )}
          <button
            onClick={onOtherWallet}
            className={cn("button", "button-green")}
          >
            <span className="white">Other Wallet</span>
            <Icon name="share" size="24" />
          </button>
          {/* <button
            onClick={onWalletConnect}
            className={cn("button", styles.button)}
          >
            <span className="white">Wallet Connect</span>
            <Icon name="wallet" size="24" viewBox="0 0 24 24" />
          </button> */}
        </div>
      </>
    );
  }