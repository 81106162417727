import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Seo from "components/Seo";
import { useGetSetting } from "api";

const Page = ({ wide, children,seo, title, search = true }) => {
  const [visible, setVisible] = useState(false);
  const { data, refetch } = useGetSetting("companyName");
  return (
    <>
      <Seo
        title={data?.companyName? `${data?.companyName}${seo?` | ${seo}`:''}`: "Home"}
        description={data?.companyName? `${data?.companyName}${seo?` | ${seo}`:''}`: "Home"}
      />
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={() => setVisible(false)}
        />
        <Header search={search} onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {title && <div className={cn("h3", styles.title)}>{title}</div>}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Page);
