import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useParams } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useLoadingDispatch, useUserDispatch } from "../../context";
import {
  URL,
  assets,
  setAuth,
  useGetAdminByAccount,
  useSignInForm,
} from "../../api";
import { Notify } from "../../components/Notify";
import Icon from "../../components/Icon";
import { useHistory } from "react-router";

const SignIn = ({ role: roleui }) => {
  const heightWindow = use100vh();
  const loading = useLoadingDispatch();
  const user = useUserDispatch();
  const [role, setRole] = useState();
  const { username } = useParams();
  const { data, isLoading } = useGetAdminByAccount(username);
  const history = useHistory();
  const [form, onForm, onSignIn] = useSignInForm({
    email: "",
    password: "",
  });
  useEffect(() => {
    if (roleui) {
      setRole(roleui);
    }
    return () => {
      setRole("client");
    };
  }, [roleui, isLoading]);
  const signIn = async (data) => {
    loading.show();
    const response = await onSignIn.mutateAsync({
      isAdmin: role === "master",
      ...data,
    });
    loading.hide();
    if (!response || response?.error) {
      return Notify.error(
        response?.message || "Sorry! email or password not valid."
      );
    } else {
      user.onSignIn(response?.data);
      setAuth(response?.data);
      Notify.success("SignIn successfull!");
      history.push(`/profile`);
      return;
    }
  };
  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <div className={styles.close}>
          <div className={styles.info}>
            <Link className={styles.link} to="/">
              <Icon name="close" size={24} />
            </Link>
          </div>
        </div>
        <Link className={styles.logo} to="/">
          {data?.image ? (
            <Image
              className={styles.image}
              src={assets(data?.image)}
              srcDark={assets(data?.image)}
              alt="Log"
            />
          ) : (
            <Image
              className={styles.pic}
              srcDark={URL.CdnUrl + "/logo/logo-dark.svg"}
              src={URL.CdnUrl + "/logo/logo-light.svg"}
              alt="Log"
            />
          )}
        </Link>
        <div className={cn("h4", styles.title)}>Sign in {data?.name || ""}</div>

        <form className={styles.body} onSubmit={onForm(signIn)}>
          <div className={styles.subtitle}>Continue with email address</div>
          <TextInput
            className={styles.field}
            placeholder="Your email"
            icon="mail"
            error={form.errors.email?.message}
            {...form.email}
          />
          <TextInput
            className={styles.field}
            type="password"
            placeholder="Password"
            icon="lock"
            error={form.errors.password?.message}
            {...form.password}
          />

          <div className={styles.sublink}>
            <Link
              to={
                username ? `/${username}/forgot/password` : "/forgot/password"
              }
              className={styles.sublink}
            >
              Forgot Password
            </Link>
          </div>
          <button
            type="submit"
            className={cn("button", styles.button, {
              "button-green": username === "master",
            })}
          >
            Sign in
          </button>
          {!username && (
            <div className={styles.info}>
              Don’t have an account?{" "}
              <Link className={styles.link} to="/sign-up">
                Sign up
              </Link>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SignIn;
