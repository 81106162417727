/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useReducer } from "react";
import * as action from "./action";
import { Reducer } from "./reducer";
import { TransactionStateContext, TransactionDispatchContext } from "./context";
import { dispatch } from "../tools";

export const TransactionProvider = ({ children }) => {
  const [state, setState] = useReducer(Reducer, {
    trx: null,
    trxModal: false,
    paid: null,
  });
  useEffect(() => {
    if (action?.lastTransaction) {
      action?.lastTransaction(dispatch(setState))();
    }
  }, [action?.lastTransaction]);

  const actions = React.useMemo(
    () => ({
      createTrx: action.createTransaction(dispatch(setState), state),
      setTrxModal: action.setTrxModal(dispatch(setState)),
      lastTransaction: action.lastTransaction(dispatch(setState)),
      setPaid: action.setPaid(dispatch(setState)),
    }),
    [setState, state]
  );
  return (
    <TransactionStateContext.Provider value={useMemo(() => state, [state])}>
      <TransactionDispatchContext.Provider value={actions}>
        {children}
      </TransactionDispatchContext.Provider>
    </TransactionStateContext.Provider>
  );
};
