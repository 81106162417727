import { toast } from "react-toastify";
const config = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  //   theme: "dark",
};
export const Notify = {
  info: (title) => {
    toast.info(title, { ...config, theme: getTheme() });
  },
  success: (title) => {
    toast.success(title, { ...config, theme: getTheme() });
  },
  warning: (title) => {
    toast.warning(title, { ...config, theme: getTheme() });
  },
  error: (title) => {
    toast.error(title, { ...config, theme: getTheme() });
  },
};
export const getTheme = () => {
  try {
    const darkMode = localStorage.getItem("darkMode");
    const theme = darkMode !== "false" ? "dark" : "light";
    return theme;
  } catch (error) {
    return "light";
  }
};
