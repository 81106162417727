/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./Row.module.sass";
import { EPlanType, EVolumeType, URL } from "api";
import Control from "./Control";
import VolumeValue from "./VolumeValue";
import cn from "classnames";
import { Img } from "components/Image";
import Price from "components/Price";

const Row = ({ item, index, count, state, refetch }) => {
  return (
    <div
      className={cn(styles.row, {
        expired: !item?.active,
      })}
    >
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            {item?.image ? (
              <img src={`${URL.CdnUrl}${item?.image}`} alt="Image" />
            ) : (
              <img src={`noimage.png`} alt="Image" />
            )}
            <div className={styles.icon}>
              {/* <Icon name={"image"} /> */}
              {item?.testVerstion && <span className="status-aptos">Demo</span>}
              {!item?.active && <span className="status-red">Disable</span>}
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.post}>{item?.name}</div>
          </div>
        </div>
      </div>
      {state.isSuperAdmin && (
        <div className={styles.col}>
          <div className={styles.label}>Admin</div>
          <div className={styles.col}>
            <div className={styles.post_image}>
              <Img src={`${URL.CdnUrl}${item?.admin?.profile?.image}`} />
              {item?.admin?.name || ""}
            </div>
          </div>
        </div>
      )}
      <div className={styles.col}>
        <div className={styles.label}>Price</div>
        <div className={styles.col}>
          <div className={styles.post}>
            $ <Price>{item.price}</Price>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Plan</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {EPlanType[item?.plan?.type]}({item?.plan?.value || "0"})
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Volume</div>
        <div className={styles.col}>
          <div className={cn(styles.post, styles.volume)}>
            {EVolumeType[item?.volume?.type]}(<VolumeValue {...item?.volume} />)
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Create Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.createdAt?.substr(0, 16)?.replace("T", " ")}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control
              className={styles.control}
              {...{ index, count, item, refetch }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
