import axios from "axios";
import { URL } from "./url.enum";
import { getAuth, setAuth } from "./useAuth";

const http = axios.create({
  baseURL: URL.BaseUrl,
});
// http.defaults.timeout = 2500000;
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const auth=getAuth();
      const role=auth.user.user.role || '4'
      setAuth();
      let menu='';
      if (role) {
        if (role === "1" || role === "2") {
          menu = "/"+auth.user.user.account;
        } else if(role === "0"){
          menu = "/master";
        }else{
          //it is user
      }
      }
      window.location = menu+'/sign-in';
    }
    if(error.response.status >= 400){
      return Promise.reject(error);
    }
    return Promise.resolve(error);
  }
);
export { http };
export const go = (url) => () => {
  if (url) window.open(url);
};

export const assets = (url) => {
  return `${URL.CdnUrl}${url}`;
};
