import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import List from "./List";

const PaidLog = () => {

  const { id } = useParams();
  return <>{id && <List id={id} />}</>;
};

export default PaidLog;
