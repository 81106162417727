import { useEffect, useState } from "react";
import { EPlanType } from "../../api";
import styles from "./Plan.module.sass";
import Dropdown from "../Dropdown";
import { TextInput } from "../TextInput";
import cn from "classnames";
import Tooltip from "components/Tooltip";
import TooltipGlodal from "components/TooltipGlodal";
import { CanAdmin, IsAdmin, NotCanAdmin } from "context";

export const Plan = ({ readonly = false, ...props }) => {
  const [plan, setPlan] = useState(EPlanType.keys()[0]);
  const [value, setValue] = useState();
  const onChangePlan = (e) => {
    setPlan(e);
  };
  const onChangeValue = (e) => {
    setValue(parseFloat(e.target.value));
  };
  useEffect(() => {
    if (plan && value > 0)
      props.onChange({
        type: plan,
        value,
      });

    return () => {
      props.onChange({});
    };
  }, [value, plan]);
  useEffect(() => {
    if (props?.value && "type" in props?.value) {
      setPlan(props?.value?.type);
    }
    if (props?.value && "value" in props?.value) {
      setValue(props?.value?.value);
    }
  }, [props?.value]);

  return (
    <>
      <div className={styles.field_label}>
        <div className={cn(styles.label)}>
          {"Plan setting"}
          <Tooltip
            className={styles.tooltip}
            title={"Plan setting"}
            icon="info"
            place="right"
          />
          <IsAdmin>
            {readonly && (
              <span className="text-red" style={{ fontSize: 16 }}>
                {"This field cannot be changed"}
              </span>
            )}
          </IsAdmin>
        </div>
      </div>
      <TooltipGlodal />
      <div className={styles.field_flex}>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={plan}
          setValue={onChangePlan}
          options={EPlanType.keys()}
          readonly={readonly}
        />

        <TextInput
          placeholder={`${plan} value`}
          type="number"
          value={value}
          icon={plan.toLocaleLowerCase()}
          iconSize={plan.toLocaleLowerCase() === "dollar" ? 16 : 18}
          onChange={onChangeValue}
          readonly={readonly}
        />
      </div>
      <div className={styles.field_error}>
        {props.error?.type?.message && (
          <span className={styles.error}>Plan value must be a number</span>
        )}
      </div>
    </>
  );
};
