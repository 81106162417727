import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { http } from "./http.config";
import { URL } from "./url.enum";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getAuthHeader } from "./useAuth";
import { useFindOne, usePagesWithLoadeMore } from "./usePages";
const RoleList = ["super_admin", "admin", "admin_partner", "client"];
export const Role = Object.freeze({
  SUPER_ADMIN: "0",
  ADMIN: "1",
  ADMIN_PARTNER: "2", //user and pass
  CLIENT: "3", //	use in jwt
  checked: (a, b) => `${a}` === `${b}`,
  checkedName: (a, b) => {
    console.log(a, b);
    return `${a}` in { [RoleList[b]]: 1 };
  },
});

const useUpdateProfile = () => {
  return useMutation(async (data) => {
    try {
      const update = {};
      if (data?.email) {
        update["email"] = data.email;
      }
      if (data?.name) {
        update["name"] = data.name;
      }
      if (data?.password) {
        update["password"] = data.password;
      }
      if (data?.description || data?.image) {
        update["profile"] = {
          description: data?.description || null,
          image: data?.image || null,
        };
      }
      const res = await http.put(URL.MASTER_PROFILE, update, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useMasterProfile = () => {
  return useQuery(["master.profile"], async (data) => {
    try {
      const res = await http.get(URL.MASTER_PROFILE, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useUpdateProfileForm = (defaultValues) => {
  const schema = yup
    .object(defaultValues?.email?{
      email: yup.string().email().required(),
      name: yup.string().max(150).optional(),
      // image: yup.string().optional(),
      description: yup.string().optional(),
      password: yup.string().max(16).optional(),
    }:{
      name: yup.string().max(150).optional(),
      description: yup.string().optional(),
    })
    .required();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const _ = useUpdateProfile();
  return useMemo(() => {
    return [
      {
        email: { ...register("email") },
        name: { ...register("name") },
        password: { ...register("password") },
        image: { ...register("image") },
        description: { ...register("description") },
        register,
        control,
        errors,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};

const useCreateAdmin = () => {
  return useMutation(async (data) => {
    try {
      const update = {};
      if (data?.email) {
        update["email"] = data.email;
      }
      if (data?.commission) {
        update["commission"] = data.commission;
      } else {
        update["commission"] = 0;
      }
      if (data?.account) {
        update["account"] = data.account;
      }
      if (data?.partner === "1") {
        update["role"] = Role.ADMIN_PARTNER;
      } else {
        update["role"] = Role.ADMIN;
      }
      if (data?.name) {
        update["name"] = data.name;
      }
      if (data?.password) {
        update["password"] = data.password;
      }
      if (data?.description || data?.image) {
        update["profile"] = {
          description: data?.description || null,
          image: data?.image || null,
        };
      }
      if (data?.access) {
        update["access"] = data.access;
      }
      const res = await http.post(URL.MASTER, update, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
const useEditAdmin = () => {
  return useMutation(async (data) => {
    try {
      const update = {};
      if (data?.email) {
        update["email"] = data.email;
      }
      if (data?.commission) {
        update["commission"] = data.commission;
      } else {
        update["commission"] = 0;
      }
      if (data?.account) {
        update["account"] = data.account;
      }
      if (data?.partner === "1") {
        update["role"] = Role.ADMIN_PARTNER;
      } else {
        update["role"] = Role.ADMIN;
      }
      if (data?.name) {
        update["name"] = data.name;
      }
      if (data?.password) {
        update["password"] = data.password;
      }
      if (data?.description || data?.image) {
        const profile = {};
        if (data?.description) {
          profile["description"] = data?.description;
        }
        if (data?.image) {
          profile["image"] = data?.image;
        }
        update["profile"] = profile;
      }
      if (data?.access) {
        update["access"] = data.access;
      }
      const res = await http.put(
        `${URL.MASTER}/${data._id}`,
        update,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useCreateAdminForm = () => {
  const schema = yup
    .object({
      account: yup
        .number()
        .typeError("Account number must be a number")
        .required(),
      name: yup.string().max(150).required(),
      image: yup.string().optional(),
      description: yup.string().optional(),
      partner: yup.string().optional(),
      email: yup
        .string()
        .email()
        .when("partner", {
          is: true,
          then: yup.string().required("Must enter email address"),
        }),
      password: yup
        .string()
        .max(16)
        .when("partner", {
          is: true,
          then: yup.string().required("Must enter password"),
        }),
      commission: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .typeError("Commission must be a number")
        .max(100)
        .nullable(),
      access: yup
        .object({
          clientRemove: yup
            .boolean()
            .transform((value) => !!value)
            .optional(),
          clientAdd: yup
            .boolean()
            .transform((value) => !!value)
            .optional(),
          volumeUpdate: yup
            .boolean()
            .transform((value) => !!value)
            .optional(),
        })
        .optional(),
    })
    .required();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const _ = useCreateAdmin();
  return useMemo(() => {
    return [
      {
        name: { ...register("name") },
        account: { ...register("account") },
        email: { ...register("email") },
        password: { ...register("password") },
        image: { ...register("image") },
        description: { ...register("description") },
        partner: { ...register("partner") },
        commission: { ...register("commission") },
        access: {
          clientRemove: register("access.clientRemove"),
          clientAdd: register("access.clientAdd"),
          volumeUpdate: register("access.volumeUpdate"),
        },
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useEditAdminForm = (defaultValues) => {
  const schema = yup
    .object({
      account: yup
        .number()
        .typeError("Account number must be a number")
        .required(),
      name: yup.string().max(150).required(),
      image: yup.string().optional(),
      description: yup.string().optional(),
      partner: yup.string().optional(),
      email: yup
        .string()
        .email()
        .transform((value) =>
          value === null || value === undefined ? "" : value
        )
        .when("partner", {
          is: true,
          then: yup.string().required("Must enter email address"),
        }),
      password: yup
        .string()
        .max(16)
        .when("partner", {
          is: true,
          then: yup.string().required("Must enter password"),
        }),
      commission: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .typeError("Commission must be a number")
        .max(100)
        .nullable(),
      access: yup
        .object({
          clientRemove: yup
            .boolean()
            .transform((value) => !!value)
            .optional(),
          clientAdd: yup
            .boolean()
            .transform((value) => !!value)
            .optional(),
          volumeUpdate: yup
            .boolean()
            .transform((value) => !!value)
            .optional(),
        })
        .optional(),
    })
    .required();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const _ = useEditAdmin();
  return useMemo(() => {
    return [
      {
        name: { ...register("name") },
        account: { ...register("account") },
        email: { ...register("email") },
        password: { ...register("password") },
        image: { ...register("image") },
        description: { ...register("description") },
        partner: { ...register("partner") },
        commission: { ...register("commission") },
        access: {
          clientRemove: register("access.clientRemove"),
          clientAdd: register("access.clientAdd"),
          volumeUpdate: register("access.volumeUpdate"),
        },
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useListAdmin = () => {
  return usePagesWithLoadeMore(URL.MASTER, true, 0, 15, {});
};
export const useGetAdminByAccount = (account) => {
  return useFindOne(URL.MASTER_ACCOUNT, account, false);
};
export const useDeleteAdmin = () => {
  return useMutation(async (data) => {
    try {
      const update = { status: data?.status };

      const res = await http.put(
        `${URL.MASTER}/${data._id}`,
        update,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};

export const useGetPaidLogAdmin = (id) => {
  return useFindOne(URL.MASTER_PAID, id, false);
};
export const useAddPaidLogAdmin = () => {
  return useMutation(async ({ _id, ...data }) => {
    try {
      const res = await http.post(
        `${URL.MASTER_PAID}/${_id}`,
        { ...data, price: parseFloat(data?.price) },
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
