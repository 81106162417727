import Checkbox from "../../../../components/Checkbox";
import React from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import { useEditWalletApi, useEditWalletForm } from "api";
import { TextInput } from "components/TextInput";
import { Controller } from "react-hook-form";
import Textarea from "components/Textarea";
import Select from "components/Select";
import { useForm } from "common";

const Edit = ({ refetch, onClose, item }) => {
  const [active, setActive] = React.useState(item?.active || false);
  const loading = useLoadingDispatch();
  const [form, onForm] = useForm(item);
  const _ = useEditWalletApi();
  const onSubmit = async () => {
    loading.show();
    const response = await _.mutateAsync({
      ...form.data,
      active,
      _id: item._id,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };
  const onSetActive = async (e) => {
    const checked = e.target.checked;
    setActive(checked);
  };
  //
  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>Edit Wallet</div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          placeholder="Name"
          icon="info-stroke"
          value={form?.name}
          onChange={onForm("name")}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          placeholder="address"
          value={form?.address}
          icon="wallet"
          onChange={onForm("address")}
        />
      </div>
      <div className={styles.field}>
        <Select
          option="name"
          id="id"
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={form?.type}
          setValue={onForm("type")}
          options={[
            {
              id: 1,
              name: "USDT",
            },
          ]}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          placeholder="Priority"
          icon="point"
          type="number"
          value={form?.priority}
          onChange={onForm("priority")}
        />
      </div>
      <div className={styles.field_checkbox}>
        <Checkbox value={active} onChange={onSetActive} content="Free" />
      </div>
      <div className={styles.field}>
        <Textarea
          // value={field?.value}
          placeholder="What you would like to share?"
          value={form?.description}
          onChange={onForm("description")}
        />
      </div>
      <div className={styles.foot}>
        <button
          type="button"
          onClick={onSubmit}
          className={cn("button", styles.button)}
        >
          <span className="white">Save</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Edit;
