import React from "react";
import styles from "./Del.module.sass";
import cn from "classnames";
import Icon from "components/Icon";

const Del = ({ onSubmit, onClose }) => {
  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>Cancel transaction</div>

      <div className={styles.field}>Do you want cancel transaction?</div>

      <div className={styles.foot}>
        <button
          type="button"
          onClick={()=>{
            debugger
            onSubmit()
          }}
          className={cn("button", styles.button)}
        >
          <span>Yes</span>
          <Icon name="trash" size="24" />
        </button>
        <button
          type="button"
          onClick={onClose}
          className={cn("button", styles.button)}
        >
          <span>No</span>
          <Icon name="close" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Del;
