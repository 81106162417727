import React from "react";
import ContentLoader from "react-content-loader";
export function LoaderMint() {
  return (
    <ContentLoader
      height={65}
      width="100%"
      speed={1.8}
      backgroundColor={"#333"}
      foregroundColor={"#999"}
    //   viewBox="0 0 380 70"
    >
      <rect x="0" y="0" rx="5" ry="5" width="60" height="60" />
      <rect x="70" y="4" rx="4" ry="4" width="30%" height="8" />
      <rect x="75" y="18" rx="4" ry="4" width="10%" height="8" />
      {/* <rect x="275" y="18" rx="4" ry="4" width="60%" height="8" />
      <rect x="70" y="32" rx="4" ry="4" width="90%" height="8" />
      <rect x="70" y="46" rx="4" ry="4" width="10%" height="8" />
      <rect x="18%" y="46" rx="4" ry="4" width="30%" height="8" /> */}
    </ContentLoader>
  );
}
