import React from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import Seo from "../../components/Seo";

 

const Promote = () => {
  return (
    <>
    
      <div className={styles.section}>
        <div className={cn(styles.card)} title="" >
          {/* <div className={styles.image}>
            <img src="/assets/img/404.png" />
          </div> */}
          <div className={styles.info}>
            <div className={styles.h2}>403</div>
            <br />
            <div className={styles.h1}>You do not have access to this page </div>
            <div className={styles.h3}>
              Well, this is awkward. There is nothing to see here!
            </div>
            <br />
            <div>
              <Link to="/" className={cn("status-dark", styles.status)}>
                Go To Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Promote;
