import React, { useState } from "react";
import styles from "./New.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Upload from "../../../../components/Upload";
import { useLoadingDispatch } from "../../../../context";
import { useUploadFile } from "../../../../api";
import { Notify } from "../../../../components/Notify";
import { useCreateAdminForm } from "../../../../api/useAdmin";
import TextInputRef, { TextInput } from "../../../../components/TextInput";
import Textarea from "../../../../components/Textarea";
import Checkbox, { CheckboxRef } from "../../../../components/Checkbox";
import TextEdit from "components/TextEdit";
import { Controller } from "react-hook-form";

const New = ({ refetch, onClose }) => {
  const [image, setImage] = useState('');
  const [partner, setPartner] = useState(false);
  const [link, setLink] = useState();
  const loading = useLoadingDispatch();
  const [form, onForm, _] = useCreateAdminForm({});
  const updateFile = useUploadFile();
  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync({ ...data });
    loading.hide();
    debugger
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };
  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      form.setValue("image", response);
      setImage(response);
    } else {
      form.setValue("image", null);
      setImage('');
      return Notify.error("Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF");
    }
  };
  const onCheckbox = async (e) => {
    const checked = e.target.checked;
    setPartner(checked);
    form.setValue("partner", checked ? "1" : "");
  };
  const onChangeLink = async (e) => {
    const checked = e.target.value;
    debugger;
    setLink(`${window.location.origin}/${checked}/sign-in`);
    form.setValue("account", checked);
  };
  return (
    <form onSubmit={onForm(onSubmit)}>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>New Master</div>

        <div className={styles.field}>
          <Upload
            value={image}
            onDelete={() => {
              form.setValue("image", null);
            }}
            onSelectFile={onUpdateFile}
          />
        </div>

        <div className={styles.field}>
          <TextInputRef
            className={styles.field}
            placeholder="Account number"
            icon="info"
            type="number"
            error={form.errors.account?.message}
            // {...form?.account}
            onChange={onChangeLink}
          />
        </div>

        <div className={styles.field}>
          <TextInputRef
            className={styles.field}
            placeholder="Your name"
            icon="info"
            error={form.errors.name?.message}
            {...form.name}
          />
        </div>
        <div className={styles.field}>
          <Checkbox onChange={onCheckbox} content="Admin partner" />
        </div>
        <div
          className={styles.field}
          style={{
            display: partner ? "block" : "none",
          }}
        >
          <TextInputRef
            className={styles.field}
            placeholder="Your email"
            icon="mail"
            // readonly
            error={form.errors.email?.message}
            {...form.email}
          />
        </div>
        <div
          className={styles.field}
          style={{
            display: partner ? "block" : "none",
          }}
        >
          <TextInputRef
            className={styles.field}
            type="password"
            placeholder="Password"
            icon="lock"
            error={form.errors.password?.message}
            {...form.password}
          />
        </div>
        <div
          className={styles.field}
          style={{
            display: partner ? "block" : "none",
          }}
        >
          <TextInputRef
            className={styles.field}
            type="number"
            placeholder="Super admin commission"
            icon="commission"
            max={100}
            min={0}
            error={form.errors.commission?.message}
            {...form.commission}
          />
        </div>
        <div className={styles.field}>
        <Controller
                render={({ field }) => (
                  <TextEdit
                    name="description"
                    className={styles.textarea}
                    placeholder="What you would like to share?"
                    value={field?.value}
                    onChange={(v) => field.onChange(v)}

                  />
                )}
                control={form.control}
                name="description"
              />
          
        </div>
        <div className={styles.field}>
          <TextInputRef
            className={styles.field}
            type="hidden"
            placeholder="image"
            {...form.image}
          />
        </div>
        <div className={styles.field}>
          <TextInputRef
            className={styles.field}
            type="hidden"
            placeholder="partner"
            {...form.partner}
          />
        </div>
        <div className={styles.field_flex}>
          <CheckboxRef {...form?.access?.clientAdd} content="Add client" />
          <CheckboxRef {...form.access?.clientRemove} content="Remove client" />
          <CheckboxRef {...form.access?.volumeUpdate} content="Update volume" />
        </div>
        <div
          className={styles.field}
          style={{
            display: partner ? "block" : "none",
          }}
        >
          <TextInput
            className={styles.field}
            type="text"
            placeholder="Link"
            icon="link"
            copy
            value={link}
          />
        </div>
        <div className={styles.foot}>
          <div className={styles.files}></div>
          <button className={cn("button", styles.button)}>
            <span>Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default New;
