export const EVolumeType = Object.freeze({
  FIX: 0, //	number
  FALLOW: 1, //boolean
  RATIO: 2, //	2000:0.01
  MULTIPLY: 3, //	number
  RISK: 4, //2 : 0.01
  0: "Fix lot size",
  1: "Master money management",
  2: "Ratio",
  3: "Multiply master volume",
  4: "Risk",
  Fix: "Fix lot size",
  Fallow: "Master money management",
  Ratio: "Ratio",
  Multiply: "Multiply master volume",
  Risk: "Risk",
  values: () => [0, 1, 2, 3, 4],
  keys: () => [
    "Fix lot size",
    "Master money management",
    "Ratio",
    "Multiply master volume",
    "Risk",
  ],
  checked: (a, b) => `${a}` === `${b}`,
  get: (a) =>
    [
      "Fix lot size",
      "Master money management",
      "Ratio",
      "Multiply master volume",
      "Risk",
    ].findIndex((i) => i === `${a}`),
});
export const EVolumeTypeWay = Object.freeze({
  Balance: 1, //	Balance
  Equity: 2, //Equity
  1: "Balance",
  2: "Equity",

  values: () => [1, 2],
  keys: () => ["Balance", "Equity"],
  checked: (a, b) => `${a}` === `${b}`,
  get: (a) => [1, 2][["Balance", "Equity"].findIndex((i) => i === `${a}`)],
});
