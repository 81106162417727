import { useContext, createContext } from "react";

export const LoadingStateContext = createContext();
export const LoadingDispatchContext = createContext();

/**
 * Loading State Managment
 * @returns state
 */
export const useLoadingState = () => {
  const context = useContext(LoadingStateContext);
  if (context === undefined) {
    throw new Error("useLoadingState must be used within a LoadingProvider");
  }
  return context;
};

/**
 * Loading Action Managment
 * @returns Actions
 */
export const useLoadingDispatch = () => {
  const context = useContext(LoadingDispatchContext);
  if (context === undefined) {
    throw new Error("useLoadingDispatch must be used within a LoadingProvider");
  }
  return context;
};
