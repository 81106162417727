/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import Icon from "../../../../components/Icon";
import {
  EPlanType,
  EVolumeType,
  Role,
  URL,
  useUpdateAccountByClient,
} from "../../../../api";
import Control from "./Control";
import classNames from "classnames";
import Image, { Img } from "components/Image";
import Renewal from "components/Renewal";
import { IsSuperAdmin, NotSuperAdmin, useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import VolumeValue, { VolumeValueToStr } from "screens/AdminPlan/List/Row/VolumeValue";
import cn from "classnames";
import { starText } from "common";

const Row = ({ expired, item, role, user, refetch }) => {
  const [toggle, setToggle] = useState(false);
  const loading = useLoadingDispatch();
  const _ = useUpdateAccountByClient();
  const volume= `${EVolumeType[item?.volume?.type]}(${VolumeValueToStr({...item?.volume})})`
  useEffect(() => {
    if ("autoRenewal" in item) {
      setToggle(item.autoRenewal);
    }

    return () => {
      setToggle(false);
    };
  }, [item]);

  const onToggle = async (e) => {
    const checked = !e.target.checked;
    loading.show();

    const response = await _.mutateAsync({
      autoRenewal: checked,
      _id: item?._id,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      Notify.success("Auto renewal change successfull!");
      setToggle(checked);
      return;
    }
  };
  return (
    <div className={classNames(styles.row, { expired })}>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            {item?.lastPlan?.image ? (
              <Img src={`${URL.CdnUrl}${item?.lastPlan?.image}`} alt="Image" />
            ) : (
              <img src={`noimage.png`} alt="Image" />
            )}
            {(Role.checked(user?.role, Role.CLIENT) ||
              Role.checked(user?.role, Role.SUPER_ADMIN)) && (
                <div className={styles.icon}>
                  <Img
                    src={`${URL.CdnUrl}${item?.admin?.profile?.image}`}
                    alt="Image"
                  />
                  <span className="status-aptos">{item?.admin?.name}</span>
                </div>
              )}
            {!item?.lastPlan && (
              <div className={styles.icon} style={{ top: 69 }}>
                <span className="status-red">Manual</span>
              </div>
            )}
          </div>
          <div className={styles.details}>
            <div className={styles.post}>
              {item?.lastPlan?.name || " "}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Client Name</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <IsSuperAdmin>
              {item?.lastPlan ? <>
                <div data-tip={item.owner?.name} data-place={"top"}>
                  {starText(item.owner?.name, 4, 0, 1)}
                </div>
              </> : <>
                {item.options?.name ? <div data-tip={item.options?.name} data-place={"top"}>
                  {starText(item.options?.name, 4, 0, 1)}
                </div> : ""}
              </>}
            </IsSuperAdmin>
            <NotSuperAdmin>
              {item?.lastPlan ? <>---</> : <> {item.options?.name ? <div data-tip={item.options?.name} data-place={"top"}>
                  {starText(item.options?.name,4, 0, 1)}
                </div> : ""}</>}
            </NotSuperAdmin>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Client Email</div>
        <div className={styles.col}>
          <div className={styles.post}><IsSuperAdmin>
            {item?.lastPlan ? <>
              <div data-tip={item.owner?.email} data-place={"top"}>
                {starText(item.owner?.email, 4, 0, 1)}
              </div> </> : <>
              {item.options?.contact ? <div data-tip={item.options?.contact} data-place={"top"}>
                {starText(item.options?.contact, 4, 0, 1)}
              </div> : ""}
            </>}
          </IsSuperAdmin>
            <NotSuperAdmin>
              {item?.lastPlan ? <>---</> : <>  {item.options?.contact ? <div data-tip={item.options?.contact} data-place={"top"}>
                {starText(item.options?.contact, 4, 0, 1)}
              </div> : ""}</>}
            </NotSuperAdmin> </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Account</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.accountNumber || " "} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Demo</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.testVerstion ? "Yes" : "No"}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Plan name</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {EPlanType[item?.plan?.type]}({item?.plan?.value || "0"})
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Volume</div>
        <div className={styles.col}>
          <div className={cn(styles.post)}>
            {volume ?<><div data-tip={volume} data-place={"top"}>
                {starText(volume, 4, 0, 1)}
              </div></>:""}
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Remain</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.stats?.passDay || "None"}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Create Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.createdAt?.substr(0, 16)?.replace("T", " ")}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Expired Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {expired && expired?.substr(0, 16)?.replace("T", " ")}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Auto Renewal</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {role !== "client" ? (
              <>
                {item?.autoRenewal ? (
                  <span className="status status-green">activate</span>
                ) : (
                  <span className="status status-red">deactivate</span>
                )}
              </>
            ) : (
              <>
                <Renewal toggle={!toggle} onToggle={onToggle} />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control
              role={role}
              refetch={refetch}
              className={styles.control}
              item={item}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
