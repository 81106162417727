import React, { useEffect, useState } from "react";
import styles from "./Client.module.sass";
import List from "./List";
import { useListAccountClient, useListClient } from "../../api";

const Client = (props) => {
  const data = useListClient();
  return (
    <>
      <div className={styles.section}>
        <List {...data}  />
      </div>
    </>
  );
};  

export default Client;
