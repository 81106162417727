import React, { useCallback, useEffect } from "react";
import styles from "./Wallets.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { TextInput } from "components/TextInput";
import { QRCodeSVG } from "qrcode.react";
import { Timer } from "components/Timer";
import { FreePrice } from "./FreePrice";
import { CardQr } from "../CardQr";
import CopyUi from "components/Copy";

export function PlanPaid({
  item,
  expired,
  onDenyTrx,
  onExtend,
  plan,
  paid,
  onClose,
}) {
  return (
    <>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>
          <div className={styles.title}> {`Payment ${plan.name} plan`}</div>
          <div className={styles.stage}>
            Transaction Id: <CopyUi isIcon>{item?.authority}</CopyUi>
          </div>
        </div>
        {paid ? (
          <>
            <div className={cn(styles.card)}>
              <div className={styles.info}>
                <Icon name="info" size="24" />
                <span>
                  Dear user, you can add the account number now or add it later
                  in the account section
                </span>
              </div>
            </div>
            <FreePrice
              paid={paid}
              plan={plan}
              onClose={onClose}
              // loading={loading}
            />
          </>
        ) : (
          <CardQr {...{ item, expired, onDenyTrx, onClose, onExtend, plan }} />
        )}
      </div>
    </>
  );
}
