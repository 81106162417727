export const SignClient = "sign.client";
export const SetSession = "set.session";
export const SetAccount = "set.account";
export const Disconnect = "Disconnect";
export const Connect = "Connect";



export const SessionConnected = "SessionConnected";

