export const EPlanType = Object.freeze({
    DAY: 0,
    POINT: 1,
    DOLLAR: 2,
    0: "Day",
    1: "Point",
    2: "Dollar",
    values: () => [0, 1, 2],
    keys: () => ["Day", "Point", "Dollar"],
    checked: (a, b) => `${a}` === `${b}`,
  });
  