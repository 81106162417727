import React, { useEffect, useState } from "react";
import styles from "./Upload.module.sass";
import { URL, useDeleteFile } from "../../api";
import TooltipGlodal from "components/TooltipGlodal";
import { Notify } from "components/Notify";
import Icon from "components/Icon";
import { copy } from "common";
const UploadFile = ({
  onSelectFile,
  onDelete,
  error,
  value,
  radius = 10,
  isCircle = false,
  width = 80,
  height = 80,
  isDisabled,
  deleteFile = true,
}) => {
  const _ = useDeleteFile();
  const imageUrl = URL.CdnUrl;
  const getValuePath = () => {
    if (value) {
      return `${imageUrl}${value}`;
    }
    resetFile();
    return;
  };
  const [getSelectStatus, setSelectStatus] = useState(false);
  const borderRadius = isCircle === true ? (width + height) / 2 : radius;
  const itemSelect = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.click();
    input.onchange = (e) => {
      var file = e.target.files[0];
      if (file.size > 16 * 1024 * 1024 || !file?.name?.toLocaleLowerCase().match(/\.(zip|rar)$/)) {
        Notify.error("Sorry, File size must be less than 16 MB and allowed formats are RAR,ZIP");
        return;
      }
      var reader = new FileReader();
      console.log("file", file);
      console.log("file", file?.size);
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        var base64 = readerEvent.target.result;
        setSelectStatus(true);
        onSelectFile(file, base64);
      };
    };
  };

  const resetFile = async () => {
    deleteFile && value && await _.mutateAsync({ file: value });
    setSelectStatus(false);
  };
  useEffect(() => {
    if (value) setSelectStatus(true);
    return () => {
      setSelectStatus(false);
    };
  }, [value]);

  const getImage = () => {
    if (getSelectStatus === true || value) {
      if (!getValuePath()) {
        return (
          <img
            data-tip={`upload mql limit size: 5MB format: .exe`}
            data-place={"top"}
            src="/img/s3/camera.svg"
            alt="camera.svg"
          />
        );
      }
      return (
        <>
          <span
            onClick={() => {
              if (!getSelectStatus && !isDisabled) itemSelect();
              else copy(getValuePath());
            }}
            style={{ marginTop: "25px", cursor: "pointer" }}
          >
            File uploaded <Icon name="copy" />
          </span>
          <img
            className={isCircle ? styles.deleteIcon : styles.deleteIconRight}
            src="/img/s3/clear.svg"
            alt="clear.svg"
            onClick={async () => {
              await resetFile();
              itemSelect()
              onDelete && onDelete();
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <img
            data-tip={`upload mql limit size: 5MB format: .exe`}
            data-place={"top"}
            src="/img/s3/camera.svg"
            alt="camera.svg"
          />
        </>
      );
    }
  };

  return (
    <div
      className={styles.uploadpage}
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div
          onClick={() => {
            if (!getSelectStatus && !isDisabled) itemSelect();
          }}
          style={{ borderRadius: borderRadius, width: "100%", height: height }}
          className={styles.uploader}
        >
          {getImage()}
        </div>
      </div>
      <div span={24} align="center">
        {error !== "" && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default UploadFile;
