import React, { useState } from "react";
import styles from "./PayModal.module.sass";
import cn from "classnames";
import { useBuyPlanByClientBalance } from "api";
import { Wallet } from "./component/Wallet";
import { FreePrice } from "./component/FreePrice";
import { useLoadingDispatch, useUserDispatch } from "context";
import CardPlan from "./component/CardPlan";
import { WalletList } from "./component/WalletList";
import { Notify } from "components/Notify";

export const PayModal = ({ plan, onClick, onClose }) => {
  const loading = useLoadingDispatch();
  const user = useUserDispatch();
  const _ = useBuyPlanByClientBalance();
  const [paid, setPaid] = React.useState();
  const [page, setPage] = useState("buynow");
  const onBuyBalance = async () => {
    const _id = plan?._id;
    loading.show();
    const res = await _.mutateAsync({ _id });
    console.log("res", res);
    loading.hide();
    if (!res.error) {
      Notify.success("paid successfull");
      onPiad(res?.data);
      await user.updateClientBalance();
      return;
    }
    Notify.error(res?.message || 'Sorry! network error');
  };
  const onWalletConnect = () => {
    onClose(false);
    onClick();
  };
  const onOtherWallet = () => {
    setPage("wallets");
  };
  const onBackBuyNow = () => {
    setPage("buynow");
  };
  const onPiad = (p) => {
    setPaid(p);
    setPage("paid");
  };
  const render = () => {
    switch (page) {
      case "buynow":
        return (
          <>
            <CardPlan plan={plan} />
            <Wallet
              {...{
                plan,
                // onWalletConnect,
                onBuyBalance,
                onOtherWallet,
                onClose,
                loading,
              }}
            />
          </>
        );
      case "wallets":
        return (
          <>
            <WalletList
              onBackBuyNow={onBackBuyNow}
              onClose={onClose}
              plan={plan}
              onPiad={onPiad}
            />
          </>
        );
      case "paid":
        return (
          <>
            <CardPlan plan={plan} />
            <FreePrice
              paid={paid}
              plan={plan}
              onClose={onClose}
              loading={loading}
            />
          </>
        );

      default:
        break;
    }
  };
  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>Buy Now</div>

      {plan.price ? (
        <>{render()}</>
      ) : (
        <>
          <CardPlan plan={plan} />
          <FreePrice plan={plan} onClose={onClose} loading={loading} />
        </>
      )}
    </div>
  );
};
