import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Modal from "../../../../../components/Modal";
import EditAdmin from "../../Edit";
import TooltipGlodal from "components/TooltipGlodal";
import Tooltip from "components/Tooltip";

const Control = ({ className, refetch, item }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const actions = [
    {
      icon: "edit",
      tooltip: "Edit email",
      action: () => setVisibleModal("edit"),
    },
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Tooltip
              className={styles.tooltip}
              title={x.tooltip}
              icon={x.icon}
              place={"top"}
            />
          </button>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <>
          {item && (
            <EditAdmin
              item={item}
              onClose={() => setVisibleModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <TooltipGlodal />
    </>
  );
};

export default Control;
