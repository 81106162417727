import React, { useState } from "react";
import styles from "./New.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useLoadingDispatch } from "../../../../context";
import { Notify } from "../../../../components/Notify";
import { TextInput } from "../../../../components/TextInput";
import { Controller } from "react-hook-form";
import Textarea from "components/Textarea";
import Dropdown from "components/Dropdown";
import { Department, EmailType, Priority } from "enum";
import { useForm } from "common";
import { useCreateEmail } from "api/useEmail";
import TextEdit from "components/TextEdit";

const New = ({ refetch, onClose }) => {
  const loading = useLoadingDispatch();
  const [form, onForm] = useForm();
  const _ = useCreateEmail();
  const onSubmit = async () => {
    if (!form?.subject) {
      return Notify.error("subject is reqierd!");
    }
    const data = {};
    if (form.subject) {
      data["subject"] = form.subject;
    }
    if (form?.message) {
      data["message"] = form.message;
    }
    if (form?.email) {
      data["type"] = EmailType[form.email];
    } else {
      data["type"] = EmailType.All;
    }
    loading.show();
    const response = await _.mutateAsync(data);
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };

  return (
    <>
      <div className={styles.post}>
        <div className={cn("title-blue", styles.title)}>New Email</div>
        <div className={styles.field}>
          <Dropdown
            placeholder="Email"
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            {...form?.register("email", EmailType[1])}
            options={EmailType.keys()}
            setValue={onForm("email")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Subject"
            icon="mail"
            type="text"
            {...form?.register("subject")}
            onChange={onForm("subject")}
          />
        </div>
        <div className={styles.field}>
          <TextEdit
            image={false}
            name="message"
            // className={styles.textarea}
            placeholder="What you would like to share?"
            {...form.register("message")}
            onChange={onForm("message")}
          />
        </div>
        <div className={styles.foot}>
          <button
            onClick={onSubmit}
            type="button"
            className={cn("button", styles.button)}
          >
            <span className="white">Sent</span>
            <Icon name="mail" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default New;
