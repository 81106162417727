import React, { useEffect, useState } from "react";
import styles from "./Upload.module.sass";
import { URL } from "../../api";
import TooltipGlodal from "components/TooltipGlodal";
import { Notify } from "components/Notify";
const UploadEmail = ({
  onSelectFile,
  onDelete,
  error,
  file,
  radius = 10,
  isCircle = false,
  width = 80,
  height = 80,
  isDisabled,
}) => {
  const [getSelectStatus, setSelectStatus] = useState(false);
  const [getBase64Img, setBase64Img] = useState("");
  const borderRadius = isCircle === true ? (width + height) / 2 : radius;
  const [value, setValue] = useState();
  useEffect(() => {
    if (file) {
      setValue(file);
      setSelectStatus(true)
    }

    return () => {
      setValue("");
    };
  }, [file]);

  const imageUrl = URL.CdnUrl;
  const getValuePath = () => {
    if (value) {
      if (value.search("/assets") >= 0) {
        return `${URL.BaseUrl}${value}`;
      }
      return `${imageUrl}/${value}`;
    }
    // resetFile();
    return;
  };

  const itemSelect = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.click();
    input.onchange = (e) => {
      var file = e.target.files[0];
      var type = file.type?.toLocaleUpperCase();
      if (
        file.size > 1 * 1024 * 1024 ||
        !["IMAGE/JPG", "IMAGE/JPEG", "IMAGE/PNG", "IMAGE/GIF"].includes(type)
      ) {
        Notify.error(
          "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
        );
        return;
      }
      var reader = new FileReader();
      console.log("file", file);
      console.log("file", file?.size);
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        var base64 = readerEvent.target.result;
        setSelectStatus(true);
        setBase64Img(base64);
        onSelectFile(file, base64);
      };
    };
  };

  const resetFile = () => {
    setSelectStatus(false);
    setBase64Img("");
    setValue("");
  };

  const getImage = () => {
    if (getSelectStatus === true || value) {
      if (!getValuePath()) {
        return (
          <img
            data-tip={`upload picture limit size: 1MB format: JPG | JPEG | PNG | GIF`}
            data-place={"top"}
            src="/img/s3/camera.svg"
            alt="camera.svg"
          />
        );
      }
      return (
        <>
          <img
            style={{
              borderRadius: borderRadius,
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            src={getValuePath() || getBase64Img}
            alt="camera.svg"
          />
          <img
            className={isCircle ? styles.deleteIcon : styles.deleteIconRight}
            src="/img/s3/clear.svg"
            alt="clear.svg"
            onClick={() => {
              resetFile();
              onDelete();
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <img
            data-tip={`
upload picture
limit size: 1MB
format: JPG | JPEG | PNG | GIF
          `}
            data-place={"top"}
            src="/img/s3/camera.svg"
            alt="camera.svg"
          />
        </>
      );
    }
  };

  return (
    <div
      className={styles.uploadpage}
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    > 
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div
          onClick={() => {
            if (!getSelectStatus && !isDisabled) itemSelect();
          }}
          style={{ borderRadius: borderRadius, width: "100%", height: height }}
          className={styles.uploader}
        >
          
          {getImage()}
        </div>
      </div>
      <div span={24} align="center">
        {error !== "" && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default UploadEmail;
