import { useEffect, useState, useRef } from "react";


export const Timer = ({ all,expired, onEndTime, onChangeTime }) => {
  let i = 0;
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const startTimer = (ex) => {
    let { total, minutes, seconds } = getTimeRemaining(ex);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const getTimeRemaining = (ex) => {
    const total = ex;
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };
  useEffect(() => {
    if (Ref.current) {
      i = 0;
      clearInterval(Ref.current);
    }
    const id = setInterval(() => {
      i += 1000;

      const tm = expired - i;
      onChangeTime && onChangeTime(parseFloat(tm/all*100).toFixed(3));
      if (tm <= 0) {
        onEndTime && onEndTime();
        clearInterval(Ref.current);
      }
      startTimer(tm);
    }, 1000);
    Ref.current = id;
  }, [expired]);
  return <span style={{ fontSize: "1.4em", fontStyle: "bold" }}>{timer}</span>;
};
