import React, { useCallback, useEffect } from "react";
import styles from "./Wallets.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { TextInput } from "components/TextInput";
import { QRCodeSVG } from "qrcode.react";
import { Timer } from "components/Timer";
import { CardQr } from "../CardQr";
import CopyUi from "components/Copy";

export function WalletPaid({
  item,
  expired,
  onDenyTrx,
  onExtend,
  plan,
  paid,
  onClose,
}) {
  return (
    <>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>
          <div className={styles.title}>{plan.name}</div>
          <div className={styles.stage}>
            Transaction Id: <CopyUi isIcon>{item?.authority}</CopyUi>
          </div>
        </div>
        {paid ? (
          <>
            <>
              <h1 className={styles.active}>Charge account successfull!</h1>
              <div className={styles.foot}>
                <button
                  onClick={onClose}
                  className={cn("button", styles.button)}
                >
                  <span className="white">Close</span>
                </button>
              </div>
            </>
          </>
        ) : (
          <CardQr {...{ item, expired, onClose, onDenyTrx, onExtend, plan }} />
        )}
      </div>
    </>
  );
}
