import { useEffect, useState } from "react";
import { EVolumeType, EVolumeTypeWay } from "../../api";
import styles from "./Volume.module.sass";
import Dropdown from "../Dropdown";
import { VolumeInput } from "../TextInput";
import cn from "classnames";
import Tooltip from "components/Tooltip";
import TooltipGlodal from "components/TooltipGlodal";
import { IsAdmin } from "context";
const Help = {
  [EVolumeType.Fix]: `Trade volume is fix for all clients`, //FIX: 0, //	number
  [EVolumeType.Fallow]: `
  Fallow Master money management, Adjust lot size for clients base on master money management<br/>
  example:<br/>
  if  master balance or equity 1000$ and open trade 0.05 lot size,<br/>
  Client A ,  balance or equity 200$  , client lot size is 0.01<br/>
  Client B , balance or equity 500$ , client lot size is  0.02<br/>
  Result is rounding float <br/>
  `, //FALLOW: 1, //boolean
  [EVolumeType.Ratio]: `Example:<br/>
  if Client balance or equity 2000$  ,  setting 500$  : 0.02 ,  volume result = 0.08<br/>
  if Client balance or equity 499$  ,  setting 500$  : 0.02  ,  volume result = 0.02<br/>
  if Client balance or equity 999$  ,  setting 500$  : 0.02  ,  volume result = 0.02<br/>
 Result is rounding float
 `, // RATIO: 2, //	2000:0.01
  [EVolumeType.Multiply]: `Setting the lot size for the client with a coefficient from the manager<br/>
  example:<br/>
  Value =1   ,  if master lot size is 0.06 client lot size will be 0.06<br/>
  Value = 0.5  ,  if master lot size is 0.06 client lot size will be 0.03<br/>
  Value = 2  ,  if master lot size is 0.06 client lot size will be 0.12<br/>
  Result is rounding float
  `, //  MULTIPLY: 3, //	number
  [EVolumeType.Risk]: `Adjusting the lot size based on risk, <br/>
  volume is selected that, if the stop loss of trade  is touched ,<br/>
 
 The amount of loss in that transaction is equal to the specified <br/>percentage of balance or equity<br/>
 . having stop loss in this option is necessary.<br/>
 `, //RISK: 4, //2 : 0.01
};
export const Volume = ({ readonly, ...props }) => {
  const [type, setType] = useState(EVolumeType.Fix);
  const [value, setValue] = useState();

  useEffect(() => {
    if (type && value) {
      props.onChange({
        type,
        value,
      });
    }
  }, [value, type]);
  useEffect(() => {
    if (props?.value?.type) {
      setType(props?.value?.type);
    }
    if (props?.value?.value) {
      setValue(props?.value?.value);
    } else {
      setValue("0.01");
    }
  }, [props?.value]);
  const onChangeType = (e) => {
    switch (e) {
      case EVolumeType.Fix:
        setValue("0.01");
        break;
      case EVolumeType.Fallow:
        setValue(EVolumeTypeWay.Balance);
        break;
      case EVolumeType.Ratio:
        setValue(`500:0.01:${EVolumeTypeWay.Balance}`);
        break;
      case EVolumeType.Risk:
        setValue(`1:${EVolumeTypeWay.Balance}`);
        break;
      case EVolumeType.Multiply:
        setValue(`1`);
        break;

      default:
        setValue("");
        break;
    }

    setTimeout(() => {
      setType(e);
    }, 200);
  };
  const onChangeValue = (v) => {
    if (type === EVolumeType.Fallow) {
      setValue(EVolumeTypeWay[v]);
    } else {
      setValue(v);
    }
  };
  return (
    <>
      <div className={styles.field_label}>
        <div className={cn(styles.label)}>
          {"Volume setting"}
          <Tooltip
            className={styles.tooltip}
            title={Help[type]}
            icon="info"
            place="right"
          />
          <IsAdmin>
            {readonly && (
              <span className="text-red" style={{ fontSize: 16 }}>
                {"You do not have permission to change this section"}
              </span>
            )}
          </IsAdmin>
        </div>
      </div>
      <TooltipGlodal />
      <div className={styles.field_volum}>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={type}
          setValue={onChangeType}
          options={EVolumeType.keys()}
          readonly={readonly}
        /> 
        <VolumeInput
          readonly={readonly}
          value={value}
          volume={type}
          onChange={onChangeValue}
        />
      </div>
      <div className={styles.field_error}>
        {(props.error?.type?.message || props.error?.value?.message) && (
          <span className={styles.error}>Volume value must be a reqird</span>
        )}
      </div>
    </>
  );
};
