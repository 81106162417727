import React from "react";
import cn from "classnames";
import styles from "./Loader.module.sass";
import ContentLoader from "react-content-loader";
import { useLoadingState } from "../../context/loading";
export * from "./circle-loader";
export * from "./loader-mint";
export const Loader = ({ className, white }) => {
  return (
    <div
      className={cn(styles.loader, className, {
        [styles.loaderWhite]: white,
      })}
    ></div>
  );
};

export const Loading = ({force}) => {
  const loading = useLoadingState();
  return (
    <>
      {(loading || force )&& (
        <div className={cn(styles.loading)}>
          <div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export const PreLoader = ({ width }) => (
  <ContentLoader
    width={width ?? 50}
    height={12}
    speed={1}
    backgroundColor={"#333"}
    foregroundColor={"#999"}
  >
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="13" />
  </ContentLoader>
);
