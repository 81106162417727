import React, { useState } from "react";
import styles from "./List.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import { Loader } from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Filters from "../../../components/Filters";
import Row from "./Row";
import NewAdmin from "./New";
import TextInput from "../../../components/TextInput";
import { useUserDispatch, useUserState } from "../../../context";
const intervals = ["15 in page", "25 in page", "50 in page"];
const List = ({
  className,
  isLoading,
  page,
  data,
  refetch,
  filters,
  changeFilter,
  changePage,
  changeCount,
}) => {
  const [count, setCount] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const action = useUserDispatch();
  const state = useUserState();
  const onChangeCount = (count) => {
    setCount(count);
    changeCount(count.replace(" in page", ""));
  };
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Admin Plan"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <TextInput
              className={styles.query}
              type="text"
              onChange={(e) => changeFilter("query")(e.target.value)}
              placeholder="search name"
            />
            {/* <Filters title="Admin Plan filter" className={styles.filters}>
              <>
                <TextInput
                  className={styles.field}
                  type="text"
                  onChange={(e) => changeFilter("query")(e.target.value)}
                  placeholder="search name"
                />
                <br />
                <TextInput
                  type="number"
                  className={styles.field}
                  onChange={(e) =>
                    changeFilter("accountNumber")(e.target.value)
                  }
                  placeholder="search account"
                />
              </>
            </Filters> */}
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={count}
              setValue={onChangeCount}
              options={intervals}
              small
            />
            {action.canAdmin() && (
              <button
                className={cn("button-small", styles.button)}
                onClick={() => setVisibleModal(true)}
              >
                New Plan
              </button>
            )}
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row} id="sticky">
            <div className={styles.col} style={{ textAlign: "center" }}>
              Name
            </div>
            {state.isSuperAdmin && <div className={styles.col}>Admin</div>}
            <div className={styles.col}>Price</div>
            <div className={styles.col}>Plan</div>
            <div className={styles.col}>Volume</div>
            <div className={styles.col}>Create Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {data?.map((x, index) => (
            <Row
              state={state}
              index={index}
              count={data.length}
              refetch={refetch}
              item={x}
              key={index}
            />
          ))}
        </div>
        <div className={styles.foot}>
          <button
            onClick={() => changePage(page + 1)}
            className={cn("button-stroke button-small", styles.button)}
          >
            {isLoading && <Loader className={styles.loader} />}
            <span>Load more</span>
          </button>
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewAdmin onClose={() => setVisibleModal(false)} refetch={refetch} />
      </Modal>
    </>
  );
};

export default List;
