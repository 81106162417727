import React, { useEffect, useState } from "react";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { URL, useGetSetting, useListAdminPlan } from "../../api";
import { Loader, LoaderMint } from "../../components/Loader";
import AdminCard from "./AdminCard";
import Seo from "../../components/Seo";
import { web3Modal, onSignClient } from "components/Wallet/Web3Modal";
import classNames from "classnames";
import { useWalletDispatch, useWalletState } from "context";
import Icon from "components/Icon";

const proposalNamespace = {
  eip155: {
    methods: ["eth_sendTransaction", "personal_sign"],
    chains: ["eip155:1"],
    events: ["connect", "disconnect", "chainChanged", "accountsChanged"],
  },
};

const Filter = ({ filters, changeFilter }) => {
  const IconName = ({ name }) => {
    if (filters?.sort && name in filters?.sort) {
      if (filters?.sort[name]) {
        return <Icon name="arrow-top" size={16} />;
      }
      return <Icon name="arrow-bottom" size={16} />;
    }
    return <Icon name="arrows-up-down" size={16} />;
  };
  const onSort = (name) => () =>
    changeFilter("sort")({
      // ...filters?.sort,
      [name]:
        filters?.sort && name in filters?.sort ? !filters?.sort[name] : true,
    });

  return (
    <div className={styles.filters}>
      <button
        type="button"
        onClick={onSort("percentProfit")}
        className={classNames("button-stroke button-small", styles.button, {
          [styles.buttonActive]:
            filters?.sort && "percentProfit" in filters?.sort,
        })}
      >
        <IconName name="percentProfit" size={16} />
        <span>Percent profit</span>
      </button>
      <button
        type="button"
        onClick={onSort("reliability")}
        className={classNames("button-stroke button-small", styles.button, {
          [styles.buttonActive]:
            filters?.sort && "reliability" in filters?.sort,
        })}
      >
        <IconName name="reliability" size={16} />
        <span>Reliability</span>
      </button>
      <button
        type="button"
        onClick={onSort("subscribers")}
        className={classNames("button-stroke button-small", styles.button, {
          [styles.buttonActive]:
            filters?.sort && "subscribers" in filters?.sort,
        })}
      >
        <IconName name="subscribers" force />
        <span>Subscribers</span>
      </button>
      <button
        type="button"
        onClick={onSort("day")}
        className={classNames("button-stroke button-small", styles.button, {
          [styles.buttonActive]:
            filters?.sort && "day" in filters?.sort,
        })}
      >
        <IconName name="day" />
        <span>Day</span>
      </button>
    </div>
  );
};
const Home = () => {
  const { data: header } = useGetSetting("header");
  const { data: footer } = useGetSetting("footer");
  const { data: filter } = useGetSetting("filter");
  const { data, isLoading, filters, changeFilter, changePage, page } =
    useListAdminPlan();
  const { account, signClient } = useWalletState();
  const action = useWalletDispatch();

  return (
    <>
    
      <div
        className={styles.header_ui}
        dangerouslySetInnerHTML={{
          __html: header?.txt?.replace(/\n/g, "<br />"),
        }}
      ></div>
      {filter && filter?.filter && (
        <>
          <Filter {...{ filters, changeFilter }} />
        </>
      )}
      {data &&
        data?.map((admin, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              <AdminCard
                onClick={action?.onConnect}
                className={styles.card}
                admin={admin}
                showBox={filter?.box}
              />
            </div>
          </div>
        ))}
      <div className={styles.foot}>
        <button
          type="button"
          onClick={() => changePage(page + 1)}
          className={classNames("button-stroke button-small", styles.button)}
        >
          {isLoading && <Loader className={styles.loader} />}
          <span>Load more</span>
        </button>
      </div>
      <div
        className={styles.footer_ui}
        dangerouslySetInnerHTML={{
          __html: footer?.txt?.replace(/\n/g, "<br />"),
        }}
      ></div>
    </>
  );
};

export default Home;
