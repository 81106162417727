import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import { useHistory } from "react-router";
import {
  useUserDispatch,
  useUserState,
  useWalletDispatch,
  useWalletState,
  useTransactionState,
  useLoadingDispatch,
  useTransactionDispatch,
} from "../../context";
import { Img } from "components/Image";
import {
  URL,
  useCheckChargeAccount,
  useCheckTransaction,
  useDenyTransaction,
  useExtendTransaction,
} from "api";
import { Timer } from "components/Timer";
import { Notify } from "components/Notify";
import Modal from "components/Modal";
import { WalletPaid } from "./WalletPaid";
import { PlanPaid } from "./PlanPaid";
import Del from "./Del";
let timer = null;
const Header = ({ onOpen, search }) => {
  const [visible, setVisible] = useState(false);
  const { connect, session } = useWalletState();
  const [visibleDel, setDelModal] = React.useState(false);
  const { trxModal, ...stateTrx } = useTransactionState();
  const { setTrxModal, setPaid, ...actionTrx } = useTransactionDispatch();
  const wallet = useWalletDispatch();
  const history = useHistory();
  const action = useUserDispatch();
  const loading = useLoadingDispatch();
  const user = useUserState();
  const _checkCharge = useCheckChargeAccount();
  const _checkPlan = useCheckTransaction();
  const _Extend = useExtendTransaction();
  const _deny = useDenyTransaction();
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };
  const onLogout = () => {
    action.onLogout(history, user.isMasterLocked);
  };
  useEffect(() => {
    if (stateTrx && stateTrx?.trx) {
      timer = setInterval(
        stateTrx?.trx?.plan ? onCheckPlan : onCheckCharge,
        10000
      );
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [stateTrx]);
  useEffect(() => {
    if (user.isAuth)
      if (!stateTrx?.trx) {
        actionTrx?.lastTransaction();
      }
  }, [user, stateTrx]);
  const onCheckCharge = async () => {
    const response = await _checkCharge.mutateAsync({
      authority: stateTrx.trx?.authority,
    });
    if (!response?.error && 'data' in response) {
      timer && clearInterval(timer);
      console.log('charge account response',response)
      Notify.success("charge account successfull!");
      action.updateClientBalance();
      await actionTrx?.lastTransaction();
      actionTrx?.createTrx(null);
      setPaid(response.data);
      // onClose();
      return;
    }
  };
  const onCheckPlan = async () => {
    const response = await _checkPlan.mutateAsync({
      authority: stateTrx.trx?.authority,
    });
    if (!response?.error) {
      timer && clearInterval(timer);
      if (response?.paid) {
        Notify.success("The purchase of the plan was successfully completed!"); 
        // actionTrx?.createTrx(null);
        setPaid(response.data);
        await actionTrx?.lastTransaction();
      } else {
        Notify.info("The plan was not purchased!");
        Notify.success(`charge account ${response?.walletCharge} successfull!`);
        setTrxModal(false);
        actionTrx?.createTrx(null);
        await action?.updateClientBalance();
        await actionTrx?.lastTransaction();
      }
      // onClose();
      return;
    }
  };
  const onDenyTrx = async () => {
    loading.show();
    const response = await _deny.mutateAsync({
      authority: stateTrx.trx?.authority,
    });
    loading.hide();
    setDelModal(false);
    if (!response?.error) {
      Notify.success("close transaction successfull!");
      actionTrx.createTrx(null);
      return;
    }
  };
  const onExtend = async () => {
    loading.show();
    const response = await _Extend.mutateAsync({
      authority: stateTrx.trx?.authority,
    });
    if (!response?.error) {
      Notify.success("Extend transaction successfull!");
      await actionTrx?.lastTransaction();
      loading.hide();
      return;
    }
    loading.hide();
  };
  const onModalPay = async () => {
    // loading.show();
    await actionTrx?.lastTransaction();
    setTrxModal(true);
    // loading.hide();
  };
  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>

      {user.isAuth && (
        <>
          {user.isUser ? (
            <h1 className={styles.not_mobile}>
              Welcome&nbsp;
              {user?.user?.name}.
            </h1>
          ) : (
            <>
              <Img src={`${URL.CdnUrl}${user?.user?.profile?.image}`} />
              <h1>{user?.user?.name}.</h1>
            </>
          )}
        </>
      )}
      {stateTrx?.trx && !stateTrx?.paid && (
        <>
          <button
            className={cn("button", styles.button, styles.button_green)}
            onClick={onModalPay}
          >
            <Icon name="wallet" size="24" viewBox="0 0 24 24" />
            Waiting
            <span className={styles.not_mobile}>&nbsp;transaction</span>
            &nbsp;
            {stateTrx?.trx?.amount}$
            <span className={styles.not_mobile}>&nbsp;after</span>
            &nbsp;
            <Timer
              expired={stateTrx?.trx.timer}
              all={30 * 60 * 1000}
              onEndTime={(t) => {
                actionTrx.createTrx(null);
                setTrxModal(false);
              }}
            />
          </button>
        </>
      )}
      {trxModal && (
        <Modal
          outerClassName={styles.outer}
          visible={trxModal}
          onClose={() => {
            setTrxModal(false);
          }}
        >
          {stateTrx?.trx?.plan ? (
            <PlanPaid
              item={{
                address: stateTrx?.trx?.details?.to,
                _id: stateTrx?.trx?.wallet,
                authority: stateTrx?.trx?.authority,
              }}
              paid={stateTrx?.paid}
              expired={stateTrx?.trx?.timer}
              plan={{
                ...stateTrx?.trx?.plan,
              }}
              onExtend={onExtend}
              onDenyTrx={() => setDelModal(true)}
              onClose={() => {
                setTrxModal(false);
              }}
            />
          ) : (
            <>
              <WalletPaid
                item={{
                  address: stateTrx?.trx?.details?.to,
                  _id: stateTrx?.trx?.wallet,
                  authority: stateTrx?.trx?.authority,
                }}
                paid={stateTrx?.paid}
                expired={stateTrx?.trx?.timer}
                plan={{
                  name: "Charge account",
                  price: stateTrx?.trx?.amount,
                }}
                onDenyTrx={() => setDelModal(true)}
                onExtend={onExtend}
                onClose={() => {
                  setTrxModal(false);
                }}
              />
            </>
          )}
        </Modal>
      )}
      <Modal
        visible={visibleDel}
        onClose={() => {
          setTrxModal(true);
          setDelModal(false);
        }}
      >
        <>
          <Del
            onClose={() => {
              setTrxModal(true);
              setDelModal(false);
            }}
            onSubmit={onDenyTrx}
          />
        </>
      </Modal>
      <div className={styles.control}>
        {user?.isUser && (
          <button
            className={cn("button", styles.button, styles.button_info)}
            onClick={() => history.push("/account-recharge")}
          >
            <Icon name="wallet" size="24" viewBox="0 0 24 24" />
            $ {user?.user?.balance || 0} 
          </button>
        )}
        {user.isAuth ? (
          <button className={cn("button", styles.button)} onClick={onLogout}>
            {user.isMasterLocked ? (
              <>
                <Icon name="arrow-left" size="24" /> Back to super admin
              </>
            ) : (
              <>
                <Icon name="lock" size="24" />
                Logout
              </>
            )}
          </button>
        ) : (
          <button
            className={cn("button", styles.button)}
            onClick={() => history.push("/sign-in")}
          >
            <Icon name="lock" size="24" />
            Signin
          </button>
        )}
        {connect && (
          <button
            className={cn("button", styles.button, styles.button_red)}
            onClick={wallet.onDisconnect}
          >
            <Icon name="wallet" size="24" viewBox="0 0 24 24" />
            Disconnect {session && session?.peer?.metadata?.name}
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
