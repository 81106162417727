import React, { useMemo } from "react";
import { useMutation } from "react-query";
import { http } from "./http.config";
import { URL } from "./url.enum";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
const APP = "MQL_";
const MASTER = "MASTER";
export const useSignIn = () => {
  return useMutation(async ({ isAdmin, ...data }) => {
    try {
      const res = await http.post(
        isAdmin ? URL.MASTER_LOGIN : URL.CLIENT_LOGIN,
        { ...data, email: data.email?.toLocaleLowerCase() }
      );
      //TODO error notify
      debugger
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useVerifyAccount = () => {
  return useMutation(async ({ token, role }) => {
    try {
      const res = await http.post(
        role === "user" ? URL.CLIENT_VERIFY : URL.MASTER_VERIFY,
        { token }
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useSignInForm = (defaultValues) => {
  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().min(4).max(16).required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const _ = useSignIn();
  return useMemo(() => {
    return [
      {
        email: { ...register("email") },
        password: { ...register("password") },
        errors,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};

export const useSignUp = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.post(URL.CLIENT, {
        ...data,
        email: data?.email?.toLocaleLowerCase(),
      });
      //TODO error notify
      return res;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useForgotPassword = () => {
  return useMutation(async (data) => {
    try {
      const url = data.isAdmin
        ? URL.MASTER_FORGET_PASSWORD
        : URL.CLIENT_FORGET_PASSWORD;
      const res = await http.post(url, data);
      return res;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useSignUpForm = (defaultValues) => {
  const schema = yup
    .object({
      email: yup.string().email().required(),
      name: yup.string().max(150).required(),
      password: yup.string().min(4).max(16).required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const _ = useSignUp();
  return useMemo(() => {
    return [
      {
        email: { ...register("email") },
        name: { ...register("name") },
        password: { ...register("password") },
        errors,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useLoginAdminWithId = () => {
  return useMutation(async (_id) => {
    try {
      const res = await http.patch(
        `${URL.MASTER_LOGIN}/${_id}`,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
function setItem(cname, cvalue, exh = 2) {
  const d = new Date();
  d.setTime(d.getTime() + exh * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = APP + cname + "=" + cvalue + ";" + expires + ";path=/";
  localStorage.setItem(APP + cname, cvalue);
}
function getItem(cname) {
  let name = APP + cname + "=";
  const authS = localStorage.getItem(APP + cname);
  if (document?.cookie && decodeURIComponent) {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");

    let authC;
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        authC = c.substring(name.length, c.length);
        break;
      }
    }
    if (authC && authS) {
      return authS;
    }
  } else {
    if (authS) {
      return authS;
    }
  }

  return "";
}
export const getAuth = () => {
  const w = getItem("auth");
  if (w) {
    const auth = JSON.parse(w);
    return {
      ...auth,
      user: parseJwt(auth?.token),
    };
  }
  return null;
};
export const setAuth = (auth) => {
  if (!auth) {
    return setItem("auth", "", 0);
  }
  return setItem("auth", JSON.stringify(auth), 48);
};
export const lockedMaster = () => {
  const auth = getAuth();
  return setItem(MASTER, JSON.stringify(auth), 48);
};
export const getMaster = () => {
  return JSON.parse(getItem(MASTER));
};
export const unLockedMaster = () => {
  const data = getMaster();
  setItem(MASTER, "", 0);
  setAuth(data);
  return data;
};

export const getAuthHeader = (headers = {}) => {
  const auth = getAuth();
  console.log('auth', auth) 
  return {
    headers: {
      Authorization: `Bearer ${auth.token}`,
      ...headers,
    },
  };
};
