import React from "react";
import useDarkMode from "use-dark-mode";
import styles from "./AdminCardItem.module.sass";
import Icon from "components/Icon";
import Balance from "components/Balance";
import { ChartBar, ChartDay, ChartPeople, ChartPi } from "components/Chart";
import classNames from "classnames";

const center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  export const AdminCardItem = ({
    className,
    src = "/noimage.png",
    noimage = "/noimage.png",
    alt = "image",
    title,
    createdAt,
    stats,
    onShow,
  }) => (
    <div className={styles.admin}>
      <div className={styles.item}>
        <div className={styles.logo}>
          <div className={styles.avatar}>
            <img
              alt={alt}
              src={src}
              className={className}
              onError={({ currentTarget }) => {
                if (currentTarget) {
                  currentTarget.onerror = null;
                  currentTarget.src = noimage;
                }
              }}
            />
          </div>
          <div className={styles.details}>
            <div className={styles.user}>
              {title} <Icon name="blue" />
            </div>
            <div className={styles.date}>
              {new Date(createdAt || new Date()).toDateString()}
            </div>
            <div onClick={onShow} className={classNames('link',styles.link)}>
            <Icon name="info"  /> {'About me'} 
            </div>
          </div>
        </div>
  
        <div className={styles.details}>
          <div
            className={styles.user}
            style={{
              minHeight: "64px",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingBottom: "12px",
            }}
          >
            <Balance
              className={styles.balance}
              value={stats?.percentProfit || 0}
              background
            />
          </div>
          <div
            className={styles.date}
            style={center}
          >
            {"Percent profit"}
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.user} style={center}>
            <ChartBar count={stats?.reliability || 1} />
          </div>
          <div className={styles.date}    style={center}>{"Reliability"}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.user} style={center}>
            <ChartPi count={stats?.algoTrading || 0} />
          </div>
          <div className={styles.date} style={center}>{"Algo Trading"}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.user} style={center}>
            <ChartPeople count={stats?.userTotal || 0} />
          </div>
          <div className={styles.date} style={center}>{"Subscribers"}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.user} style={center}>
            <ChartDay count={stats?.passDay || 0} />
          </div>
          <div className={styles.date} style={center}>{"Day"}</div>
        </div>
      </div>
    </div>
  );
  