import React, { useState } from "react";
import styles from "./List.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewAdmin from "./New";
import { useGetPaidLogAdmin } from "api";
import Icon from "components/Icon";
import { useHistory } from "react-router-dom";
const List = ({ className, id }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const { data, refetch } = useGetPaidLogAdmin(id);
  const h = useHistory();
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={
          <>
            <span  className={cn("link", styles.back)} onClick={() => h.push('/masters')}>
              <Icon name="arrow-left" size={34} />
               {data?.name}({data?.account}) Paid
            </span>
          </>
        }
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              New Paid
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row} id="sticky">
            <div className={styles.col}>Price</div>
            <div className={styles.col}>From</div>
            <div className={styles.col}>To</div>
            <div className={styles.col}>Hash</div>
            <div className={styles.col}>Discription</div>
            <div className={styles.col}>Date</div>
          </div>
          {"logPaid" in data &&
            data?.logPaid?.map((x, index) => (
              <Row refetch={refetch} item={x} key={index} />
            ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        {id && (
          <NewAdmin
            id={id}
            onClose={() => setVisibleModal(false)}
            refetch={refetch}
          />
        )}
      </Modal>
    </>
  );
};

export default List;
