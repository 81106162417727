import { useMutation } from "react-query";
import { http } from "./http.config";
import { URL } from "./url.enum";
import { getAuthHeader } from "./useAuth";

export const useUploadFile = () => {
  return useMutation(async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const res = await http.post(URL.UPLOAD_IMAGE, formData, getAuthHeader());
      console.log("res useUploadFile", res);
      return res?.data;
    } catch (error) {
      console.log("error useUploadFile cache", error);
      return {
        error: true,
        message: error?.message || error?.response?.data?.message,
      };
    }
  });
};
export const useUploadLogo = () => {
  return useMutation(async ({ file, path }) => {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const res = await http.post(
        path === "dark" ? URL.UPLOAD_LOGO_DARK : URL.UPLOAD_LOGO_LIGHT,
        formData,
        getAuthHeader()
      );
      console.log("res useUploadFile", res);
      return res?.data;
    } catch (error) {
      console.log("error useUploadFile cache", error);
      return {
        error: true,
        message: error?.message || error?.response?.data?.message,
      };
    }
  });
};

export const useUploadMqlFile = () => {
  return useMutation(async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const res = await http.post(URL.UPLOAD_MQL, formData, getAuthHeader());
      console.log("res useUploadFile", res);
      return res?.data;
    } catch (error) {
      console.log("error useUploadFile cache", error);
      return {
        error: true,
        message: error?.message || error?.response?.data?.message,
      };
    }
  });
};
export const useUploadVideoFile = () => {
  return useMutation(async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const res = await http.post(URL.UPLOAD_VIDEO, formData, getAuthHeader());
      console.log("res useUploadFile", res);
      return res?.data;
    } catch (error) {
      console.log("error useUploadFile cache", error);
      return {
        error: true,
        message: error?.message || error?.response?.data?.message,
      };
    }
  });
};
export const useDeleteFile = () => {
  return useMutation(async ({ file }) => {
    try {
      if (typeof file === "string") {
        const res = await http.put(URL.UPLOAD_IMAGE + '/delete', { file }, getAuthHeader());
        return res;
      } else {
        for (let i = 0; i < file.length; i++) {
          const path = file[i];
          if (path.type === 'link')
            await http.put(URL.UPLOAD_IMAGE + '/delete', { file: path.file }, getAuthHeader());
        }
        return { error: false }
      }
    } catch (error) {
      return {
        error: true,
        message: error?.message || error?.response?.data?.message,
      };
    }
  });
};