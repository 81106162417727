import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";

const items = [
  {
    title: "Users",
    counter: "256k",
    icon: "profile-circle",
    color: "#B5E4CA",
    tooltip: "All users signup in website",
    value: "userTotal",
  },
  {
    title: "Transaction",
    counter: "1.2x",
    icon: "dollar",
    color: "#CABDFF",
    tooltip: "All transaction successfully in website",
    value: "transactionTotal",
    fixed: 2,
  },
  {
    title: "Master",
    counter: "128",
    icon: "smile",
    color: "#FFBC99",
    tooltip: "All master in website",
    value: "adminTotal",
  },
  // {
  //   title: "Link clicks",
  //   counter: "80",
  //   icon: "mouse",
  //   color: "#B1E5FC",
  //   tooltip: "Small description Link clicks",
  //   value: -11.3,
  // },
];
const itemsAdmin = [
  {
    title: "Account",
    counter: "256k",
    icon: "profile-circle",
    color: "#B5E4CA",
    tooltip: "All account join in your profile",
    value: "userTotal",
  },
  {
    title: "Transaction",
    counter: "1.2x",
    icon: "dollar",
    color: "#CABDFF",
    tooltip: "All transaction successfully in your profile",
    value: "traderShare",
    fixed: 2,
  },
  {
    title: "Plan",
    counter: "128",
    icon: "smile",
    color: "#FFBC99",
    tooltip: "All plan in your profile",
    value: "planTotal",
  },
  // {
  //   title: "Link clicks",
  //   counter: "80",
  //   icon: "mouse",
  //   color: "#B1E5FC",
  //   tooltip: "Small description Link clicks",
  //   value: -11.3,
  // },
];

const Overview = ({ className, state, stats }) => {
  let arr = itemsAdmin;
  if (state.isSuperAdmin) {
    arr = items;
  }
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Insights"
        classTitle="title-red"
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {arr?.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name={x.icon} size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    {x.title}
                    <Tooltip
                      className={styles.tooltip}
                      title={x.tooltip}
                      icon="info"
                      place="top"
                    />
                  </div>

                  <div className={styles.counter}>
                    {"fixed" in x
                      ? (stats[x.value] || 0)?.toFixed(x.fixed)
                      : stats[x.value] || 0}
                  </div>
                  {/* <div className={styles.indicator}>
                    <Balance className={styles.balance} value={stats[x.value]} />
                    <span>this All time</span>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};

export default Overview;
