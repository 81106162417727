import * as Types from "./types";

export const Reducer = (state, action) => {
  switch (action?.type) {
    case Types.SHOW: {
      return  true;
    }
    case Types.HIDE: {
      return  false;
    }
    default:
      throw new Error(`Loading Unhandled action type: ${action.type}`);
  }
};
