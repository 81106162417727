import React, { useEffect, useState } from "react";
import styles from "./EditSetting.module.sass";
import cn from "classnames";
import Card from "components/Card";
import TextEdit from "components/TextEdit";
const EditSetting = ({ title, className, txt, setTxt, onSave }) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={title}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => onSave(txt)}
            >
              Update
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <TextEdit
            value={txt}
            placeholder="What you would like to share?"
            onChange={(value) => {
              setTxt(value);
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default EditSetting;
