import React from "react";
import styles from "./PlanCard.module.sass";
import cn from "classnames";

import { Img, NoImage } from "../../../../components/Image";
import { EPlanType, EVolumeType, URL } from "../../../../api";
import VolumeValue from "screens/AdminPlan/List/Row/VolumeValue";
import Price from "components/Price";
import Icon from "components/Icon";
const PlanCard = ({ plan, onClick }) => {
  return (
    <>
      <div className={cn(styles.card)}>
        <div className={styles.item}>
          <div className={styles.left}>
            <div className={styles.top}>
              <div className={styles.preview}>
                {plan.image ? (
                  <Img src={`${URL.CdnUrl}${plan.image}`} alt="Product" />
                ) : (
                  <NoImage />
                )}
              </div>
              <div>
                <div className={styles.win}>{"Price"}</div>
                {/* <div className={styles.win}>{EPlanType[plan.plan.type]}</div> */}
                {/* <div className={styles.win_number}>{plan.plan.value || ''} X</div> */}
                <div className={styles.win_number}>
                  {plan?.price && plan?.price > 0 ? <>
                    $  <Price>{plan.price}</Price>
                  </> : "Free"}
                </div>
                <div className={styles.type}>
                  {plan.testVerstion && (
                    <div className={cn("status-aptos", styles.status)}>
                      Demo
                    </div>
                  )}
                  {/* {plan?.dailyDrowDown > 0 && (
                    <div className={cn("status-green", styles.status)}>
                      Daily Drow Down : {plan.dailyDrowDown}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.title}>
                {plan.name}
                &nbsp; &nbsp;
              </div>
              <div className={styles.stage}>
                Plan({EPlanType[plan.plan.type]}) : {plan.plan.value} | Volume(
                {EVolumeType[plan.volume.type]}) :
                <VolumeValue {...plan?.volume} />
              </div>
            </div>
          </div>
          <div onClick={onClick} className={styles.right}>
            <div className={styles.enter}>
               <Icon name="like" size={24} />
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanCard;
