import { useMemo } from "react";
import { useMutation } from "react-query";
import { URL } from "./url.enum";
import { usePagesWithLoadeMore } from "./usePages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getAuthHeader } from "./useAuth";
import { http } from "./http.config";
import { EVolumeType } from "./volume.enum";
import { EPlanType } from "./plan.enum";

const useCreateAdminPlanApi = () => {
  return useMutation(async (data) => {
    try {
      console.table(data);
      const sendData = {
        price: data.price,
        active: data?.active,
        name: data?.name,
        image: data?.image,
        description: data?.description,
        testVerstion: data?.testVerstion,
        dailyDrowDown: data?.dailyDrowDown,
        plan: {
          type: EPlanType[data?.plan.type?.toUpperCase()],
          value: data?.plan.value,
        },
        volume: {
          type: EVolumeType.get(data?.volume.type),
          value: data?.volume.value,
        },
      };
      console.table(sendData);
      debugger;
      const res = await http.post(URL.ADMIN_PLAN, sendData, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useCreateAdminPlanForm = () => {
  const schema = yup
    .object({
      price: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .typeError("Price must be a number")
        .required(),
      name: yup.string().max(150).required(), //it is options
      description: yup.string().max(1500).optional(), //it is options
      image: yup.string().optional(),
      status: yup.mixed().optional(),
      plan: yup
        .object({
          type: yup.mixed().oneOf(EPlanType.keys()).required(),
          value: yup
            .number()
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .typeError("Plan type must be a number")
            .min(0)
            .optional(),
        })
        .required(),
      volume: yup
        .object({
          type: yup.mixed().oneOf(EVolumeType.keys()).required(),
          value: yup.mixed().required(),
        })
        .required(),
    })
    .required();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const _ = useCreateAdminPlanApi();
  return useMemo(() => {
    return [
      {
        register,
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};

const useEditAdminPlanApi = () => {
  return useMutation(async (data) => {
    try {
      const sendData = {
        price: data.price,
        active: data.active,
        name: data?.name,
        image: data?.image,
        description: data?.description,
        dailyDrowDown: data?.dailyDrowDown,
        plan: {
          type: EPlanType[data?.plan.type?.toUpperCase()],
          value: data?.plan.value,
        },
        volume: {
          type: EVolumeType.get(data?.volume.type),
          value: data?.volume.value,
        },
      };
      if ("testVerstion" in data) {
        sendData["testVerstion"] = data?.testVerstion;
      }
      console.table(sendData);
      const res = await http.put(
        URL.ADMIN_PLAN + "/" + data?._id,
        sendData,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useEditAdminPlanForm = (defaultValues) => {
  const schema = yup
    .object({
      price: yup.number().typeError("Price must be a number").required(),
      name: yup.string().max(150).required(), //it is options
      description: yup
        .string()
        .transform((value) =>
          value === null || value === undefined ? "" : value
        )
        .max(1500)
        .optional(), //it is options
      plan: yup
        .object({
          type: yup.mixed().oneOf(EPlanType.keys()).required(),
          value: yup
            .number()
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .typeError("Plan type must be a number")
            .min(0)
            .optional(),
        })
        .required(),
      volume: yup
        .object({
          type: yup.mixed().oneOf(EVolumeType.keys()).required(),
          value: yup.mixed().required(),
        })
        .required(),
    })
    .required();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      price: defaultValues?.price,
      name: defaultValues?.name,
      description: defaultValues?.description,
      status: defaultValues?.status,
      dailyDrowDown: defaultValues?.dailyDrowDown,
      plan: {
        type: EPlanType[defaultValues?.plan?.type],
        value: defaultValues?.plan?.value,
      },
      volume: {
        type: EVolumeType[defaultValues?.volume?.type],
        value: defaultValues?.volume?.value,
      },
    },
  });
  const _ = useEditAdminPlanApi();
  return useMemo(() => {
    return [
      {
        register,
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useListAdminPlanClient = () => {
  return usePagesWithLoadeMore(URL.MY_ADMIN_PLAN, true, 0, 15, {});
};
export const useListAdminPlan = () => {
  return usePagesWithLoadeMore(URL.ADMIN_PLAN, false, 0, 20, {});
};
export const useListAdminPlanAll = () => {
  return usePagesWithLoadeMore(URL.MY_ADMIN_PLAN, true, 0, 20000, {});
};

export const useDeleteAdminPlanApi = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.delete(
        URL.ADMIN_PLAN + "/" + data?._id,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useUpAdminPlanApi = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.ADMIN_PLAN + "/up/" + data?._id,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useDownAdminPlanApi = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.ADMIN_PLAN + "/down/" + data?._id,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
