import React, { useEffect, useState } from "react";
import styles from "./Setting.module.sass";
import Wallet from "./Wallet";
import Mql from "./Mql";

import { useGetSetting, useListWallet } from "../../api";
import SlipPage from "./SlipPage";
import Email from "./Email";
import MqlMaster from "./MqlMaster";
import Filter from "./Filter";
import Company from "./Company";
const Setting = (props) => {
  const data = useListWallet();
  const emails = useGetSetting("emails");
  return (
    <>
      <div className={styles.section}>
        <Company />
      </div>
      <div className={styles.section}>
        <SlipPage />
      </div>
      <div className={styles.section}>
        <Filter />
      </div>
      <div className={styles.section}>
        <Mql />
      </div>
      <div className={styles.section}>
        <MqlMaster />
      </div>
      <div className={styles.section}>
        <Email {...emails} />
      </div>
      <div className={styles.section}>
        <Wallet {...data} />
      </div>
    </>
  );
};

export default Setting;
