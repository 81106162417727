import React, { useMemo, useState } from "react";
import cn from "classnames";
import styles from "./UserProfileForm.module.sass";
import TextInput from "components/TextInput";
import { useUpdateProfileForm } from "api/useAdmin";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import { useUpdateClientProfileForm } from "api";
const UserProfileForm = ({ className, user,onProfile }) => {
  const [form, onForm, _] = useUpdateClientProfileForm({
    email: user?.email,
    name: user?.name,
  });
  const loading = useLoadingDispatch();
  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync(data);
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      onProfile({...user,...data})
      Notify.success("Update successfull!");
      return;
    }
  };
  
  return (
    <div className={cn(styles.packages, className)}>
      <div className={styles.list}>
        <div className={styles.package}>
          <div className={styles.top}>
            <div className={cn("title-purple", styles.title)}>
              {"Update Profile"}
            </div>
          </div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html:
                'Your can update profile and password <span role="img" aria-label="fire">🔥</span>',
            }}
          ></div>

          <form className={styles.body} onSubmit={onForm(onSubmit)}>
         
            <TextInput
              className={styles.field}
              placeholder="Your name"
              icon="info"
              error={form.errors.name?.message}
              {...form.name}
            />
            <TextInput
              className={styles.field}
              placeholder="Your email"
              icon="mail"
              readonly
              error={form.errors.email?.message}
              {...form.email}
            />
            <TextInput
              className={styles.field}
              type="password"
              placeholder="Password"
              icon="lock"
              error={form.errors.password?.message}
              {...form.password}
            /> 
            <button className={cn("button", styles.button)}>Update</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfileForm;
