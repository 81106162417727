import React, { useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Image from "../../../components/Image";
import { Link, useHistory } from "react-router-dom";
import { useSignUpForm } from "../../../api";
import { useLoadingDispatch } from "../../../context";
import { Notify } from "../../../components/Notify";
import Checkbox from "components/Checkbox";

const Entry = ({ onConfirm }) => {
  const [check, setCheck] = useState(false);
  const [form, onForm, _] = useSignUpForm();
  const loading = useLoadingDispatch();
  const signUp = async (data) => {
    if(!check){
     return Notify.error("Please check terms and condition");
    }
    loading.show();
    const response = await _.mutateAsync({
      ...data,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      Notify.success("SignUp successfull!Please check your email");
      onConfirm(response);
      return;
    }
  };
  return (
    <div className={styles.entry}>
      <form className={styles.body} onSubmit={onForm(signUp)}>
        <div className={styles.info}>Create a new account</div>
        <TextInput
          className={styles.field}
          type="text"
          placeholder="Your name"
          error={form.errors.name?.message}
          {...form.name}
          icon="info"
        />
        <TextInput
          className={styles.field}
          type="email"
          placeholder="Your email"
          icon="mail"
          error={form.errors.email?.message}
          {...form.email}
        />
        <TextInput
          className={styles.field}
          type="password"
          placeholder="Your password"
          icon="lock"
          error={form.errors.password?.message}
          {...form.password}
        />
        <Checkbox
          content={`Please confirm <a alt="terms and condition" rel="noopener" target="_blank"  class='link' href="/terms">
                terms and condition
              </a>
          `}
          value={check}
          onChange={(e) => setCheck(e.target.checked)}
        />
        <br />
        <button className={cn("button", styles.button)}>Continue</button>
        <div className={styles.info2}>
          Already a member?
          <Link className={styles.link} to="/sign-in">
            Sign in
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Entry;
