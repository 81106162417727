import { useMutation, useQuery } from "react-query";
import { URL } from "./url.enum";
import { getAuthHeader } from "./useAuth";
import { http } from "./http.config";

export const useUpdateSetting = () => {
  return useMutation(async ({ name, ...data }) => {
    try {
      const res = await http.put(
        `${URL.SETTING}/${name}`,
        data,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useGetSetting = (name) => {
  return useQuery(["setting" + name], async () => {
    try {
      const res = await http.get(`${URL.SETTING}/${name}`);
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
