import { useContext, createContext } from "react";

export const WalletStateContext = createContext();
export const WalletDispatchContext = createContext();

/**
 * Wallet State Managment
 * @returns state
 */
export const useWalletState = () => {
  const context = useContext(WalletStateContext);
  if (context === undefined) {
    throw new Error("useWalletState must be used within a WalletProvider");
  }
  return context;
};

/**
 * Wallet Action Managment
 * @returns Actions
 */
export const useWalletDispatch = () => {
  const context = useContext(WalletDispatchContext);
  if (context === undefined) {
    throw new Error("useWalletDispatch must be used within a WalletProvider");
  }
  return context;
};
