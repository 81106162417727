import React, { useEffect, useState } from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useLoadingDispatch } from "../../../../context";
import { Notify } from "../../../../components/Notify";
import {
  useChargeAccountByAdmin,
  useEditAccountWithAdminForm,
} from "../../../../api";
import TextInput from "../../../../components/TextInput";
import { Controller } from "react-hook-form";
import { Plan } from "../../../../components/Plan";
import { Volume } from "../../../../components/Volume";
import Select from "../../../../components/Select";
import Checkbox from "components/Checkbox";

const Edit = ({ refetch, role, onClose, item }) => {
  const loading = useLoadingDispatch();
  const [price, setPrice] = useState(0);
  const _ = useChargeAccountByAdmin();

  const onSubmit = async () => {
    // if (price < 1) {
    //   return Notify.error("Sorry! price > 1");
    // }
    loading.show();

    const response = await _.mutateAsync({
      price,
      client: item?._id,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success(`update balance ${price}$ successfull!`);
      return;
    }
  };

  return (
    <form>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>Charge wallet</div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Account number"
            icon="ticket"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            step={10}
          />
        </div>

        <div className={styles.foot}>
          <button
            type="button"
            onClick={() => onSubmit()}
            className={cn("button", styles.button)}
          >
            <span className="white">Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Edit;
