import React, { useState } from "react";
import styles from "./List.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import { Loader } from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Filters from "../../../components/Filters";
import Row from "./Row";
import NewAdmin from "./New";
import TextInput from "../../../components/TextInput";
import Checkbox from "components/Checkbox";
const intervals = ["15 in page", "25 in page", "50 in page"];
const List = ({
  className,
  isLoading,
  page,
  data,
  refetch,
  filters,
  changeFilter,
  changePage,
  changeCount,
}) => {
  const [count, setCount] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const onChangeCount = (count) => {
    setCount(count);
    changeCount(count.replace(" in page", ""));
  };
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Master"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Filters title="Master filter" className={styles.filters}>
              <>
                <Checkbox 
                  onChange={(e) => changeFilter("status")(e.target.checked)}
                  content="Show delete account"
                />
                <br />
                <TextInput
                  className={styles.field}
                  type="text"
                  onChange={(e) => changeFilter("query")(e.target.value)}
                  placeholder="search name"
                />
                <br />
                <TextInput
                  type="number"
                  className={styles.field}
                  onChange={(e) => changeFilter("account")(e.target.value)}
                  placeholder="search account"
                />
                <br />
                <TextInput
                  type="string"
                  className={styles.field}
                  onChange={(e) => changeFilter("email")(e.target.value)}
                  placeholder="search email"
                />
              </>
            </Filters>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={count}
              setValue={onChangeCount}
              options={intervals}
              small
            />
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              New Master
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row} id="sticky">
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Email</div>
            <div className={styles.col}>Plan #</div>
            <div className={styles.col}>Client #</div>
            <div className={styles.col}>Master share</div>
            <div className={styles.col}>Super Admin share</div>
            <div className={styles.col}>Total revenue $</div>
            <div className={styles.col}>Commission super admin  %</div>
            <div className={styles.col}>Total paid master $</div>
            <div className={styles.col}>Action</div>
          </div>
          {data?.map((x, index) => (
            <Row refetch={refetch} item={x} key={index} />
          ))}
        </div>
        <div className={styles.foot}>
          <button
            onClick={() => changePage(page + 1)}
            className={cn("button-stroke button-small", styles.button)}
          >
            {isLoading && <Loader className={styles.loader} />}
            <span>Load more</span>
          </button>
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewAdmin onClose={() => setVisibleModal(false)} refetch={refetch} />
      </Modal>
    </>
  );
};

export default List;
