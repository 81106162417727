import React from "react";
import { BarChart, Bar, PieChart, Pie, Sector } from "recharts";
import styles from "./Chart.module.sass";
import useDarkMode from "use-dark-mode";
import { Img } from "components/Image";
const GREEN = "#83BF6E";
export const ChartBar = ({ count }) => {
  let data = [
    {
      name: "Page A",
      uv: 10,
      fill: GREEN,
    },
    {
      name: "Page B",
      uv: 20,
      fill: GREEN,
    },
    {
      name: "Page C",
      uv: 30,
      fill: GREEN,
    },
    {
      name: "Page D",
      uv: 40,
      fill: GREEN,
    },
    {
      name: "Page E",
      uv: 50,
      fill: GREEN,
    },
  ];
  switch (count) {
    case 1:
      data = [
        {
          name: "Page A",
          uv: 10,
          fill: "#ff9900",
        },
        {
          name: "Page B",
          uv: 20,
          fill: "#bbbbbb19",
        },
        {
          name: "Page C",
          uv: 30,
          fill: "#bbbbbb19",
        },
        {
          name: "Page D",
          uv: 40,
          fill: "#bbbbbb19",
        },
        {
          name: "Page E",
          uv: 50,
          fill: "#bbbbbb19",
        },
      ];
      break;
    case 2:
      data = [
        {
          name: "Page A",
          uv: 10,
          fill: "#ff9900",
        },
        {
          name: "Page B",
          uv: 20,
          fill: "#ff9900",
        },
        {
          name: "Page C",
          uv: 30,
          fill: "#bbbbbb19",
        },
        {
          name: "Page D",
          uv: 40,
          fill: "#bbbbbb19",
        },
        {
          name: "Page E",
          uv: 50,
          fill: "#bbbbbb19",
        },
      ];
      break;
    case 3:
      data = [
        {
          name: "Page A",
          uv: 10,
          fill: "#ff9900",
        },
        {
          name: "Page B",
          uv: 20,
          fill: "#ff9900",
        },
        {
          name: "Page C",
          uv: 30,
          fill: "#ff9900",
        },
        {
          name: "Page D",
          uv: 40,
          fill: "#bbbbbb19",
        },
        {
          name: "Page E",
          uv: 50,
          fill: "#bbbbbb19",
        },
      ];
      break;
    case 4:
      data = [
        {
          name: "Page A",
          uv: 10,
          fill: GREEN,
        },
        {
          name: "Page B",
          uv: 20,
          fill: GREEN,
        },
        {
          name: "Page C",
          uv: 30,
          fill: GREEN,
        },
        {
          name: "Page D",
          uv: 40,
          fill: GREEN,
        },
        {
          name: "Page E",
          uv: 50,
          fill: "#bbbbbb19",
        },
      ];
      break;
    default:
      break;
  }
  const darkMode = useDarkMode(false);
  return (
    <div className={styles.chart}>
      <BarChart width={70} height={50} data={data}>
        <Bar dataKey="uv" />
      </BarChart>
    </div>
  );
};

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};
export const ChartPi = ({ count }) => {
  const data =
    parseFloat(count) >= 100
      ? [{ name: `100 %`, value: 100, fill: GREEN }]
      : [
          { name: `${count} %`, value: parseFloat(count), fill: GREEN },
          { name: "", value: 100 - parseFloat(count), fill: "#bbbbbb19" },
        ];
  return (
    <div className={styles.chart}>
      <PieChart width={60} height={60}>
        <Pie
          activeIndex={0}
          activeShape={renderActiveShape}
          data={data}
          innerRadius={25}
          outerRadius={30}
          dataKey="value"
        />
      </PieChart>
    </div>
  );
};
export const ChartPeople = ({ count }) => {
  const getCount=()=>{
    if(count >= 100000){
      return "10m+"
    }
    if(count >= 10000){
      return "1m+"
    }
    if(count >= 1000){
      return "1K+"
    }

    return count;
  }
    return (
      <div className={styles.people}>
        <img src="/img/s3/people.svg" alt="logo" />
        <div>{getCount()}</div>
      </div>
    );
  };
  
export const ChartDay = ({ count }) => {
  
    return (
      <div className={styles.day}>
        <img src="/img/s3/day.svg" alt="logo" />
        <div>{count}</div>
      </div>
    );
  };
  