import React, { useState } from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { EVolumeType, EVolumeTypeWay } from "../../api";
import { Notify } from "components/Notify";
import Dropdown from "components/Dropdown";
const TextInputRef = React.forwardRef(
  (
    {
      className,
      classLabel,
      classInput,
      label,
      icon,
      copy,
      currency,
      tooltip,
      value,
      place,
      readonly,
      placeholder = "",
      error,
      onChange,
      onBlur,
      name,
      type = "text",
      ...props
    },
    ref
  ) => {
    const [password, setPassword] = useState(false);
    return (
      <>
        <div
          className={cn(
            styles.field,
            { [styles.fieldIcon]: icon },
            { [styles.fieldCopy]: copy },
            { [styles.fieldCurrency]: currency },
            className
          )}
        >
          {label && (
            <div className={cn(classLabel, styles.label)}>
              {label}
              {tooltip && (
                <Tooltip
                  className={styles.tooltip}
                  title={tooltip}
                  icon="info"
                  place={place ? place : "right"}
                />
              )}
            </div>
          )}
          <div className={styles.wrap}>
            <input
              className={cn(classInput, styles.input)}
              name={name}
              type={type === "password" ? (password ? "text" : type) : type}
              ref={ref}
              disabled={readonly}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              {...props}
            />

            {icon && (
              <div className={styles.icon}>
                <Icon name={icon} size="24" />{" "}
              </div>
            )}
            {copy && (
              <button className={styles.copy}>
                <Icon name="copy" size="24" />{" "}
              </button>
            )}
            {type === "password" && (
              <button
                type="button"
                onClick={() => setPassword(!password)}
                className={styles.copy}
              >
                <Icon name={password ? "eye" : "eye-closed"} size="24" />{" "}
              </button>
            )}
            {currency && <div className={styles.currency}>{currency}</div>}
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </div>
      </>
    );
  }
);
export const TextInputRefReadonly = React.forwardRef(
  (
    {
      className,
      classLabel,
      classInput,
      label,
      icon,
      copy,
      currency,
      tooltip,
      value,
      place,
      readonly,
      placeholder = "",
      error,
      onChange,
      onBlur,
      name,
      type = "text",
      ...props
    },
    ref
  ) => {
    const [password, setPassword] = useState(false);
    return (
      <>
        <div
          className={cn(
            styles.field,
            { [styles.fieldIcon]: icon },
            { [styles.fieldCopy]: copy },
            { [styles.fieldCurrency]: currency },
            className
          )}
        >
          {label && (
            <div className={cn(classLabel, styles.label)}>
              {label}
              {tooltip && (
                <Tooltip
                  className={styles.tooltip}
                  title={tooltip}
                  icon="info"
                  place={place ? place : "right"}
                />
              )}
            </div>
          )}
          <div className={styles.wrap}>
            <input
              className={cn(classInput, styles.input)}
              name={name}
              type={type === "password" ? (password ? "text" : type) : type}
              ref={ref}
              disabled={true}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              {...props}
            />

            {icon && (
              <div className={styles.icon}>
                <Icon name={icon} size="24" />{" "}
              </div>
            )}
            {copy && (
              <button className={styles.copy}>
                <Icon name="copy" size="24" />{" "}
              </button>
            )}
            {type === "password" && (
              <button
                type="button"
                onClick={() => setPassword(!password)}
                className={styles.copy}
              >
                <Icon name={password ? "eye" : "eye-closed"} size="24" />{" "}
              </button>
            )}
            {currency && <div className={styles.currency}>{currency}</div>}
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </div>
      </>
    );
  }
);
export const TextInput = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  iconSize = 24,
  copy,
  currency,
  tooltip,
  value,
  place,
  readonly,
  placeholder = "",
  error,
  onChange,
  onBlur,
  name,
  type = "text",
  ...props
}) => (
  <>
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        { [styles["pl-0"]]: !icon },
        { [styles["readonly-field"]]: readonly },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div
        className={cn(styles.wrap, {
          [styles.readonly]: readonly,
        })}
      >
        {readonly ? (
          <div className={cn(classInput, styles.input)}>
            {value || placeholder}
          </div>
        ) : (
          <input
            className={cn(classInput, styles.input)}
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
          />
        )}

        {icon && (
          <div className={styles.icon}>
            <Icon
              {...(typeof icon === "string"
                ? { name: icon, size: iconSize }
                : icon)}
            />{" "}
          </div>
        )}
        {copy && (
          <button
            type="button"
            className={styles.copy}
            onClick={() => {
              try {
                window.navigator.clipboard.writeText(value);
                Notify.success(`${value} add to clipboard`);
              } catch (error) {
                Notify.error("Sorry. copy is incorrect");
              }
            }}
          >
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  </>
);

const Input = {
  Fix: ({ onChange, readonly, value }) => {
    const [error, setError] = React.useState();
    const onChangeFix = (e) => {
      const v =
        isNaN(e.target.value) ||
        e.target.value === null ||
        e.target.value === undefined
          ? 0
          : e.target.value;
      setError("");
      if (!(v > 0) && !(v < 0)) setError("Fix value must be a number");
      if (v >= 0) onChange(v);
      else setError("Fix value must be a number");
    };
    return (
      <TextInput
        name={"FIX"}
        placeholder={`lot size example is 1.01`}
        type="number"
        value={value || 0}
        // icon={'payment'}
        onChange={onChangeFix}
        error={error}
        step={0.01}
        readonly={readonly}
      />
    );
  }, //FIX: 0, //	number
  Fallow: ({ onChange, value, readonly }) => {
    return (
      <Dropdown
        className={styles.dropdownfull}
        classDropdownHead={styles.dropdownHead}
        value={EVolumeTypeWay[value]}
        setValue={onChange}
        options={EVolumeTypeWay.keys()}
        readonly={readonly}
      />
    );
  }, //FALLOW: 1, //boolean
  Ratio: ({ onChange, value, readonly }) => {
    const [error, setError] = React.useState();
    const [ratio, setRatio] = React.useState({
      balance: 0,
      lot: 0,
      way: EVolumeTypeWay.Balance,
    });
    React.useEffect(() => {
      if (value && typeof value === "string") {
        const [balance, lot, way] = value?.split(":");
        if (lot && balance) {
          setRatio({
            balance,
            lot,
            way: `${way}` === "1" || `${way}` === "2" ? way : EVolumeTypeWay.Balance,
          });
        }
      }
    }, [value]);
    const onChangeRatio = (key) => (e) => {
      let v = "";
      if (key === "way") {
        v = EVolumeTypeWay.get(e);
      } else {
        v =
          isNaN(e.target.value) ||
          e.target.value === null ||
          e.target.value === undefined
            ? 0
            : e.target.value;
      }

      setError({});
      const nr = {
        ...ratio,
        [key]: v,
      };
      setRatio(nr);
      if (nr.balance > 0 && nr.lot > 0)
        onChange(`${nr.balance}:${nr.lot}:${nr.way}`);
      if (nr[key] <= 0) setError({ ...error, [key]: key + " not valid" });
    };
    return (
      <>
        <TextInput
          className={styles.inputRatio}
          placeholder={`each 100$`}
          type="number"
          value={ratio?.balance || ""}
          onChange={onChangeRatio("balance")}
          error={error?.balance}
          icon={"dollar"}
          iconSize={16}
          step={10}
          readonly={readonly}
        />
        <TextInput
          className={styles.inputRatio1}
          tooltip={`lot 0.01`}
          placeholder={`lot 0.01`}
          type="number"
          value={ratio?.lot || ""}
          onChange={onChangeRatio("lot")}
          error={error?.lot}
          step={0.01}
          readonly={readonly}
        />
        <Dropdown
          className={styles.dropdownRatio}
          classDropdownHead={styles.dropdownHead}
          value={EVolumeTypeWay[ratio.way]}
          setValue={onChangeRatio("way")}
          options={EVolumeTypeWay.keys()}
          readonly={readonly}
        />
      </>
    );
  }, // RATIO: 2, //	2000:0.01
  Multiply: ({ onChange, value, readonly }) => {
    const [error, setError] = React.useState();
    const onChangeFix = (e) => {
      const v =
        isNaN(e.target.value) ||
        e.target.value === null ||
        e.target.value === undefined
          ? 0
          : e.target.value;
      setError("");
      if (v >= 0) onChange(v);
      if (!(v > 0)) setError("Fix value must be a number");
    };
    return (
      <TextInput
        name={"MULTIPLY"}
        placeholder={`Multiply size example is 1.01`}
        type="number"
        value={value || 0}
        // icon={'payment'}
        onChange={onChangeFix}
        error={error}
        step={0.01}
        readonly={readonly}
      />
    );
  }, //  MULTIPLY: 3, //	number
  Risk: ({ onChange, value, readonly }) => {
    const [error, setError] = React.useState();
    const [form, setRisk] = React.useState({
      risk: 0,
      way: EVolumeTypeWay.Balance,
    });
    React.useEffect(() => {
      if (value && typeof value === "string") {
        const [risk, way] = value?.split(":");
        if (risk) {
          setRisk({
            risk,
            way:
              `${way}` === "1" || `${way}` === "2"
                ? way
                : EVolumeTypeWay.Balance,
          });
        }
      }
    }, [value]);
    const onChangeRisk = (key) => (e) => {
      let v = "";
      if (key === "way") {
        v = EVolumeTypeWay.get(e);
      } else {
        v =
          isNaN(e.target.value) ||
          e.target.value === null ||
          e.target.value === undefined
            ? 0
            : e.target.value;
      }
      setError({});
      const nr = {
        ...form,
        [key]: v,
      };
      setRisk(nr);
      if (nr.risk > 0 && nr.risk < 100) onChange(`${nr.risk}:${nr.way}`);
      if (nr.risk <= 0 || nr.risk >= 100)
        setError({ ...error, risk: "risk is not valid" });
    };
    return (
      <>
        <TextInput
          placeholder={`Risk %`}
          type="number"
          value={form?.risk}
          onChange={onChangeRisk("risk")}
          error={error?.risk}
          icon="percent"
          iconSize={16}
          className="ml-4"
          readonly={readonly}
          // step={10}
        />
        {/* <TextInput
          tooltip={`lot 0.01`}
          placeholder={`lot 0.01`}
          type="number"
          value={form?.lot}
          onChange={onChangeRisk("lot")}
          error={error?.lot}
          step={0.01}
        /> */}
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={EVolumeTypeWay[form.way]}
          setValue={onChangeRisk("way")}
          options={EVolumeTypeWay.keys()}
          readonly={readonly}
        />
      </>
    );
  }, //RISK: 4, //2 : 0.01
};
export const VolumeInput = ({ readonly, value, volume, onChange }) => {
  switch (volume) {
    case EVolumeType.Fix:
      return <Input.Fix {...{ readonly, onChange, value }} />;
    case EVolumeType.Fallow:
      return <Input.Fallow {...{ readonly, onChange, value }} />;
    case EVolumeType.Ratio:
      return <Input.Ratio {...{ readonly, onChange, value }} />;
    case EVolumeType.Multiply:
      return <Input.Multiply {...{ readonly, onChange, value }} />;
    case EVolumeType.Risk:
      return <Input.Risk {...{ readonly, onChange, value }} />;
    default:
      break;
  }
  return <></>;
};

export default TextInputRef;
