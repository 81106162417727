import { useMutation, useQuery } from "react-query";
import { URL } from "./url.enum";
import { getAuthHeader } from "./useAuth";
import { http } from "./http.config";
import { usePagesWithLoadeMore } from "./usePages";
export const useCreateTransaction = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.TRANSACTION + "/" + data?._id,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useChargeAccount = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.TRANSACTION + "/charge/" + data?.price,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useCheckChargeAccount = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.TRANSACTION + "/charge/check/" + data?.authority,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useChargeAccountByAdmin = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.TRANSACTION + "/admin/charge",
        data,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useCheckTransaction = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.TRANSACTION + "/check/" + data?.authority,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useListTransaction = () => {
  return usePagesWithLoadeMore(URL.TRANSACTION, true, 0, 15, {});
};
export const lastTransactionApi = async () => {
  try {
    const res = await http.get(`${URL.TRANSACTION}/last`, getAuthHeader());
    return res?.data;
  } catch (error) {
    return null;
  }
};
export const useDenyTransaction = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.post(
        URL.TRANSACTION + "/deny/" + data?.authority,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useExtendTransaction = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.post(
        URL.TRANSACTION + "/extend/" + data?.authority,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};