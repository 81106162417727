import { unLockedMaster } from "api";
import * as Types from "./types";

export const handleSignIn = (dispatch) => (data) => {
  return dispatch(Types.SignIn, data);
};
export const handleSignUp = (dispatch) => () => {
  return dispatch(Types.SignUp, true);
};
export const handleProfile = (dispatch) => (user) => {
  return dispatch(Types.Profile, user);
};
export const handleCanSuperAdmin = (state) => () => {
  return state?.menu === "super_admin";
};
export const handleCanAdmin = (state) => (can) => {
  if ((state?.menu === "admin" || state?.menu === "adminPart") && can) {
    if (state.user?.access && can in state.user.access) {
      return state.user.access[can];
    } else {
      return false;
    }
  }
  return state?.menu === "admin" || state?.menu === "adminPart";
};
export const handleLogout = (dispatch) => (history, master) => {
  if (master) {
    const data = unLockedMaster();
    dispatch(Types.SignIn, data);
    history.push("/masters");
    return;
  }
  return dispatch(Types.Logout, history);
};
