import * as Types from "./types";

export const Reducer = (state, action) => {
  switch (action?.type) {
    
    case Types.SignClient: {
      return {
        ...state,
        signClient:action.value,
      };
    }
    case Types.SetSession: {
      return {
        ...state,
        session:action.value,
      };
    }
    case Types.SetAccount: {
      return {
        ...state,
        account:action.value,
      };
    }
    case Types.Connect: {
      return {
        ...state,
        connect:action.value,
      };
    } 
    default:
      throw new Error(`User Unhandled action type: ${action.type}`);
  }
};
