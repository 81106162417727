import { Web3Modal } from "@web3modal/standalone";
import { SignClient } from "@walletconnect/sign-client";
import {
  DEFAULT_APP_METADATA,
  DEFAULT_PROJECT_ID,
  DEFAULT_RELAY_URL,
} from "./constants";
export const ProposalNamespace = {
  tron: {
    methods: ["tron_signTransaction", "tron_signMessage"],
    chains: ["tron:0x2b6653dc"],
    events: [],
  },
  // eip155: {
  //   methods: ["eth_sendTransaction", "personal_sign"],
  //   chains: ["eip155:1"],
  //   events: ["connect", "disconnect", "chainChanged", "accountsChanged"],
  // },
};
export const web3Modal = new Web3Modal({
  projectId: DEFAULT_PROJECT_ID,
  themeMode: "light",
  // standaloneChains: ["tron"],
  walletConnectVersion: 2,
  // supportedChains: [1],
  // providerOptions: {
  //   walletconnect: {
  //     package: WalletConnectSigner,
  //     options: {
  //       infuraId: DEFAULT_PROJECT_ID,
  //       supportedChains: [1],
  //       proposalNamespace: ProposalNamespace,
  //     },
  //   },
  // },
});

export async function onSignClient() {
  return await SignClient.init({
    logger: "debug",
    projectId: DEFAULT_PROJECT_ID,
    // walletConnectVersion: 2,
    relayUrl: DEFAULT_RELAY_URL,
    metadata: DEFAULT_APP_METADATA,
    // url: "https://my-dapp.com",
    // icons: ["https://my-dapp.com/logo.png"],
  });
}
