import React from "react";
import useDarkMode from "use-dark-mode";
import styles from "./Image.module.sass";
import Icon from "components/Icon";
import Balance from "components/Balance";
import { ChartBar, ChartDay, ChartPeople, ChartPi } from "components/Chart";
import { URL } from "api";

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt }) => {
  const darkMode = useDarkMode(false);

  return (
    <img
      className={className}
      srcSet={darkMode.value ? srcSetDark : srcSet}
      src={darkMode.value ? srcDark : src}
      alt={alt}
    />
  );
};

export default Image;
export const Logo = ({ className }) => (
  <Image
    className={className}
    srcDark={URL.CdnUrl + "/logo/logo-dark.svg"}
    src={URL.CdnUrl + "/logo/logo-light.svg"}
  />
);
export const NoImage = ({
  className,
  src = "/noimage.png",
  srcDark = "/noimage.png",
}) => <Image {...{ src, srcDark, className }} />;
export const Img = ({
  className,
  src = "/noimage.png",
  noimage = "/noimage.png",
  alt = "image",
}) => (
  <img
    alt={alt}
    src={src}
    className={className}
    onError={({ currentTarget }) => {
      if (currentTarget) {
        currentTarget.onerror = null;
        currentTarget.src = noimage;
      }
    }}
  />
);

export const ImgCircle = ({
  className,
  src = "/noimage.png",
  noimage = "/noimage.png",
  alt = "image",
  title,
}) => (
  <div className={styles.col}>
    <div className={styles.item}>
      <div className={styles.avatar}>
        <img
          alt={alt}
          src={src}
          className={className}
          onError={({ currentTarget }) => {
            if (currentTarget) {
              currentTarget.onerror = null;
              currentTarget.src = noimage;
            }
          }}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.user}>{title}</div>
      </div>
    </div>
  </div>
);
