import React, { useState } from "react";
import styles from "./List.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import { Loader } from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Filters from "../../../components/Filters";
import Row from "./Row";
import NewAdmin from "./New";
import TextInput from "../../../components/TextInput";
import { useUserDispatch, useUserState } from "../../../context";
import Checkbox from "components/Checkbox";
const intervals = ["15 in page", "25 in page", "50 in page"];
const List = ({
  className,
  isLoading,
  page,
  data,
  refetch,
  filters,
  role,
  changeFilter,
  changePage,
  changeCount,
}) => {
  const [count, setCount] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const action = useUserDispatch();
  const state = useUserState();
  const onChangeCount = (count) => {
    setCount(count);
    changeCount(count.replace(" in page", ""));
  };
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={"Users"}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Filters title="Client filter" className={styles.filters}>
              <>
                <Checkbox
                  onChange={(e) => changeFilter("status")(e.target.checked)}
                  content="Show delete user"
                />
                <br />
                <TextInput
                  className={styles.field}
                  type="text"
                  onChange={(e) => changeFilter("query")(e.target.value)}
                  placeholder="search name"
                />
                <br />
                <TextInput
                  type="text"
                  className={styles.field}
                  onChange={(e) => changeFilter("email")(e.target.value)}
                  placeholder="search email"
                />
              </>
            </Filters>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={count}
              setValue={onChangeCount}
              options={intervals}
              small
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row} id="sticky">
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Email</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Balance</div>
            <div className={styles.col}>Account</div>
            <div className={styles.col}>Create Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {data?.map((x, index) => (
            <Row
              expired={x.expiredAt}
              role={role}
              user={state?.user}
              refetch={refetch}
              item={x}
              key={index}
            />
          ))}
        </div>
        <div className={styles.foot}>
          <button
            type="button"
            onClick={() => changePage(page + 1)}
            className={cn("button-stroke button-small", styles.button)}
          >
            {isLoading && <Loader className={styles.loader} />}
            <span>Load more</span>
          </button>
        </div>
      </Card>
    </>
  );
};

export default List;
