/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useReducer } from "react";
import * as action from "./action";
import { Reducer } from "./reducer";
import { WalletStateContext, WalletDispatchContext } from "./context";
import { dispatch } from "../tools";

export const WalletProvider = ({ children }) => {
  const [state, setState] =useReducer(Reducer, {
    connect: false,
    account: null,
    session: null,
    signClient: null,
    pairing: null,
  });
  //TODO add in use walletconnect
  // useEffect(() => {
  //   if (action?.handleCreateClient) {
  //     action?.handleCreateClient(dispatch(setState))();
  //   }
  // }, [action?.handleCreateClient]);

  const actions = React.useMemo(
    () => ({
      onConnect: action.handleConnect(dispatch(setState), state),
      onDisconnect: action.handleDisconnect(dispatch(setState), state),
      onSendTransaction: action.handleSendTransaction(dispatch(setState), state),
    }),
    [setState, state]
  );
  return (
    <WalletStateContext.Provider value={useMemo(() => state, [state])}>
      <WalletDispatchContext.Provider value={actions}>
        {children}
      </WalletDispatchContext.Provider>
    </WalletStateContext.Provider>
  );
};
