import React, { useEffect, useState } from "react";
import styles from "./SlipPage.module.sass";
import cn from "classnames";
import Card from "components/Card";
import { useLoadingDispatch } from "context";
import { useGetSetting, useUpdateSetting } from "api";
import { Notify } from "components/Notify";
import { TextInput } from "components/TextInput";
import Checkbox from "components/Checkbox";
import Upload from "components/Upload";
const SlipPage = ({ className }) => {
  const { data, refetch } = useGetSetting("slippage");
  const [slipPage, setSlipPage] = useState(false);
  const loading = useLoadingDispatch();
  const _ = useUpdateSetting();
  const onUpdateSetting = async () => {
    loading.show();
    if (!(parseFloat(slipPage) > 0)) {
      loading.hide();
      return Notify.error("Sorry! slippage not valid");
    }
    const response = await _.mutateAsync({
      ...data,
      slipPage,
      name: "slippage",
    });
    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  useEffect(() => {
    if (data?.slipPage) setSlipPage(data?.slipPage);

    return () => {
      setSlipPage && setSlipPage(0);
    };
  }, [data]);
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Slippage"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => onUpdateSetting()}
            >
              Update
            </button>
          </>
        }
      >
        <TextInput
          value={slipPage || data?.slipPage}
          onChange={(e) => setSlipPage(e.target.value)}
          type="number"
          placeholder="Slippage transaction 0.01"
          step={0.01}
          icon="dollar"
        />
      </Card>
    </>
  );
};

export default SlipPage;
