/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./Row.module.sass";
import Icon from "../../../../components/Icon";
import { URL } from "../../../../api";
import classNames from "classnames";
import { starText } from "common";
import { Tooltip } from "recharts";
import Price from "components/Price";
import TooltipGlodal from "components/TooltipGlodal";

const Row = ({ item, refetch }) => {
  return (<>
    <div
      className={classNames(styles.row, {
        suspend: item?.status === -1,
      })}
    >
      <div className={styles.col}>
        <div className={styles.label}>Price</div>
        <div className={styles.col}>
          <div className={styles.post}>
            $ <Price>{item?.price}</Price>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>From Address</div>
        <div className={styles.col}>
          {item?.from ? (
            <div
              className={styles.post}
              data-tip={item?.from}
              data-place={"top"}
            >{`${item?.from?.substr(0, 2)}..${item?.from?.substr(
              item?.from?.length - 3,
              3
            )}`}</div>
          ) : (
            <div className={styles.post}>---</div>
          )}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>To Address</div>
        <div className={styles.col}>
          {item?.to ? (
            <div
              className={styles.post}
              data-tip={item?.to}
              data-place={"top"}
            >{`${item?.to?.substr(0, 2)}..${item?.to?.substr(
              item?.to?.length - 3,
              3
            )}`}</div>
          ) : (
            <div className={styles.post}>---</div>
          )}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Hash</div>
        <div className={styles.col}>
          {item?.hash ? (
            <div
              className={styles.post}
              data-tip={item?.hash}
              data-place={"top"}
            >{`${item?.hash?.substr(0, 2)}..${item?.hash?.substr(
              item?.hash?.length - 3,
              3
            )}`}</div>
          ) : (
            <div className={styles.post}>---</div>
          )}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Description</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.description}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
          {`${item?.createdAt}`.replace(/(T)|(Z)/gi, " ").substring(0, 16)}
          </div>
        </div>
      </div>
    </div>

<TooltipGlodal /></>
  );
};

export default Row;
