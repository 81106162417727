import React, { useEffect, useState } from "react";
import styles from "./EditSetting.module.sass";
import cn from "classnames";
import Card from "components/Card";
import TextEdit from "components/Textarea";
import { TextInput } from "components/TextInput";
import Icon from "components/Icon";
import { Notify } from "components/Notify";
import Select, { SelectType } from "components/Select";
import UploadVideo from "components/UploadVideo";
import { useLoadingDispatch } from "context";
import { useUploadVideoFile } from "api";

const EditSetting = ({
  title,
  item,
  className,
  onSave,
  onDownIndex,
  onUpIndex,
  onDel,
}) => {
  const [video, setVideo] = useState("");
  const [type, setType] = useState("all");
  const [subject, setSubject] = useState("");
  // const [category, setCategory] = useState("");

  const loading = useLoadingDispatch();
  const _v = useUploadVideoFile();
  useEffect(() => {
    if (item) {
      if (item?.subject) {
        setSubject(item.subject);
      }
      if (item?.video) {
        setVideo(item.video);
      }
      if (item?.type) {
        setType(item.type);
      }
    }
  }, [item]);
  const onUpdateVideo = async (file, _) => {
    loading.show();
    const response = await _v.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      setVideo(response);
    } else {
      setVideo("");
      return Notify.error(
        "Sorry, video size must be less than 15 MB and allowed formats are MP4"
      );
    }
  };
  const resetForm = () => {
    setSubject("");
    setVideo("");
    setType("all");
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={title}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            {onDel && (
              <button
                className={cn("button-red", styles.buttonDown)}
                onDoubleClick={onDel}
                onClick={() =>
                  Notify.warning("please DoubleClick for delete item")
                }
              >
                <Icon name="trash" />
              </button>
            )}
            <button
              className={cn("button-small", styles.button)}
              onClick={() => onSave({ subject, video, type }, resetForm)}
            >
              {item ? "Edit" : "Add"}
            </button>

            {onUpIndex && (
              <button
                className={cn("button-purple", styles.buttonDown)}
                onClick={onUpIndex}
              >
                <Icon name="arrow-top" />
              </button>
            )}
            {onDownIndex && (
              <button
                className={cn("button-purple", styles.buttonDown)}
                onClick={onDownIndex}
              >
                <Icon name="arrow-bottom" />
              </button>
            )}
          </>
        }
      >
        <div className={styles.table}>
          <SelectType
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={type}
            onChange={(v) => setType(v)}
          />
          <br />
          <TextInput
            value={subject}
            placeholder="Subject"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            icon={"message"}
          />
          <br />
          <UploadVideo
            value={video}
            onDelete={() => {
              setVideo(null);
            }}
            onSelectFile={onUpdateVideo}
          />
        </div>
      </Card>
    </>
  );
};

export default EditSetting;
