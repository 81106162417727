import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Modal from "components/Modal";
import EditAdmin from "../../Edit";
import DelAdmin from "../../Del";
import Tooltip from "components/Tooltip";
import TooltipGlodal from "components/TooltipGlodal";
import { useDownAdminPlanApi, useUpAdminPlanApi } from "api";
import { useLoadingDispatch, useUserState } from "context";
import { Notify } from "components/Notify";

const Control = ({ className, refetch, item, index, count }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const loading = useLoadingDispatch();
  const user = useUserState();
  const _onUp = useUpAdminPlanApi();
  const _onDown = useDownAdminPlanApi();
  const onUp = async () => {
    loading.show();
    const response = await _onUp.mutateAsync(item);
    loading.hide();
    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
  };
  const onDown = async () => {
    loading.show();
    const response = await _onDown.mutateAsync(item);
    loading.hide();
    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
  };
  //call api
  const actions = [
    {
      icon: "edit",
      tooltip: "Edit plan",
      action: () => setVisibleModal("edit"),
    },
    {
      icon: "trash",
      tooltip: "Delete plan",
      action: () => setDelModal("delete"),
    },
  ];
  if (user && !user?.isSuperAdmin) {
    if (!(index <= 0)) {
      actions.push({
        icon: "arrow-top",
        tooltip: "Changes the priority to the top",
        action: () => onUp(),
      });
    }
    if (!(index >= count - 1)) {
      actions.push({
        icon: "arrow-bottom",
        tooltip: "Changes the priority to the down",
        action: () => onDown(),
      });
    }
  }
  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Tooltip
              className={styles.tooltip}
              title={x.tooltip}
              icon={x.icon}
              place={"top"}
            />
          </button>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <>
          {item && (
            <EditAdmin
              item={item}
              onClose={() => setVisibleModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <Modal visible={delModal} onClose={() => setDelModal(false)}>
        <>
          {item && (
            <DelAdmin
              item={item}
              onClose={() => setDelModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>

      <TooltipGlodal />
    </>
  );
};

export default Control;
