/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./Row.module.sass";
import Icon from "../../../../components/Icon";
import { EPlanType, EVolumeType, EWalletType, URL } from "../../../../api";
import Control from "./Control";
import classNames from "classnames";

const Row = ({ item, refetch }) => {
  const getValuePath = (value) => {
    if (value) {
      if (value.search("/assets") >= 0) {
        return `${URL.BaseUrl}${value}`;
      }
      return `${URL.CdnUrl}/${value}`;
    } 
    return;
  };
  return (
    <div className={styles.row}>
     
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            {item?.file ? (
              <img src={getValuePath(item?.file)} alt="Image" />
            ) : (
              <img src={`noimage.png`} alt="Image" />
            )}
            
          </div>
          <div className={styles.details}>
            <div className={styles.post}>{item?.subject}</div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Type</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.type}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Button</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.btn}</div>
        </div>
      </div>
  
      
      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control refetch={refetch} className={styles.control} item={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
