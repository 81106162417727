import React, { useEffect, useState } from "react";
import styles from "./Company.module.sass";
import cn from "classnames";
import Card from "components/Card";
import { useLoadingDispatch } from "context";
import {
  useGetSetting,
  useUpdateSetting,
  useUploadFile,
  useUploadLogo,
} from "api";
import { Notify } from "components/Notify";
import { TextInput } from "components/TextInput";
import Checkbox from "components/Checkbox";
import Upload from "components/Upload";
const Company = ({ className }) => {
  const { data: dataCompany, refetch: refetchCompany } =
    useGetSetting("companyName");
  const [companyName, setCompanyName] = useState("");
  const [light, setLight] = useState("");
  const [dark, setDark] = useState("");
  const loading = useLoadingDispatch();
  const _ = useUpdateSetting();
  const updateFile = useUploadLogo();
  useEffect(() => {
    if (dataCompany) {
      setCompanyName(dataCompany?.companyName);
      setLight(dataCompany?.logoLight);
      setDark(dataCompany?.logoDark);
    }
  }, [dataCompany]);
  const onUploadLogo = async (file, path) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file, path });
    loading.hide();
    if (response) {
      if (path === "light") setLight(response);
      else setDark(response);
      // Notify.success("Upload file successfull!");
    } else {
      if (path === "light") setLight("");
      else setDark("");
      return Notify.error(
        "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
      );
    }
  };

  const onUpdateCompany = async () => {
    loading.show();

    const response = await _.mutateAsync({
      ...dataCompany,
      companyName,
      logoLight: light,
      logoDark: dark,
      name: "companyName",
    });
    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetchCompany && (await refetchCompany());
    }
    loading.hide();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Company"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => onUpdateCompany()}
            >
              Update
            </button>
          </>
        }
      >
        <div className={styles.mb}>Company Name</div>

        <TextInput
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          type="text"
          placeholder="Company Name"
          // step={0.01}
          icon="info"
        />
        <br />
        <div className={styles.mb}>Dark logo</div>
        <Upload
          value={dark}
          onDelete={() => {
            //form.setValue("image", null);
          }}
          valid={["IMAGE/SVG", "IMAGE/SVG+XML", "IMAGE/SVG-XML", "IMAGE/XML"]}
          formats={".SVG | .svg"}
          onSelectFile={(f) => onUploadLogo(f, "dark")}
          size={10}
        />
        <br />
        <div className={styles.mb}>Light logo</div>
        <Upload
          value={light}
          onDelete={() => {
            //form.setValue("image", null);
          }}
          valid={["IMAGE/SVG", "IMAGE/SVG+XML", "IMAGE/SVG-XML", "IMAGE/XML"]}
          formats={".SVG | .svg"}
          size={10}
          onSelectFile={(f) => onUploadLogo(f, "light")}
        />
      </Card>
    </>
  );
};

export default Company;
