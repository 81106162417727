import React, { useEffect, useState } from "react";
import styles from "./UploadVideo.module.sass";
import { URL, useDeleteFile } from "../../api";
import TooltipGlodal from "components/TooltipGlodal";
import { Notify } from "components/Notify";
import Icon from "components/Icon";

const UploadVideo = ({
  onSelectFile,
  onDelete,
  error,
  value,
  isDisabled,
  deleteFile = true,
}) => {
  const _ = useDeleteFile();
  const imageUrl = URL.CdnUrl;
  const getValuePath = () => {
    if (value) {
      return `${imageUrl}${value}`;
    }
    resetFile();
    return;
  };
  const [getSelectStatus, setSelectStatus] = useState(false);
  const borderRadius = "20px";
  const height = "180px";
  const itemSelect = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.click();
    input.onchange = (e) => {
      var file = e.target.files[0];
      var type = file.type?.toLocaleUpperCase();
      if (
        file.size > 100 * 1024 * 1024 ||
        !["VIDEO/MP4", "VIDEO/MKV"].includes(type)
      ) {
        Notify.error(
          "Sorry, Video size must be less than 100 MB and allowed formats are mp4,mkv"
        );
        return;
      }
      var reader = new FileReader();
      console.log("file", file);
      console.log("file", file?.size);
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        var base64 = readerEvent.target.result;
        setSelectStatus(true);
        onSelectFile(file, base64);
      };
    };
  };

  const resetFile = async () => {
    deleteFile && value && await _.mutateAsync({ file: value });
    setSelectStatus(false);
  };
  useEffect(() => {
    if (value) setSelectStatus(true);
    return () => {
      setSelectStatus(false);
    };
  }, [value]);
  const Img = ({ name = "video", ...props }) => (
    <span
      data-tip={`upload video limit size: 15MB format: .mp4`}
      data-place={"top"}
      {...props}
    >
      <Icon name={name} size="28" />
    </span>
  );
  const getImage = () => {
    if (getSelectStatus === true || value) {
      if (!getValuePath()) {
        return <Img style={{ marginTop: "80px", cursor: "pointer" }} />;
      }
      return (
        <>

          <video width="100%" height={height} controls >
            <source src={getValuePath()} />
            Your browser does not support the video tag.
          </video>

          <Img
            name="trash"
            className={styles.deleteIconRight}
            onClick={async () => {
              await resetFile();
              itemSelect();
              onDelete && onDelete();
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <Img style={{ marginTop: "80px", cursor: "pointer" }} />
        </>
      );
    }
  };

  return (
    <div
      className={styles.uploadpage}
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height,
        }}
      >
        <div
          onClick={() => {
            if (!getSelectStatus && !isDisabled) itemSelect();
          }}
          style={{ borderRadius: borderRadius, width: "100%", height }}
          className={styles.uploader}
        >
          {getImage()}
        </div>
      </div>
      <div span={24} align="center">
        {error !== "" && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default UploadVideo;
