import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { URL } from "./url.enum";
import { usePagesWithLoadeMore } from "./usePages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getAuthHeader } from "./useAuth";
import { http } from "./http.config";
import { EVolumeType } from "./volume.enum";
import { EPlanType } from "./plan.enum";

const useUpdateProfile = () => {
  return useMutation(async (data) => {
    try {
      const update = {};
      if (data?.email) {
        update["email"] = data.email;
      }
      if (data?.name) {
        update["name"] = data.name;
      }
      if (data?.password) {
        update["password"] = data.password;
      }

      const res = await http.put(URL.CLIENT_PROFILE, update, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};

export const getProfileClientApi = async () => {
  try {
    const res = await http.get(URL.CLIENT_PROFILE, getAuthHeader());
    return res?.data;
  } catch (error) {
    if (error?.response?.data) return error.response.data;
    return {
      error: true,
      message: error?.message,
    };
  }
};
export const useClientProfile = () => {
  return useQuery(["client.profile"], getProfileClientApi);
};
export const useUpdateClientProfileForm = (defaultValues) => {
  const schema = yup
    .object({
      email: yup.string().email().required(),
      name: yup.string().max(150).optional(),
      password: yup.string().max(16).optional(),
    })
    .required();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const _ = useUpdateProfile();
  return useMemo(() => {
    return [
      {
        email: { ...register("email") },
        name: { ...register("name") },
        password: { ...register("password") },
        register,
        control,
        errors,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useListClient = () => {
  return usePagesWithLoadeMore(URL.CLIENT, true, 0, 15, {});
};
///client/{_id}
export const useDeleteUser = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.delete(
        URL.CLIENT + "/" + data?._id,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};