import React, { useState } from "react";
import styles from "./List.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import { Loader } from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Filters from "../../../components/Filters";
import Row from "./Row";
import NewAdmin from "./New";
import TextInput from "../../../components/TextInput";
import {
  IsAdmin,
  NotSuperAdmin,
  useUserDispatch,
  useUserState,
} from "../../../context";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "components/Select";
import { useListAdminPlanAll } from "api";
import Icon from "components/Icon";
const intervals = ["15 in page", "25 in page", "50 in page"];
const List = ({
  className,
  isLoading,
  page,
  data,
  refetch,
  filters,
  role,
  changeFilter,
  changePage,
  changeCount,
  isFilter = true,
  owner,
}) => {
  const [count, setCount] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const action = useUserDispatch();
  const state = useUserState();
  const onChangeCount = (count) => {
    setCount(count);
    changeCount(count.replace(" in page", ""));
  };
  const history = useHistory();
  const title = owner ? (
    <>
      <span
        className={cn("link", styles.linkTitle)}
        onClick={() => history.push("/users")}
      >
        <Icon name={"arrow-left"} size={24} />
        {`List accounts ${owner?.owner?.name}`}
      </span>
    </>
  ) : (
    "Accounts"
  );
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={isFilter ? title : ""}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          isFilter ? (
            <>
              {/* {JSON.stringify(listAdminPlan?.data)} */}
              <Filters title="Client filter" className={styles.filters}>
                <>
                  <IsAdmin>
                    <NotSuperAdmin>
                      <FilterPlan
                        filters={filters}
                        changeFilter={changeFilter}
                      />
                    </NotSuperAdmin>
                  </IsAdmin>
                  {/* <TextInput
                  className={styles.field}
                  type="text"
                  onChange={(e) => changeFilter("query")(e.target.value)}
                  placeholder="search name"
                /> */}
                  <br />
                  <TextInput
                    type="number"
                    className={styles.field}
                    onChange={(e) =>
                      changeFilter("accountNumber")(e.target.value)
                    }
                    placeholder="search account"
                  />
                </>
              </Filters>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={count}
                setValue={onChangeCount}
                options={intervals}
                small
              />
              {action.canAdmin("clientAdd") && (
                <button
                  className={cn("button-small", styles.button)}
                  onClick={() => setVisibleModal(true)}
                >
                  New Client
                </button>
              )}
              {state?.isUser && (
                <>
                  {data && data.length > 0 && (
                    // data.find((d) => d.accountNumber > 0) &&
                    <Link
                      target="blink"
                      to="/download"
                      className={cn("button-small", styles.button)}
                    >
                      Download Mql File
                    </Link>
                  )}
                </>
              )}
            </>
          ) : (
            <></>
          )
        }
      >
        <div className={styles.table}>
          <div className={styles.row} id="sticky">
            <div className={styles.col} style={{ textAlign: "center" }}>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Plan Name
            </div>
            <div className={styles.col}>Client Name</div>
            <div className={styles.col}>Client Email</div>
            
            <div className={styles.col}>Account</div>
            <div className={styles.col}>Demo</div>
            <div className={styles.col}>Plan</div>
            <div className={styles.col}>Volume</div>
            {/* <div className={styles.col}>Volume value</div> */}
            {/* <div className={styles.col}>Trader share</div>
            <div className={styles.col}>Admin share</div>
            <div className={styles.col}>Total revenue $</div> */}
            <div className={styles.col}>Remain</div>
            <div className={styles.col}>Crate Date</div>
            <div className={styles.col}>Expired Date</div>
            <div className={styles.col}>Auto Renewal</div>
            <div className={styles.col}>Action</div>
          </div>
          {data?.map((x, index) => (
            <Row
              expired={x.expiredAt}
              role={role}
              user={state?.user}
              refetch={refetch}
              item={x}
              key={index}
            />
          ))}
        </div>
        {isFilter && (
          <div className={styles.foot}>
            <button
              type="button"
              onClick={() => changePage(page + 1)}
              className={cn("button-stroke button-small", styles.button)}
            >
              {isLoading && <Loader className={styles.loader} />}
              <span>Load more</span>
            </button>
          </div>
        )}
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewAdmin onClose={() => setVisibleModal(false)} refetch={refetch} />
      </Modal>
    </>
  );
};
const FilterPlan = ({ changeFilter, filters }) => {
  const listAdminPlan = useListAdminPlanAll();
  return (
    <Select
      option="name"
      id="_id"
      className={styles.dropdown}
      classDropdownHead={styles.dropdownHead}
      value={filters?.lastPlan || ""}
      optionFirst="Choice plan"
      setValue={(v) => changeFilter("lastPlan")(v)}
      options={listAdminPlan?.data || []}
    />
  );
};
export default List;
