import { useMutation } from "react-query";
import { URL } from "./url.enum";
import { usePagesWithLoadeMore } from "./usePages";
import { http } from "./http.config";
import { getAuthHeader } from "./useAuth";
export function useCreateEmail() {
  return useMutation(async (data) => {
    try {
      const res = await http.post(`${URL.EMAIL}`, data, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
}

export const useListEmail = () => {
  return usePagesWithLoadeMore(URL.EMAIL, true, 0, 15, {});
};