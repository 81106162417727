import React from "react";
import styles from "./Account.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import List from "../../../Account/List";
import { useListAccountClient } from "api";
import { LoaderMint } from "components/Loader";

const AccountShow = ({ onClose, item }) => {
  const data = useListAccountClient("admin", 1000, { owner: item?._id });

  return (
    <form>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>Account</div>
        {data?.isLoading ? (
          <div className={styles.body}>
            <LoaderMint />
          </div>
        ) : (
          <>
            {data?.data && data?.data?.length > 0 && (
              <List {...data} className={styles.body} isFilter={false} />
            )}
            {data?.data && data?.data?.length <= 0 && (
              <div className={styles.body}>
                No account has been registered for this user
              </div>
            )}
          </>
        )}

        <div className={styles.foot}>
          <button
            type="button"
            onClick={onClose}
            className={cn("button", styles.button)}
          >
            <span className="white">Close</span>
            <Icon name="close" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default AccountShow;
