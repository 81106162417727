import React, { useState } from "react";
import styles from "./New.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import { TextInput } from "components/TextInput";
import { Controller } from "react-hook-form";
import Textarea from "components/Textarea";
import Dropdown from "components/Dropdown";
import { Department, Priority } from "enum";
import { useForm } from "common";
import { useCreateTicket } from "api/useTicket";
import { useUploadFile } from "api";
import Upload from "components/Upload";
const New = ({ refetch, onClose }) => {
  const loading = useLoadingDispatch();
  const [form, onForm] = useForm();
  const _ = useCreateTicket();
  const updateFile = useUploadFile();
  const onSubmit = async () => {
    if (!form?.subject) {
      return Notify.error("subject is reqierd!");
    }
    const data = {};
    if (form.subject) {
      data["subject"] = form.subject;
    }
    if (form?.message) {
      data["message"] = form.message;
    }
    if (form?.file) {
      data["file"] = form.file;
    }
    if (form?.department) {
      data["department"] = Department[form.department];
    } else {
      data["department"] = Department.General;
    }
    if (form?.priority) {
      data["priority"] = Priority[form.priority];
    } else {
      data["priority"] = Priority.Normal;
    }
    loading.show();
    const response = await _.mutateAsync(data);
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };
  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      onForm("file")(response);
    } else {
      onForm("file")("");
      return Notify.error(
        "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
      );
    }
  };
  return (
    <>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>New Ticket</div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Subject"
            icon="ticket"
            type="text"
            value={form?.subject || ""}
            onChange={onForm("subject")}
          />
        </div>
        <div className={styles.field}>
          <Dropdown
            placeholder="Department"
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            {...form?.register("department", Department[1])}
            options={Department.keys()}
            setValue={onForm("department")}
          />
        </div>
        <div className={styles.field}>
          <Dropdown
            placeholder="Priority"
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            {...form?.register("priority", Priority[1])}
            options={Priority.keys()}
            setValue={onForm("priority")}
          />
        </div>
        <div className={styles.field}>
          <Textarea
            name="message"
            // className={styles.textarea}
            placeholder={`What you would like to share?
What is your master name?
What is your account number?
`}
            {...form.register("message")}
            onChange={onForm("message")}
          />
        </div>
        <div className={styles.field}>
          <Upload
            value={form?.file}
            onDelete={() => {
              onForm("file", null);
            }}
            onSelectFile={onUpdateFile}
          />
        </div>
        <div className={styles.foot}>
          <button
            onClick={onSubmit}
            type="button"
            className={cn("button", styles.button)}
          >
            <span className="white">Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default New;
