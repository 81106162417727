import React, { useEffect, useState } from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import {
  useLoadingDispatch,
  useTransactionDispatch,
  useUserDispatch,
  useUserState,
} from "../../../../context";
import { Notify } from "../../../../components/Notify";
import {
  EPlanType,
  parseError,
  useChargeAccount,
  useEditAccountWithAdminForm,
  useExtensionPlanClient,
} from "../../../../api";
import TextInput from "../../../../components/TextInput";
import { Controller } from "react-hook-form";
import { Plan } from "../../../../components/Plan";
import { Volume } from "../../../../components/Volume";
import Select from "../../../../components/Select";
import Checkbox from "components/Checkbox";

const Edit = ({ refetch, role, type, onClose, item }) => {
  const loading = useLoadingDispatch();
  const user = useUserDispatch();
  const stats = useUserState();
  const [testVerstion, setTestVerstion] = useState(item?.testVerstion || false);
  const [accountNumber, setAccountNumber] = useState(0);
  const [form, onForm, _] = useEditAccountWithAdminForm(item);
  const exten = useExtensionPlanClient();
  const { lastTransaction, setTrxModal } = useTransactionDispatch();
  const _tr = useChargeAccount();
  useEffect(() => {
    if (item && "testVerstion" in item) setTestVerstion(item?.testVerstion);
    if (item && "accountNumber" in item)
      setAccountNumber(parseInt(item?.accountNumber));
  }, [item]);
  const onSubmit = async (data) => {
    loading.show();

    const response = await _.mutateAsync({
      ...data,
      role,
      testVerstion,
      _id: item?._id,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      Notify.success("Edit successfull!");
      onClose && onClose();
      return;
    }
  };
  const onCheckbox = async (e) => {
    const checked = e.target.checked;
    setTestVerstion(checked);
  };
  const extensionBalance = async (e) => {
    loading.show();
    const res = await exten.mutateAsync({ _id: item?._id });

    if (res?.error) {
      loading.hide();
      Notify.error(res.message || "Sorry, network error!");
    } else {
      refetch && refetch();
      await user.updateClientBalance();
      loading.hide();
      onClose && onClose();
      Notify.success("extened successfull!");
    }
  };
  const onSelectItem = async (price) => {
    loading.show();
    const response = await _tr.mutateAsync({
      price: price,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(parseError(response));
    } else {
      Notify.success("create transaction successfull!");
      lastTransaction && (await lastTransaction());
      await setTrxModal(true);
      onClose();
      return;
    }
  };
  if (role)
    return (
      <form>
        <div className={styles.post}>
          <div className={cn("title-green", styles.title)}>
            {type === "edit" ? "Edit Client" : "Extened Plan"}{" "}
          </div>
          <div className={styles.field}>
            {type === "edit" ? (
              <TextInput
                className={styles.field}
                placeholder="Account number"
                icon="ticket"
                type="number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            ) : (
              <>
                Do you renew this {EPlanType[item?.plan?.type]}(
                {item?.lastPlan.plan?.value || "0"}) plan for $
                {item?.lastPlan.price}?
              </>
            )}
          </div>

          <div className={styles.foot}>
            {type === "edit" ? (
              <button
                type="button"
                onClick={() => onSubmit({ accountNumber })}
                className={cn("button", styles.button)}
              >
                <span className="white">Save</span>
                <Icon name="arrow-right" size="24" />
              </button>
            ) : (
              <>
                <button
                  type="button"
                  onClick={onClose}
                  className={cn("button", "button-red", "mr-4", styles.button)}
                >
                  <span className="white">No</span>
                </button>
                {stats.user?.balance &&
                  stats.user?.balance >= item?.lastPlan.price ? (
                  <button
                    type="button"
                    onClick={() => extensionBalance()}
                    className={cn("button", styles.button)}
                  >
                    <span className="white">Yes</span>
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() =>
                        onSelectItem(item?.lastPlan.price - stats.user?.balance)
                      }
                      className={cn("button", styles.button)}
                    >
                      <span className="white">
                        Charge wallet $
                        {item?.lastPlan.price - stats.user?.balance}
                      </span>
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </form>
    );
  return (
    <form onSubmit={onForm(onSubmit)}>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>Edit Client</div>

        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Account number"
            icon="ticket"
            type="number"
            error={form.errors.accountNumber?.message}
            {...form?.register("accountNumber")}
          />
        </div>
        {item?.lastPlan ? <></> : <>
          <div className={styles.field}>
            <TextInput
              className={styles.field}
              placeholder="Client name"
              icon="info-stroke"
              error={form.errors.name?.message}
              {...form.register("name")}
            />
          </div>
          <div className={styles.field}>
            <TextInput
              className={styles.field}
              placeholder="Client email or phone"
              icon="share"
              error={form.errors.contact?.message}
              {...form.register("contact")}
            />
          </div></>}
        <div className={styles.field}>
          <Checkbox
            value={testVerstion}
            onChange={onCheckbox}
            content="Test Verstion"
          />
        </div>
        <div className={styles.field}>
          <Controller
            render={({ field }) => (
              <Select
                option="name"
                id="id"
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={field?.value}
                setValue={(v) => field.onChange(v)}
                options={[
                  {
                    id: 0,
                    name: "Pending",
                  },
                  {
                    id: 1,
                    name: "Paid",
                  },
                  {
                    id: 2,
                    name: "Verify",
                  },
                  {
                    id: 3,
                    name: "Active",
                  },
                  {
                    id: 4,
                    name: "Disable",
                  },
                ]}
              />
            )}
            control={form.control}
            name="status"
          // defaultValue={0}
          />
        </div>
        <Controller
          render={({ field }) => <Plan {...field} error={form?.errors?.plan} readonly />}
          control={form.control}
          name="plan"
        // defaultValue={0}
        />
        <Controller
          render={({ field }) => (
            <Volume {...field} error={form?.errors?.volume} />
          )}
          control={form.control}
          name="volume"
        // defaultValue={0}
        />

        <div className={styles.foot}>
          <button className={cn("button", styles.button)}>
            <span className="white">Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Edit;
