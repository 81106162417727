import { Notify } from "components/Notify";
import * as Types from "./types";
import { lastTransactionApi } from "api";

export const createTransaction = (dispatch, state) => (data) => {
  dispatch(Types.CreateTransaction, data);
};
export const lastTransaction = (dispatch) => async () => {
  const trx = await lastTransactionApi();
  if (!trx?.error && trx?.data && trx.data?._id) {
    dispatch(Types.CreateTransaction, trx.data);
  }
};
export const setTrxModal = (dispatch) => async (s) => {
  dispatch(Types.MODAL, s);
};
export const setPaid = (dispatch) => async (s) => {
  dispatch(Types.PAID, s);
};
