/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./Row.module.sass";
import { TransactionStatus } from "enum";
import { IsAdmin, IsSuperAdmin } from "context";
import Control from "./Control";
import Status from "./Status";
import Price from "components/Price";

const Row = ({ item, refetch }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.label}>Id</div>
        <div className={styles.col}>
          <div className={styles.post}>{`${item?.authority}`}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Status</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Status value={item?.status} />
          </div>
        </div>
      </div>
      <IsAdmin>
        <>
          <div className={styles.col}>
            <div className={styles.label}>Client</div>
            <div className={styles.col}>
              <div className={styles.post}>{item?.client?.name || "---"}</div>
            </div>
          </div>
          <IsSuperAdmin>
            <div className={styles.col}>
              <div className={styles.label}>Admin</div>
              <div className={styles.col}>
                <div className={styles.post}>{item?.admin?.name || "---"}</div>
              </div>
            </div>
          </IsSuperAdmin>
        </>
      </IsAdmin>

      <div className={styles.col}>
        <div className={styles.label}>Hash</div>
        <div className={styles.col}>
          {item?.details?.hash ? (
            <div
              className={styles.post}
              data-tip={item?.details?.hash}
              data-place={"top"}
            >{`${item?.details?.hash?.substr(
              0,
              2
            )}..${item?.details?.hash?.substr(
              item?.details?.hash?.length - 3,
              3
            )}`}</div>
          ) : (
            <div className={styles.post}>---</div>
          )}
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>From Address</div>
        <div className={styles.col}>
          {item?.details?.from ? (
            <div
              className={styles.post}
              data-tip={item?.details?.from}
              data-place={"top"}
            >{`${item?.details?.from?.substr(
              0,
              2
            )}...${item?.details?.from?.substr(
              item?.details?.from?.length - 3,
              3
            )}`}</div>
          ) : (
            <div className={styles.post}>---</div>
          )}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>To Address</div>
        <div className={styles.col}>
          {item?.details?.to ? (
            <div
              className={styles.post}
              data-tip={item?.details?.to}
              data-place={"top"}
            >{`${item?.details?.to?.substr(0, 2)}..${item?.details?.to?.substr(
              item?.details?.to?.length - 3,
              3
            )}`}</div>
          ) : (
            <div className={styles.post}>---</div>
          )}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Amount</div>
        <div className={styles.col}>
          $ <Price>{item.amount}</Price>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Paid</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.paid ? (
              <span className="status text-green">
                Paid
                {item?.details?.amount && (
                  <>
                  ($ <Price>{item?.details?.amount}</Price>)
                  </>
                )}
              </span>
            ) : (
              <span className="status text-red">noPaid</span>
            )}
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Create Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {`${item?.createdAt}`.replace(/(T)|(Z)/gi, " ").substring(0, 16)}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control item={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
