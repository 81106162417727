import React from "react";
import styles from "./Del.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import { useDeleteClient } from "api";

const Del = ({ refetch, onClose, item }) => {
  const loading = useLoadingDispatch();
  const _ = useDeleteClient();

  const onSubmit = async () => {
    loading.show();
    const response = await _.mutateAsync({ _id: item?._id });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Update successfull!");
      return;
    }
  };

  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>Delete Master</div>

      <div className={styles.field}>
        Do you want delete {item?.name} master?
      </div>

      <div className={styles.foot}>
        <button onClick={onSubmit} className={cn("button", styles.button)}>
          <span>Yes</span>
          <Icon name="trash" size="24" />
        </button>
        <button onClick={onClose} className={cn("button", styles.button)}>
          <span>No</span>
          <Icon name="close" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Del;
