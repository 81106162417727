/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useReducer } from "react";
import * as action from "./action";
import { Reducer } from "./reducer";
import { UserStateContext, UserDispatchContext } from "./context";
import { dispatch } from "../tools";
import { getAuth, getProfileClientApi } from "../../api";

export const UserProvider = ({ children }) => {
  const [state, setState] = useReducer(Reducer, {
    auth: false,
    role: null,
    user: null,
    isAdmin: false,
    isUser: false,
    isGuest: true,
  });
  useEffect(() => {
    const auth = getAuth();
    if (auth) {
      if (auth?.client) {
        clientProfile(auth);
      } else {
        setState({
          type: "SignIn",
          value: auth,
        });
      }
    }
  }, [getAuth]);
  const clientProfile = async (_auth) => {
    let auth;
    if (_auth) {
      auth = _auth;
    } else {
      auth = getAuth();
    }
    const profile = await getProfileClientApi();
    setState({
      type: "SignIn",
      value: { ...auth, client: profile?.data },
    });
  };
  const actions = React.useMemo(
    () => ({
      onSignIn: action.handleSignIn(dispatch(setState)),
      onSignUp: action.handleSignUp(dispatch(setState)),
      onProfile: action.handleProfile(dispatch(setState)),
      onLogout: action.handleLogout(dispatch(setState)),
      canAdmin: action.handleCanAdmin(state),
      canSuperAdmin: action.handleCanSuperAdmin(state),
      updateClientBalance: clientProfile,
    }),
    [setState, state]
  );
  return (
    <UserStateContext.Provider value={useMemo(() => state, [state])}>
      <UserDispatchContext.Provider value={actions}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};
