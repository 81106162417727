import React, { useCallback, useEffect } from "react";
import styles from "./Wallets.module.sass";
import cn from "classnames";

import {
  parseError,
  useCheckTransaction,
  useCreateAccount,
  useCreateTransaction,
} from "api";
import {
  useLoadingDispatch,
  useTransactionDispatch,
  useTransactionState,
} from "context";
import Icon from "components/Icon";
import { TextInput } from "components/TextInput";
import { Notify } from "components/Notify";
import { QRCodeSVG } from "qrcode.react";
import { Timer } from "components/Timer";

export const WalletList = ({ plan, onPiad, onClose, onBackBuyNow }) => {
  const [wallet, setWallet] = React.useState();
  const _tr = useCreateTransaction();
  const _check = useCheckTransaction();
  const loading = useLoadingDispatch();
  const { trxModal } = useTransactionState();
  const { setTrxModal, lastTransaction } = useTransactionDispatch();
  const onSelectItem = async () => {
    loading.show();
    const response = await _tr.mutateAsync({
      _id: plan?._id,
    });

    if (response?.error) {
      loading.hide();
      return Notify.error(parseError(response));
    } else {
      Notify.success("create transaction successfull!");
      onClose();
      await lastTransaction();
      setTrxModal(true);
      loading.hide();
      return;
    }
  };
  const onCheck = async () => {
    const response = await _check.mutateAsync({
      authority: wallet?.authority,
    });
    if (!response?.error) {
      Notify.success("create transaction successfull!");
      onPiad(response.data);
      return;
    }
  };

  return (
    <>
      <div className={styles.post}>
        {wallet ? (
          <>
            <Wallet
              onClose={onClose}
              onCheck={onCheck}
              item={wallet}
              plan={plan}
            />
          </>
        ) : (
          <Items {...{ onSelectItem }} />
        )}

        <div className={styles.foot}>
          <button
            onClick={onBackBuyNow}
            className={cn("button", styles.button)}
          >
            <Icon name="arrow-left" size="24" />
            <span className="white">Back</span>
          </button>
        </div>
      </div>
    </>
  );
};
function Items({ onSelectItem }) {
  return (
    <>
      {" "}
      <div className={cn(styles.card)}>
        <div className={styles.item_center}>
          <div className={styles.title}>PAY WITH</div>
        </div>
        <Item
          wallet={{ name: "Tether(trc20)" }}
          key={0}
          onSelectItem={onSelectItem}
        />
      </div>
    </>
  );
}
let timer = null;
function Wallet({ item, plan, onCheck }) {
  const [time, setTime] = React.useState(100);
  const [expired, setExpired] = React.useState(30 * 60 * 1000);
  const addExpired = useCallback(() => {
    console.log("time", time);
    const ex = (parseInt(time) * 30) / 100 + 5;
    console.log("time", ex);
    setExpired(ex * 60 * 1000);
  });
  useEffect(() => {
    if (onCheck) {
      timer = setInterval(onCheck, 5000);
    }

    return () => {
      timer && clearInterval(timer);
    };
  }, [onCheck]);

  return (
    <>
      {" "}
      <div className={cn(styles.card)}>
        <div className={styles.info}>
          <Icon name="info" size="24" />
          <span>
            Only USDT (TRC-20 token) can be sent to this TRON address or your
            payment will be lost.
          </span>
        </div>
      </div>
      <div className={cn(styles.card)}>
        <div className={styles.left}>
          <div className={styles.top}>
            <div className={styles.preview}>
              <QRCodeSVG value={item?.address} />
            </div>
            <div>
              <div className={styles.win}> Send {plan.price} USDT</div>

              <div className={styles.type}>
                <TextInput
                  copy
                  className={styles.field}
                  name="account"
                  type="text"
                  placeholder="Your account address"
                  required
                  value={item?.address}
                  icon={{ name: "wallet", viewBox: "0 0 24 24", size: 24 }}
                  // onChange={(e) => setAccount(e.target.value)}
                />
              </div>
              <div className={styles.await}>
                <div className={styles.text}>
                  <span>Awaiting payment after &nbsp;</span>{" "}
                  <span className={styles.time}>
                    {" "}
                    <Timer
                      expired={expired}
                      onEndTime={(t) => {
                        //setTime(0);
                      }}
                      onChangeTime={(t) => {
                        setTime(t);
                      }}
                    />
                  </span>
                  {time < 20 && (
                    <span onClick={addExpired} className={styles.btn}>
                      add 5min
                    </span>
                  )}
                </div>
                <div
                  className={styles.line}
                  style={{ width: `${time}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function Item({ wallet, onSelectItem }) {
  return (
    <>
      <div className={styles.item} onClick={() => onSelectItem(wallet)}>
        <div className={styles.title}>{wallet.name}</div>
        <div className={styles.stage}>
          <Icon name="arrow-right" size="24" />
        </div>
      </div>
    </>
  );
}
