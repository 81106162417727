import React, { useState } from "react";
import styles from "./New.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import { useCreateAdminPlanForm, useUploadFile } from "api";
import TextInput from "components/TextInput";
import { Controller } from "react-hook-form";
import { Plan } from "components/Plan";
import { Volume } from "components/Volume";
import Upload from "components/Upload";
import Textarea from "components/Textarea";
import Checkbox from "components/Checkbox";
import TextEdit from "components/TextEdit";

const New = ({ refetch, onClose }) => {
  const [testVerstion, setTestVerstion] = useState(false);
  const [image, setImage] = useState('');
  const loading = useLoadingDispatch();
  const [form, onForm, _] = useCreateAdminPlanForm();
  const updateFile = useUploadFile();
  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync({ ...data, testVerstion });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };
  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      form.setValue("image", response);
      setImage(response);
    } else {
      form.setValue("image", null);
      setImage('');
      return Notify.error("Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF");
    }
  };
  const onCheckbox = async (e) => {
    const checked = e.target.checked;
    setTestVerstion(checked);
  };
  return (
    <form onSubmit={onForm(onSubmit)}>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>New Plan</div>
        <div className={styles.field}>
          <Upload
            value={image}
            onDelete={() => {
              form.setValue("image", null);
            }}
            onSelectFile={onUpdateFile}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Plan name"
            icon="info-stroke"
            error={form.errors.name?.message}
            {...form.register("name")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Price"
            icon="ticket"
            type="number"
            // step={10}
            error={form.errors.price?.message}
            {...form?.register("price")}
          />
        </div>
        <div className={styles.field}>
          <Checkbox onChange={onCheckbox} content="Test Verstion" />
        </div>

        <div className={styles.field}>
          {/* <TextInput
            className={styles.field}
            label="Daily Drow Down :"
            placeholder="Daily Drow Down"
            icon="ticket"
            type="number"
            step={"0.1"}
            error={form.errors.dailyDrowDown?.message}
            {...form?.register("dailyDrowDown")}
          /> */}
        </div>
        <Controller
          render={({ field }) => <Plan {...field} error={form?.errors?.plan} />}
          control={form.control}
          name="plan"
        // defaultValue={0}
        />
        <Controller
          render={({ field }) => (
            <Volume {...field} error={form?.errors?.volume} />
          )}
          control={form.control}
          name="volume"
        // defaultValue={0}
        />

        <div className={styles.field}>
          <Controller
            render={({ field }) => (
              <TextEdit
                image={false}
                name="description"
                className={styles.textarea}
                placeholder="What you would like to share?"
                value={field?.value}
                onChange={(v) => field.onChange(v)}

              />
            )}
            control={form.control}
            name="description"
          />
        </div>

        <div className={styles.field}>
          <TextInput
            className={styles.field}
            type="hidden"
            placeholder="image"
            {...form.image}
          />
        </div>
        <div className={styles.foot}>
          <button className={cn("button", styles.button)}>
            <span className="white">Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default New;
