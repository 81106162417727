import React from "react";
import styles from "./AdminModal.module.sass";
import cn from "classnames";
import { Img, NoImage } from "components/Image";
import { URL } from "api";
import Icon from "components/Icon";

export const AdminModal = ({ item, onClose }) => (
  <div className={cn(styles.post)}>
       <div className={cn("title-blue", styles.title)}>About me</div>
       <br/>
       <br/>
    <div className={cn(styles.card,'mt-24')}>
      <div className={styles.item}>
        <div className={styles.left}>
          <div className={styles.top}>
            <div className={styles.preview}>
              {item?.profile?.image ? (
                <Img
                  src={`${URL.CdnUrl}${item.profile?.image}`}
                  alt="Product"
                />
              ) : (
                <NoImage />
              )}
            </div>
            <div>
              <div className={styles.win}>&nbsp;</div>

              <div className={styles.win_number}>{item.name}</div>
              <div className={styles.type}>
                {new Date(item?.createdAt || new Date()).toDateString()}
              </div>
            </div>
          </div>
          <div className={styles.footer}> 
            <div
              className={styles.stage}
              dangerouslySetInnerHTML={{
                __html: item?.profile?.description?.replace(/\r?\n/gi, "<br />"),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className={styles.foot}>
      <button onClick={onClose} className={cn("button", "button-info")}>
        <span className="white">{"Close"}</span>
      </button>
    </div> */}
  </div>
);
