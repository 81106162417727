import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import Image from "../../components/Image";
import Icon from "../../components/Icon";
import { URL } from "api";

const SignUp = () => {
  const [visible, setVisible] = useState(false);
  const heightWindow = use100vh();

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <div className={styles.info}>
            <Link className={styles.link} to="">
              <Icon name="close" size={24} />
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Link className={styles.logo} to="/">
            <Image
              className={styles.pic}
              srcDark={URL.CdnUrl + "/logo/logo-dark.svg"}
              src={URL.CdnUrl + "/logo/logo-light.svg"}
              alt="logo"
            />
          </Link>
          <div className={cn("h4", styles.title)}>Sign up</div>
          {!visible ? (
            <Entry onConfirm={(data) => setVisible(data)} />
          ) : (
            <Code data={visible} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
