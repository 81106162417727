import React from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import { Loader } from "../../../components/Loader";
import { useHistory } from "react-router-dom";

const Code = ({ data }) => {
  const history = useHistory();
  return (
    <div className={styles.code}>
      <div className={styles.body}>
        <div className={styles.info}>
          SignUp successfull! <br />
          Please check your email inbox or spam
        </div>
        <button
          onClick={() => history.push("/")}
          className={cn("button", styles.button)}
        >
          <Loader className={styles.loader} white />
          <span>Close</span>
        </button>
      </div>
    </div>
  );
};

export default Code;
