import { EVolumeType, EVolumeTypeWay } from "api";

const VolumeValue = ({ type, value }) => {
  switch (`${type}`) {
    default:
    case `${EVolumeType.FIX}`:
      return <>{value}</>;
    case `${EVolumeType.FALLOW}`:
      return <>{EVolumeTypeWay[value]}</>;
    case `${EVolumeType.RATIO}`: {
      const [_1, _2, _3] = value.split(":");
      return (
        <>
          ${_1} is {_2} lot size in {EVolumeTypeWay[_3]}
        </>
      );
    }
    case `${EVolumeType.MULTIPLY}`:
      return <>{value}</>;
    case `${EVolumeType.RISK}`: {
      const [_1, _2] = value.split(":");
      return (
        <>
          {_1}% in {EVolumeTypeWay[_2]}
        </>
      );
    }
  }
};
export const VolumeValueToStr = ({ type, value }) => {
  switch (`${type}`) {
    default:
    case `${EVolumeType.FIX}`:
      return value;
    case `${EVolumeType.FALLOW}`:
      return EVolumeTypeWay[value];
    case `${EVolumeType.RATIO}`: {
      const [_1, _2, _3] = value.split(":");
      return `$${_1} is ${_2} lot size in ${EVolumeTypeWay[_3]}`;
    }
    case `${EVolumeType.MULTIPLY}`:
      return <>{value}</>;
    case `${EVolumeType.RISK}`: {
      const [_1, _2] = value.split(":");
      return  `${_1}% in ${EVolumeTypeWay[_2]}`;
    }
  }
};
export default VolumeValue;
