import React, { useState } from "react";
import styles from "./Upload.module.sass";
import { URL, useDeleteFile } from "../../api";
import TooltipGlodal from "components/TooltipGlodal";
import { Notify } from "components/Notify";
import Icon from "components/Icon";
const UploadIcon = ({
  onSelectFile,
  onDelete,
  error,
  value,
  radius = 10,
  isCircle = false,
  width = 80,
  height = 80,
  isDisabled,
  deleteFile = true,
}) => {
  const _ = useDeleteFile();
  const imageUrl = URL.CdnUrl;
  const getValuePath = () => {
    if (value) {
      return `${imageUrl}/${value}`;
    }
    resetFile();
    return;
  };
  const [getSelectStatus, setSelectStatus] = useState(false);
  const [getBase64Img, setBase64Img] = useState("");
  const borderRadius = isCircle === true ? (width + height) / 2 : radius;
  const itemSelect = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.click();
    input.onchange = (e) => {
      var file = e.target.files[0];
      var type = file.type?.toLocaleUpperCase();
      if (
        file.size > 1 * 1024 * 1024 ||
        !["IMAGE/JPG", "IMAGE/JPEG", "IMAGE/PNG", "IMAGE/GIF"].includes(type)
      ) {
        Notify.error(
          "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
        );
        return;
      }
      var reader = new FileReader();
      console.log("file", file);
      console.log("file", file?.size);
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        var base64 = readerEvent.target.result;
        setSelectStatus(true);
        setBase64Img(base64);
        onSelectFile(file, base64);
      };
    };
  };

  const resetFile = async () => {
    deleteFile && value && await _.mutateAsync({ file: value });
    setSelectStatus(false);
    setBase64Img("");
  };
  const Img = () => {
    return (
      <div
        data-tip={`upload picture limit size: 1MB format: JPG | JPEG | PNG | GIF`}
        data-place={"top"}
      >
        <Icon className="link" name="photos" size="24" />
      </div>
    );
  };
  const getImage = () => {
    if (getSelectStatus === true || value) {
      if (!getValuePath()) {
        return <Img />;
      }
      return (
        <div
          onClick={async () => {
            await resetFile();
            onDelete();
          }}
          data-tip={`Delete image`}
          data-place={"top"}
        >
          <Icon className="link" name="trash" size="24" />
        </div>
      );
    } else {
      return (
        <>
          <Img />
        </>
      );
    }
  };

  return (
    <div className={styles.uploadpage}>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div
          onClick={() => {
            if (!getSelectStatus && !isDisabled) itemSelect();
          }}
          className={styles.uploader}
        >
          {getImage()}
        </div>
      </div>
      <div span={24} align="center">
        {error !== "" && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default UploadIcon;
