import React from "react";
import { Notify } from "components/Notify";
import { copy } from "common";
import cn from "classnames";
import Icon from "components/Icon";

const CopyUi = ({
  icon = "copy",
  isIcon = false,
  iconProps = { size: 20 },
  children,
  className,
  ...props
}) => {
  return (
    <span
      {...props}
      className={cn("link", className || "")}
      onClick={() => {
        copy(children);
      }}
    >
      {children} {isIcon && <Icon name={icon} {...iconProps} />}
    </span>
  );
};

export default CopyUi;
