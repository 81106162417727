import React, { useEffect, useState } from "react";
import styles from "./ChargeWallet.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import {
  URL,
  useClientProfile,
  useGetSetting,
  useListAdminPlan,
} from "../../api";
import { LoaderMint } from "../../components/Loader";
import Seo from "../../components/Seo";
import { web3Modal, onSignClient } from "components/Wallet/Web3Modal";
import cn from "classnames";
import {
  useTransactionDispatch,
  useTransactionState,
  useWalletDispatch,
  useWalletState,
} from "context";
import { TextInput } from "components/TextInput";
import Modal from "components/Modal";
import { Notify } from "components/Notify";
import Wallets from "./Wallets";

const ChargeWallet = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [balance, setBalance] = useState(0);
  const { data: profile, isLoading, refetch } = useClientProfile();
  const onTrx = useTransactionDispatch();
  const { trx } = useTransactionState();
  const chargeWallet = (b) => {
    if (trx) {
      onTrx?.lastTransaction();
      return onTrx?.setTrxModal(true);
    }
    if (b >= 1) setVisibleModal(b);
    else Notify.error("Account charges cannot be less than $1");
  };
  if (isLoading)
    return (
      <>
        <LoaderMint />
        <LoaderMint />
        <LoaderMint />
      </>
    );
  return (
    <>
    

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={cn(styles.card, styles.card_recharge)}>
            <div className={styles.list}>
              <div className={styles.package}>
                <div className={styles.top}>
                  <div className={cn("title-purple", styles.title)}>
                    {"Account recharge"}
                  </div>
                </div>
                <div className={styles.content}>
                  Your account balance is <b>$ {profile?.data?.balance || 0}</b>
                  . You can top up your account in the following ways.
                </div>
                <div className={styles.wallet}>
                  {[10, 25, 50, 100, 250].map((b) => (
                    <div
                      onClick={() => chargeWallet(b)}
                      className={styles.ussd}
                      key={b}
                    >
                       $ {b}
                    </div>
                  ))}
                </div>
                <div className={styles.wallet}>
                  <TextInput
                    className={styles.input}
                    placeholder="Enter custom amount"
                    icon={"dollar"}
                    type="number"
                    onChange={(e) => setBalance(e.target.value)}
                  />
                  <div
                    onClick={() => chargeWallet(balance)}
                    className={cn("button", styles.btn)}
                  >
                    recharge
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        {visibleModal > 0 && (
          <Wallets
            plan={{ name: "charge account", price: visibleModal }}
            onBackBuyNow={() => setVisibleModal(false)}
            onClose={() => {
              setVisibleModal(false);
              refetch && refetch();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default ChargeWallet;
