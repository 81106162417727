import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function TextEdit({ onChange, image = true, value, placeholder, error }) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }], //"Poppins", "Inter", "sans-serif"
      [{ align: [] }],
      image ? ["link", "image", "video"] : ["link"],
      ["clean"],
      // [
      //   {
      //     header: [1, 2, 3, 4, 5, 6, false],
      //     align: [],
      //     color: ["red"],
      //     background: [],
      //   },
      // ],
      // ["bold", "italic", "underline", "strike", "blockquote"],
      // [
      //   { list: "ordered" },
      //   { list: "bullet" },
      //   { indent: "-1" },
      //   { indent: "+1" },
      // ],
      // [{ font: [] }],
      // ["link", "image"],
      // ["clean"],
    ],
  };
  const [lastValue, setValue] = useState();
  useEffect(() => {
    if (value) {
      setValue(value);
    } else {
      setValue("");
    }
    return () => {
      setValue("");
    };
  }, [value]);
  const handleOnChange = (value) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };
  if (error) {
    return <>
     <ReactQuill
      modules={modules}
      placeholder={placeholder}
      theme="snow"
      value={lastValue}
      onChange={handleOnChange}
      error={error}
    />
     <span className="text text-red" style={{fontSize: "15px"}}> {error?.message }</span>
    </>
  }
  return (
    <ReactQuill
      modules={modules}
      placeholder={placeholder}
      theme="snow"
      value={lastValue}
      onChange={handleOnChange}
      error={error}
    />
  );
}
export default TextEdit;
