import { TransactionStatus } from "enum";

const Status = ({ value }) => {
  switch (`${value}`) {
    case TransactionStatus.EXPIRED:
      return <span className="status status-red-dark">EXPIRED</span>;
    case TransactionStatus.CANCEL:
      return <span className="status status-red">CANCEL</span>;
    case TransactionStatus.PENDING:
      return <span className="status status-yellow">PENDING</span>;
    case TransactionStatus.ACTIVE:
      return <span className="status status-blue">active</span>;
    case TransactionStatus.PAID:
      return <span className="status status-green">PAID</span>;
    case TransactionStatus.PAID_WITH_ADMIN:
      return <span className="status status-green">Manual Charge</span>;
    case TransactionStatus.PARTIAL_PAYMENT:
      return <span className="status status-green">Partial Payment</span>;
    default:
      return <>---</>;
  }
};
export default Status;
