import Checkbox from "../../../../components/Checkbox";
import React from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import {
  useEditWalletApi,
  useEditWalletForm,
  useUpdateSetting,
  useUploadFile,
} from "api";
import { TextInput } from "components/TextInput";
import { Controller } from "react-hook-form";
import Textarea from "components/Textarea";
import Select from "components/Select";
import { useForm } from "common";
import Html from "components/Html";
import TextEdit from "components/TextEdit";
import Upload from "components/Upload";
import UploadEmail from "components/UploadEmail";

const Edit = ({ refetch, onClose, item }) => {
  const loading = useLoadingDispatch();
  const [form, onForm] = useForm(item);
  const updateFile = useUploadFile();
  const _ = useUpdateSetting();
  const onSubmit = async () => {
    loading.show();

    const response = await _.mutateAsync({
      ...form.data,
      _id: item._id,
      name: "emails",
    });
    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
      onClose && onClose();
    }
    loading.hide();
  };

  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      onForm("file")(response);
    } else {
      onForm("file")("");
      return Notify.error(
        "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
      );
    }
  };
  //
  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>
        Edit Email {form?.type}
      </div>
      <div className={styles.field}>
        <UploadEmail
          file={form?.file}
          onDelete={() => {
            onForm("file")("");
          }}
          onSelectFile={onUpdateFile}
        />
      </div>
    
      <div className={styles.field}>Subject</div>
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          placeholder="Subject"
          icon="info-stroke"
          value={form?.subject}
          onChange={onForm("subject")}
        />
      </div>
      <div className={styles.field}>Button</div>
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          placeholder="Button"
          icon="info-stroke"
          value={form?.btn}
          onChange={onForm("btn")}
        />
      </div>
      <div className={styles.field}>Description</div>
      <div className={styles.field}>
        <TextEdit
          image={false}
          placeholder="What you would like to share?"
          value={form?.description}
          onChange={onForm("description")}
        />
      </div>

      <div className={styles.foot}>
        <button
          type="button"
          onClick={onSubmit}
          className={cn("button", styles.button)}
        >
          <span className="white">Save</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Edit;
