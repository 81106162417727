/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useReducer } from "react";
import * as action from "./action";
import { Reducer } from "./reducer";
import { LoadingStateContext, LoadingDispatchContext } from "./context";
import { dispatch } from "../tools";

export const LoadingProvider = ({ children }) => {
  const [state, setState] = useReducer(Reducer, false);
  const actions = React.useMemo(
    () => ({
      show: action.handleShow(dispatch(setState)),
      hide: action.handleHide(dispatch(setState)),
    }),
    [setState]
  );
  return (
    <LoadingDispatchContext.Provider value={actions}>
      <LoadingStateContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </LoadingStateContext.Provider>
    </LoadingDispatchContext.Provider>
  );
};
