import React, { useEffect, useState } from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useLoadingDispatch, useUserDispatch } from "../../../../context";
import { Notify } from "../../../../components/Notify";
import { useEditAdminPlanForm, useUploadFile } from "../../../../api";
import TextInput from "../../../../components/TextInput";
import { Controller } from "react-hook-form";
import { Plan } from "../../../../components/Plan";
import { Volume } from "../../../../components/Volume";
import Upload from "../../../../components/Upload";
import Textarea from "../../../../components/Textarea";
import Checkbox from "../../../../components/Checkbox";
import TextEdit from "components/TextEdit";

const Edit = ({ refetch, onClose, item }) => {
  const [testVerstion, setTestVerstion] = useState(item?.testVerstion || false);
  const [active, setActive] = useState(item?.active || false);
  const [image, setImage] = useState(item?.profile?.image || false);
  const loading = useLoadingDispatch();
  const action = useUserDispatch();
  const [form, onForm, _] = useEditAdminPlanForm(item);
  const updateFile = useUploadFile();
  useEffect(() => {
    if (item?.image) setImage(item?.image);
    return () => {
      setImage("");
    };
  }, [item]);

  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync({
      ...data,
      testVerstion,
      active,
      _id: item?._id,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };
  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      form.setValue("image", response);
      setImage(response);
    } else {
      setImage('');
      return Notify.error("Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF");
    }
  };
  const onCheckbox = async (e) => {
    const checked = e.target.checked;
    setTestVerstion(checked);
  };
  const onSetActive = async (e) => {
    const checked = e.target.checked;
    setActive(checked);
  };
  return (
    <form onSubmit={onForm(onSubmit)}>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>Edit Plan</div>
        <div className={styles.field}>
          <Upload
            value={image}
            onDelete={() => {
              form.setValue("image", null);
            }}
            onSelectFile={onUpdateFile}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Plan name"
            icon="info-stroke"
            error={form.errors.name?.message}
            {...form.register("name")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Price"
            icon="ticket"
            type="number"
            step="0.01"
            error={form.errors.price?.message}
            {...form?.register("price")}
          />
        </div>
        <div className={styles.field_checkbox}>
          <Checkbox value={active} onChange={onSetActive} content="Enable" />
          <Checkbox
            value={testVerstion}
            onChange={onCheckbox}
            content="Test Verstion"
          />

        </div>
        <div className={styles.field}>
          {/* <TextInput
            className={styles.field}
            label="Daily Drow Down :"
            placeholder="Daily Drow Down"
            icon="ticket"
            type="number"
            step="0.01"
            error={form.errors.dailyDrowDown?.message}
            {...form?.register("dailyDrowDown")}
          /> */}
        </div>
        <Controller
          render={({ field }) => <Plan readonly {...field} error={form?.errors?.plan} />}
          control={form.control}
          name="plan"
        // defaultValue={0}
        />
        <Controller
          render={({ field }) => (
            <Volume {...field} error={form?.errors?.volume} readonly={!action?.canAdmin('volumeUpdate')} />
          )}
          control={form.control}
          name="volume"
        // defaultValue={0}
        />

        <div className={styles.field}>
          <Controller
            render={({ field }) => (
              <TextEdit
              // image={false}
                name="description"
                className={styles.textarea}
                placeholder="What you would like to share?"
                value={field?.value}
                error={form?.errors?.description}
                onChange={(v) => field.onChange(v)}

              />
            )}
            control={form.control}
            name="description"
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            type="hidden"
            placeholder="image"
            {...form.image}
          />
        </div>
        <div className={styles.foot}>
          <button className={cn("button", styles.button)}>
            <span className="white">Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Edit;
