import React, { useEffect, useState } from "react";
import styles from "./Transaction.module.sass";
import ListTransaction from "./ListTransaction";

import { useListTransaction } from "../../api";
const Transaction = (props) => {
  const data = useListTransaction();
  return (
    <>
      <div className={styles.section}>
        <ListTransaction {...data} />
      </div>
    </>
  );
};

export default Transaction;
