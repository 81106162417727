import { useContext, createContext } from "react";

export const TransactionStateContext = createContext();
export const TransactionDispatchContext = createContext();

/**
 * Transaction State Managment
 * @returns state
 */
export const useTransactionState = () => {
  const context = useContext(TransactionStateContext);
  if (context === undefined) {
    throw new Error("useTransactionState must be used within a TransactionProvider");
  }
  return context;
};

/**
 * Transaction Action Managment
 * @returns Actions
 */
export const useTransactionDispatch = () => {
  const context = useContext(TransactionDispatchContext);
  if (context === undefined) {
    throw new Error("useTransactionDispatch must be used within a TransactionProvider");
  }
  return context;
};
