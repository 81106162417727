import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Modal from "../../../../../components/Modal";
import Icon from "../../../../../components/Icon";
import EditAdmin from "../../Edit";
import AccountShow from "../../Account";
import DelAdmin from "../../Del";
import Tooltip from "components/Tooltip";
import TooltipGlodal from "components/TooltipGlodal";
import { useUserDispatch, useUserState } from "context";
import { useHistory } from "react-router";

const Control = ({ role, className, refetch, item }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const history = useHistory();
  const state = useUserState();
  const actions = [
    {
      icon: "wallet",
      tooltip: "Charge wallet",
      action: () => setVisibleModal("edit"),
    },
    {
      icon: "trash",
      tooltip: "Delete user",
      action: () => setDelModal("edit"),
    },
  ];
  if (state && state?.isSuperAdmin) {
    actions.push({
      icon: "account",
      tooltip: "Show account",
      action: () => history.push("/accounts/" + item?._id),
    });
  }

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Tooltip
              className={styles.tooltip}
              title={x.tooltip}
              icon={x.icon}
              place={"top"}
            />
          </button>
        ))}
      </div>
      <Modal visible={accountModal} onClose={() => setAccountModal(false)}>
        <>
          {item && (
            <AccountShow
              item={item}
              role={role}
              onClose={() => setAccountModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <>
          {item && (
            <EditAdmin
              item={item}
              role={role}
              onClose={() => setVisibleModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <Modal visible={delModal} onClose={() => setDelModal(false)}>
        <>
          {item && (
            <DelAdmin
              item={item}
              onClose={() => setDelModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <TooltipGlodal />
    </>
  );
};

export default Control;
