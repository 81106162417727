import React from "react";
import styles from "./New.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import { useAddPaidLogAdmin } from "api/useAdmin";
import { TextInput } from "components/TextInput";
import Textarea from "components/Textarea";
import { useForm } from "common";

const New = ({ refetch, id, onClose }) => {
  const loading = useLoadingDispatch();
  const _ = useAddPaidLogAdmin({});
  const [form, onForm] = useForm();

  const onSubmit = async () => {
    loading.show();
    const response = await _.mutateAsync({ _id: id, ...form?.data });
    loading.hide();
    if (response?.error) {
      return Notify.error(
        typeof response?.message === "string"
          ? response?.message
          : response?.message?.at(-1)
      );
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };

  return (
    <>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>New Paid</div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Price"
            icon="dollar"
            type="number"
            onChange={onForm("price")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="From Address"
            icon="wallet"
            type="string"
            onChange={onForm("from")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="To Address"
            icon="wallet"
            type="string"
            onChange={onForm("to")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Hash"
            icon="wallet"
            type="string"
            onChange={onForm("hash")}
          />
        </div>
        <div className={styles.field}>
          <Textarea
            name="description"
            // className={styles.textarea}
            placeholder="What you would like to share?"
            // {...form.description}
            onChange={onForm("description")}
          />
        </div>

        <div className={styles.foot}>
          <div className={styles.files}></div>
          <button onClick={onSubmit} className={cn("button", "button-info")}>
            <span>Add Paid</span>
            <Icon name="add" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default New;
