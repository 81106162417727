import { setAuth } from "../../api";
import * as Types from "./types";

export const Reducer = (state, action) => {
  switch (action?.type) {
    case Types.SignIn: {
      let menu = "user";
      if (action.value?.admin) {
        if (action.value?.admin.role === "1") {
          menu = "admin";
        } else if (action.value?.admin.role === "2") {
          menu = "adminPart";
        } else {
          menu = "super_admin";
        }
      }

      return {
        ...state,
        menu,
        isAuth: true,
        role: action.value?.admin?.role || action.value?.user?.role || "user",
        user: action.value?.admin || action.value?.client,
        isAdmin: !!action.value?.admin,
        isAdminPart: action.value?.admin?.role === "2", // ADMIN_PARTNER = '2', //user and pass
        isUser: !action.value?.admin,
        isSuperAdmin: menu === "super_admin",
        isMasterLocked: action.value?.isMasterLocked,
        isGuest: false,
      };
    }
    case Types.SignUp: {
      return {
        ...state,
        ...action,
      };
    }
    case Types.Profile: {
      return {
        ...state,
        user: { ...state.user, ...action.value },
      };
    }
    case Types.Logout: {
      setAuth();
      if (state.isUser) action.value?.push("/sign-in");
      else if (state.isSuperAdmin) action.value?.push("/master/sign-in");
      else if (state.isAdmin)
        action.value?.push(`/${state.user.account}/sign-in`);
      return {
        // ...state,
        menu: "user",
        isAuth: false,
        role: null,
        user: null,
        isAdmin: false,
        isUser: false,
        isGuest: true,
      };
    }
    default:
      throw new Error(`User Unhandled action type: ${action.type}`);
  }
};
