import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { http } from "./http.config";
import { getAuth } from "./useAuth";

export function usePagesWithLoadeMore(
  url,
  isAuth,
  defualtPage,
  defualtCount,
  filter
) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defualtPage || 0);
  const [count, setCount] = useState(defualtCount || 20);
  const [filters, setFilters] = useState(filter || {});
  const [items, setItems] = useState([]);
  const { data, refetch, isFetching } = useQuery(
    [url, page, count, filters],
    async () => {
      try {
        const headers = {};
        if (isAuth) {
          const auth = getAuth();
          if (!auth) {
            return { error: true, message: "plase login" };
          }
          headers["Authorization"] = `Bearer ${auth.token}`;
        }

        let qr = `page=${page || 0}`;
        if (count) qr += `&count=${count}`;
        if (filters) {
          for (const key of Object.keys(filters)) {
            if (key in filters) {
              if (typeof filters[key] === "string") {
                qr += `&${key}=${filters[key]}`;
              } else {
                qr += `&${key}=${JSON.stringify(filters[key])}`;
              }
            }
          }
        }
        setLoading(true);
        const res = await http.get(`${url}?${qr}`, { headers });

        setLoading(false);
        return res?.data;
      } catch (error) {
        setLoading(false);

        return null;
      }
    },
    { keepPreviousData: true }
  );
  useEffect(() => {
    if (data?.data) {
      if (!page && page === 0) {
        setItems(data?.data);
      } else {
        setItems([...items, ...data?.data]);
      }
    }
  }, [data]);
  const changePage = (page) => {
    setPage(page);
  };
  const changeCount = (c) => {
    setCount(c);
    setPage(0);
    setItems([]);
  };
  const changeFilter = (key) => (value) => {
    setPage(0);
    setItems([]);
    setFilters({ ...filters, [key]: value });
  };
  return useMemo(() => {
    return {
      filters,
      changeFilter,
      isLoading: loading,
      changePage,
      changeCount,
      data: items,
      refetch,
      page,
      isFetching,
    };
  }, [loading, items]);
}
export function useFindOne(url, param, isAuth) {
  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState({});
  const { data, refetch, isFetching } = useQuery(
    [url],
    async () => {
      try {
        const headers = {};
        if (isAuth) {
          const auth = getAuth();
          if (!auth) {
            return { error: true, message: "plase login" };
          }
          headers["Authorization"] = `Bearer ${auth.token}`;
        }
        if (!param) {
          return { error: true, message: "not set param" };
        }
        setLoading(true);
        const res = await http.get(`${url}/${param}`, { headers });

        setLoading(false);
        return res?.data;
      } catch (error) {
        setLoading(false);

        return null;
      }
    },
    { keepPreviousData: true }
  );
  useEffect(() => {
    if (data?.data) {
      setItems(data?.data);
    }
  }, [data]);

  return useMemo(() => {
    return {
      isLoading: loading,
      data: items,
      refetch,
      isFetching,
    };
  }, [loading, items]);
}
export function usePages(url, isAuth, defualtPage, defualtCount, filter) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defualtPage || 0);
  const [count, setCount] = useState(defualtCount || 20);
  const [filters, setFilters] = useState(filter || {});
  const { data, refetch, isFetching } = useQuery(
    [url, page, count, filters],
    async () => {
      try {
        const headers = {};
        if (isAuth) {
          const auth = getAuth();
          if (!auth) {
            return { error: true, message: "plase login" };
          }
          headers["Authorization"] = `Bearer ${auth.token}`;
        }

        let qr = `page=${page || 0}`;
        if (count) qr += `&count=${count}`;
        if (filters) {
          for (const key of Object.keys(filters)) {
            if (key in filters) qr += `&${key}=${filters[key]}`;
          }
        }
        setLoading(true);
        const res = await http.get(`${url}?${qr}`, { headers });

        setLoading(false);
        return res?.data;
      } catch (error) {
        setLoading(false);

        return null;
      }
    },
    { keepPreviousData: true }
  );
  const changePage = (page) => {
    setPage(page);
    setCount(0);
  };
  return useMemo(() => {
    return {
      filters,
      changeFilter: setFilters,
      isLoading: loading,
      changePage,
      changeCount: setCount,
      data,
      refetch,
      page,
      isFetching,
    };
  }, [loading, data]);
}
