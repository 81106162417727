import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon, { MenuIcon } from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Image, { Img } from "../Image";
import {
  useTransactionDispatch,
  useTransactionState,
  useUserDispatch,
  useUserState,
} from "../../context";
import { Menus } from "common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { URL } from "api";
import { Timer } from "components/Timer";

const Sidebar = ({ className, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [menu, setMenu] = useState(Menus.navigation);
  const history = useHistory();
  const user = useUserState();
  const action = useUserDispatch();
 
  useEffect(() => {
    if (user.isAuth) {
      setMenu(Menus[user.menu]);
    }
    return () => {
      return setMenu(Menus.navigation);
    };
  }, [user]);
  const onLogout = () => {
    action.onLogout(history, user.isMasterLocked);
  };
  
  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            srcDark={URL.CdnUrl+"/logo/logo-dark.svg"}
            src={URL.CdnUrl+"/logo/logo-light.svg"}
            alt="Logo"
          />
        </Link>
        <div className={styles.menu}>
          {menu.map((x, index) =>
            x.url ? (
              <NavLink
                className={styles.item}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                exact
                onClick={onClose}
              >
                <MenuIcon
                  name={x.icon}
                  size={x?.iconProps?.size || "24"}
                  viewBox={x?.iconProps?.viewBox}
                />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
      
        <div className={styles.footicon}>
          {user.isAuth ? (
            <button className={cn("button", styles.button)} onClick={onLogout}>
              {user.isMasterLocked ? (
                <>
                  <Icon name="arrow-left" size="24" /> Back to super admin
                </>
              ) : (
                <>
                  <Icon name="lock" size="24" />
                  Logout
                </>
              )}
            </button>
          ) : (
            <button
              className={cn("button", styles.button)}
              onClick={() => history.push("/sign-in")}
            >
              <Icon name="lock" size="24" />
              Signin
            </button>
          )}
        </div>

        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>

      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
