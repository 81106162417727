import { Redirect, Route } from "react-router";
import { getAuth } from "../api";

export const Auth = ({ render,roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const auth = getAuth();
        if (!auth) {
          return <Redirect to="/sign-in" />;
        } 
        const user= auth?.user?.user;
        if(roles && !roles.includes(user?.role)){
          return <Redirect to="/403" />;
        }
        return render(props);
      }}
    />
  );
};
