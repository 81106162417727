import React, { useEffect, useState } from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Upload from "../../../../components/Upload";
import { useLoadingDispatch } from "../../../../context";
import { useUploadFile } from "../../../../api";
import { Notify } from "../../../../components/Notify";
import { Role, useEditAdminForm } from "../../../../api/useAdmin";
import TextInput from "../../../../components/TextInput";
import Textarea from "../../../../components/Textarea";
import Checkbox, { CheckboxRef } from "../../../../components/Checkbox";
import { Controller } from "react-hook-form";
import TextEdit from "components/TextEdit";

const Edit = ({ refetch, onClose, item }) => {
  const [partner, setPartner] = useState(false);
  const [image, setImage] = useState('');
  const loading = useLoadingDispatch();
  const [form, onForm, _] = useEditAdminForm({ ...item });
  const updateFile = useUploadFile();
  useEffect(() => {
    if (item && Role.checked(Role.ADMIN_PARTNER, item?.role)) {
      setPartner(true);
      form.setValue("partner", "1");
    }
    return () => {
      setPartner(false);
    };
  }, [item, form]);

  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync({ ...data, _id: item?._id });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Update successfull!");
      return;
    }
  };
  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      form.setValue("image", response);
      setImage(response)
      // Notify.success("Upload file successfull!");
    } else {
      // form.setValue("image", null);
      setImage('')
      return Notify.error(
        "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
      );
    }
  };
  const onCheckbox = async (e) => {
    const checked = e.target.checked;
    setPartner(checked);
    form.setValue("partner", checked ? "1" : "");
  };
  return (
    <form onSubmit={onForm(onSubmit)}>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>Edit Master</div>

        <div className={styles.field}>
          <Upload
            value={image || item?.profile?.image}
            onDelete={() => {
              form.setValue("image", null);
            }}
            onSelectFile={onUpdateFile}
          />
        </div>

        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Account number"
            icon="info"
            type="number"
            error={form.errors.account?.message}
            {...form?.account}
          />
        </div>

        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Your name"
            icon="info"
            error={form.errors.name?.message}
            {...form.name}
          />
        </div>
        <div className={styles.field}>
          <Checkbox
            value={partner}
            onChange={onCheckbox}
            content="Admin partner"
          />
        </div>
        <div
          className={styles.field}
          style={{
            display: partner ? "block" : "none",
          }}
        >
          <TextInput
            className={styles.field}
            placeholder="Your email"
            icon="mail"
            // readonly
            error={form.errors.email?.message}
            {...form.email}
          />
        </div>
        <div
          className={styles.field}
          style={{
            display: partner ? "block" : "none",
          }}
        >
          <TextInput
            className={styles.field}
            type="password"
            placeholder="Password"
            icon="lock"
            error={form.errors.password?.message}
            {...form.password}
          />
        </div>
        <div
          className={styles.field}
          style={{
            display: partner ? "block" : "none",
          }}
        >
          <TextInput
            className={styles.field}
            type="number"
            placeholder="Super admin commission"
            icon="commission"
            max={100}
            min={0}
            error={form.errors.commission?.message}
            {...form.commission}
          />
        </div>
        <div className={styles.field}> 
        <Controller
                render={({ field }) => (
                  <TextEdit
                    name="description"
                    className={styles.textarea}
                    placeholder="What you would like to share?"
                    value={field?.value}
                    onChange={(v) => field.onChange(v)}

                  />
                )}
                control={form.control}
                name="description"
              />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            type="hidden"
            placeholder="image"
            {...form.image}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            type="hidden"
            placeholder="partner"
            {...form.partner}
          />
        </div>
        <div className={styles.field_flex}>
          <CheckboxRef {...form?.access?.clientAdd} content="Add client" />
          <CheckboxRef {...form.access?.clientRemove} content="Remove client" />
          <CheckboxRef {...form.access?.volumeUpdate} content="Update volume" />
        </div>
        <div className={styles.field}>
          {/* Token :
          <br /> */}
          {/* <TextInput
            className={styles.field}
            type="password"
            readonly
            placeholder="token"
            value={item?.token}
            icon="lightning"
          /> */}
        </div>
        <div className={styles.foot}>
          <div className={styles.files}></div>
          <button className={cn("button", styles.button)}>
            <span>Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Edit;
