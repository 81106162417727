import React, { useEffect, useState } from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch, useUserDispatch } from "context";
import { Notify } from "components/Notify";
import { Department, Priority, TicketStatus } from "enum";
import { Img } from "components/Image";
import { useForm } from "common";
import { useCreateTicketReply, useListTicketReply } from "api/useTicket";
import { URL, useUploadFile } from "api";
import UploadIcon from "components/UploadIcon";
import TooltipGlodal from "components/TooltipGlodal";

const Item = ({ right, message, image, ...user }) => {
  return (
    <div className={right ? styles.item_right : styles.item}>
      <div className={styles.icon}>
        <Img src={`${URL.CdnUrl}${image}`} alt="Image" />
      </div>
      <div className={styles.messages}>
        <div className={styles.name}>
          <div className={styles.message}>{user?.name}</div>
          <div className={styles.time}>
            {user?.createdAt?.replace(/T/gi, " ")?.replace(/.{7}Z/gi, "")}
          </div>
        </div>

        {user?.file && (
          <div className={styles.file}>
            <Img src={`${URL.CdnUrl}${user?.file}`} />
          </div>
        )}
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
};
const Edit = ({ isSuperAdmin, role, type, onClose, item }) => {
  const loading = useLoadingDispatch();
  const { data, refetch } = useListTicketReply(item?._id);
  const user = useUserDispatch();
  const [form, onForm, __] = useForm();
  const [replies, setReplies] = useState([]);
  const _ = useCreateTicketReply();
  const updateFile = useUploadFile();
  const onSubmit = async () => {
    if (item.status === TicketStatus.Close && !isSuperAdmin) {
      return Notify.error("ticket is closed!");
    }
    if (!form?.message) {
      return Notify.error("message is reqierd!");
    }
    const data = { ticket: item?._id };
    if (form?.message) {
      data["message"] = form.message;
    }
    if (form?.file) {
      data["file"] = form.file;
    }
    loading.show();
    const response = await _.mutateAsync(data);
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      setReplies([
        ...replies,
        {
          ...response?.data,
        },
      ]);
      __({});
      refetch && refetch();
      // onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };
  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (!response?.error) {
      onForm("file")(response);
      return Notify.success("Attach successfully!");
    } else {
      onForm("file")("");
      return Notify.error(
        "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
      );
    }
  };
  return (
    <>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>
          Ticket
          <div className={styles.text}>
            Status :
            <span className={cn("text-green")}>
              {TicketStatus[item?.status]}
            </span>
            Priority:{" "}
            <span className={cn("text-blue")}>{Priority[item?.priority]}</span>
            Department :
            <span className={cn("text-red")}>
              {Department[item?.department]}
            </span>
          </div>
        </div>

        <div className={styles.body}>
          <Item
            message={item?.message}
            createdAt={item.createdAt}
            name={item?.owner?.name}
            image={item?.owner?.profile?.image}
            file={item?.file}
          />
          {data &&
            data?.map((reply) => {
              return (
                <>
                  <Item
                    message={reply?.message}
                    createdAt={reply.answeredAt || item.createdAt}
                    name={reply?.owner?.name}
                    image={reply?.owner?.profile?.image}
                    right={reply?.admin}
                    file={reply?.file}
                  />
                </>
              );
            })}
        </div>
        <div className={styles.foot}>
          <UploadIcon
            value={form?.file}
            onDelete={() => {
              onForm("file", null);
            }}
            onSelectFile={onUpdateFile}
          />
          <input
            readOnly={item.status === TicketStatus.Close && !isSuperAdmin}
            placeholder="Please write your message"
            className={styles.textarea}
            {...form.register("message")}
            onChange={onForm("message")}
            onClick={() => {
              if (item.status === TicketStatus.Close && !isSuperAdmin) {
                return Notify.error("ticket is closed!");
              }
            }}
          />
          <button onClick={onSubmit} className={cn("button", styles.button)}>
            <span className="white">send</span>
            <Icon name="message" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Edit;
