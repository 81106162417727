import React, { useEffect, useState } from "react";
import styles from "./Help.module.sass";

import { useGetSetting, useUpdateSetting } from "api";
import EditSetting from "./EditSetting";
import { useLoadingDispatch, useUserState } from "context";
import { Notify } from "components/Notify";
import ShowSetting from "./ShowSetting";

const Help = () => {
  const TAG = "help";
  const { data, refetch } = useGetSetting(TAG);
  const loading = useLoadingDispatch();
  const state = useUserState();
  const _ = useUpdateSetting();
  const [helps, setHelps] = useState([]);
  useEffect(() => {
    if (data?.helps) {
      setHelps(data?.helps);
    }
  }, [data?.helps]);
  const onAdd = async (help, resetForm) => {
    loading.show();
    const response = await _.mutateAsync({
      helps: [help, ...helps],
      name: TAG,
    });
    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Add successfull!");
      refetch && (await refetch());
      resetForm && resetForm();
    }
    loading.hide();
  };
  const onEdit =
    (index) =>
    async (help) => {
       
      loading.show();
      let f = helps;
      f[index] = help;
      const response = await _.mutateAsync({
        helps: f,
        name: TAG,
      });

      if (response?.error) {
        Notify.error(response?.message);
      } else {
        Notify.success("Update successfull!");
        refetch && (await refetch());
      }
      loading.hide();
    };
  const onDel = (index) => async () => {
    loading.show();
    let f = helps.filter((i, ind) => `${ind}` !== `${index}`);

    const response = await _.mutateAsync({
      helps: f,
      name: TAG,
    });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  const onUpIndex = (index) => async () => {
    loading.show();
    let f = helps;
    const a = f[index];
    const b = f[index - 1];
    f[index] = b;
    f[index - 1] = a;
    const response = await _.mutateAsync({
      helps: f,
      name: TAG,
    });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  const onDownIndex = (index) => async () => {
    loading.show();
    let f = helps;
    const a = f[index];
    const b = f[index + 1];
    f[index] = b;
    f[index + 1] = a;
    const response = await _.mutateAsync({
      helps: f,
      name: TAG,
    });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  return (
    <>
      <div className={styles.section}>
        {state.isSuperAdmin ? (
          <>
            <EditSetting title={"Helps"} onSave={onAdd} />
            <br />
            {helps && (
              <>
                {helps.map((f, index) => {
                  return (
                    <EditSetting
                      key={index}
                      item={f}
                      title={index + 1 + "-" + f?.subject}
                      onSave={onEdit(index)}
                      onDel={onDel(index)}
                      onDownIndex={
                        index + 1 !== helps.length && onDownIndex(index)
                      }
                      onUpIndex={index !== 0 && onUpIndex(index)}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          <ShowSetting title={"Helps"} data={helps} />
        )}
      </div>
    </>
  );
};

export default Help;
