import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Packages.module.sass";
import TextInput from "../../../components/TextInput";
import { useUpdateProfileForm } from "../../../api/useAdmin";
import { IsAdmin, NotSuperAdmin, useLoadingDispatch } from "../../../context";
import { Notify } from "../../../components/Notify";
// import File from "../../../components/File";
import Upload from "../../../components/Upload";
import { URL, useGetSetting, useUploadFile } from "../../../api";
import TextEdit from "components/TextEdit";
import { Controller } from "react-hook-form";

const ProfileForm = ({ className, user }) => {
  const TAG = "master-mql";
  const { data, refetch } = useGetSetting(TAG);
  const [image, setImage] = useState(user?.profile?.image || "");
  const [form, onForm, _] = useUpdateProfileForm({
    email: user?.email,
    name: user?.name,
    image: user?.profile?.image,
    description: user?.profile?.description,
  });
  const loading = useLoadingDispatch();
  const updateFile = useUploadFile();
  useEffect(() => {
    if (user) setImage(user?.profile?.image);
  }, [user?._id]);

  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync({ ...data, image });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      return;
    }
  };
  const onUpdateFile = async (file, _) => {
    loading.show();
    const response = await updateFile.mutateAsync({ file });
    loading.hide();
    if (response) {
      setImage(response);
      // Notify.success("Upload file successfull!");
    } else {
      setImage("");
      return Notify.error(
        "Sorry, Image size must be less than 1 MB and allowed formats are JPG,JPEG,PNG,GIF"
      );
    }
  };
  return (
    <div className={cn(styles.packages, className)}>
      <div className={styles.list}>
        <div className={styles.package}>
          <div className={styles.top}>
            <div className={cn("title-purple", styles.title)}>
              {"Update Profile"}
            </div>
          </div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html:
                'Your can update profile and password <span role="img" aria-label="fire">🔥</span>',
            }}
          ></div>

          <form className={styles.body} onSubmit={onForm(onSubmit)}>
            <Upload
              value={image || user?.profile?.image}
              onDelete={() => {
                setImage(null);
              }}
              onSelectFile={onUpdateFile}
            />

            <NotSuperAdmin>
              <TextInput
                className={styles.field}
                type="password"
                placeholder="Account number"
                readonly
                icon="account"
                value={user?.account}
              />
            </NotSuperAdmin>
            {/* <TextInput
              className={styles.field}
              type="password"
              placeholder="Token"
              readonly
              icon="lightning"
              value={user?.token}
            /> */}
            <TextInput
              className={styles.field}
              placeholder="Your name"
              icon="info"
              error={form.errors.name?.message}
              {...form.name}
            />
            {user?.email && <>  <TextInput
              className={styles.field}
              placeholder="Your email"
              icon="mail"
              readonly
              error={form.errors.email?.message}
              {...form.email}
            />
              <TextInput
                className={styles.field}
                type="password"
                placeholder="Password"
                icon="lock"
                error={form.errors.password?.message}
                {...form.password}
              /></>}
            <div className={styles.field}>
              <Controller
                render={({ field }) => (
                  <TextEdit
                    name="description"
                    className={styles.textarea}
                    placeholder="What you would like to share?"
                    value={field?.value}
                    onChange={(v) => field.onChange(v)}

                  />
                )}
                control={form.control}
                name="description"
              />

            </div>
            <TextInput
              className={styles.field}
              type="hidden"
              placeholder="image"
              {...form.image}
            />
            <button className={cn("button", styles.button)}>Update</button>
          </form>
        </div>
        <IsAdmin>
          <NotSuperAdmin>
            <div className={styles.package}>
              <div className={styles.top}>
                <div className={cn("title-purple", styles.title)}>
                  {"Download mql"}
                </div>
              </div>

              <div className={styles.info}>
                <a
                  alt="mqlcopytrade"
                  href={URL.CdnUrl + data?.mql5}
                  target="_blink"
                  className={cn("button", styles.button)}
                >
                  Download MQL5 software
                </a>

                <a
                  alt="mqlcopytrade"
                  href={URL.CdnUrl + data?.mql4}
                  target="_blink"
                  className={cn("button", styles.button)}
                >
                  Download MQL4 software
                </a>
              </div>
            </div>
          </NotSuperAdmin>
        </IsAdmin>
      </div>
    </div>
  );
};

export default ProfileForm;
