import React, { useState } from "react";
import styles from "./Email.module.sass";
import cn from "classnames";
import Card from "components/Card";
import Row from "./Row";
const Email = ({
  className,
  isLoading,
  page,
  data,
  refetch,
  filters,
  changeFilter,
  changePage,
  changeCount,
}) => { 
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Email Setting"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Subject</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Button</div> 
            <div className={styles.col}>Action</div>
          </div>
          {data && data?.map((x, index) => (
            <Row refetch={refetch} item={x} key={index} />
          ))}
        </div>
       
      </Card>
    </>
  );
};

export default Email;
