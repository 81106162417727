import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";

const Item = ({ className, item }) => {
  const [visible, setVisible] = useState(true);

  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.head} onClick={() => setVisible(!visible)}>
        <div className={styles.title}>{item.title}</div>
        <Icon name="plus-circle" size="24" />
      </div>
      <div className={styles.body}>{JSON.stringify(item)}</div>
    </div>
  );
};

export default Item;
