import React, { useState } from "react";
import cn from "classnames";
import styles from "./ForgotPassword.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useParams } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useLoadingDispatch, useUserDispatch } from "../../context";
import {
  assets,
  setAuth,
  useForgotPassword,
  useGetAdminByAccount,
  useSignInForm,
} from "../../api";
import { Notify } from "../../components/Notify";
import Icon from "../../components/Icon";
import { useHistory } from "react-router";
import { Timer } from "components/Timer";

const ForgotPassword = ({ role }) => {
  const heightWindow = use100vh();
  const loading = useLoadingDispatch();
  const { username } = useParams();
  const { data } = useGetAdminByAccount(username);
  const _ = useForgotPassword();
  const [email, setEmail] = useState();
  const [send, setSend] = useState(false);
  const onForgetPassword = async () => {
    if (!email) {
      Notify.error("email is required!");
      return;
    }
    loading.show();
    const response = await _.mutateAsync({
      isAdmin: role === "master",
      email,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      setSend(true);
      Notify.success("Send email successfull!");
      return;
    }
  };
  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <div className={styles.close}>
          <div className={styles.info}>
            <Link className={styles.link} to="/">
              <Icon name="close" size={24} />
            </Link>
          </div>
        </div>
        <Link className={styles.logo} to="/">
          {data?.image ? (
            <Image
              className={styles.image}
              src={assets(data?.image)}
              srcDark={assets(data?.image)}
              alt="Log"
            />
          ) : (
            <Image
              className={styles.pic}
              srcDark={URL.CdnUrl+"/logo/logo-dark.svg"}
              src={URL.CdnUrl+"/logo/logo-light.svg"}
              alt="Log"
            />
          )}
        </Link>
        <div className={cn("h4", styles.title)}>
          Forgot password in {data?.name || ""}
        </div>
        {send ? (
          <>
            {" "}
            <div className={styles.body}>
              <div className={cn("text-green")}>
                SignUp successfull! <br />
                Please check your email inbox or spam
              </div>
              <div className={styles.subtitle}></div>
              <Link className={cn("button", styles.button)} to="/">
                Back
              </Link>
            </div>
          </>
        ) : (
          <div className={styles.body}>
            <div className={styles.subtitle}>Continue with email address</div>
            <TextInput
              className={styles.field}
              placeholder="Your email"
              icon="mail"
              onChange={(e) => setEmail(e.target.value)}
            />

            <button
              type="button"
              onClick={onForgetPassword}
              className={cn("button", styles.button)}
            >
              Forgot password
            </button>
            <div className={styles.info}>
              Do have an account?{" "}
              <Link className={styles.link} to="/sign-in">
                Sign in
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
