import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import SignIn from "./screens/SignIn";
import Admin from "./screens/Admin";
import SignUp from "./screens/SignUp";
import ForgotPassword from "./screens/ForgotPassword";
import Profile from "./screens/Profile";
import Verify from "./screens/Verify";
import Page404 from "./screens/404";
import Page403 from "./screens/403";
import { HelmetProvider } from "react-helmet-async";
import { QueryProvider, Role } from "./api";
import {
  LoadingProvider,
  TransactionProvider,
  UserProvider,
  WalletProvider,
} from "./context";
import { Loading } from "./components/Loader";
import TooltipGlodal from "./components/TooltipGlodal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Auth } from "./route/auth.route";
import Account from "./screens/Account";
import Ticket from "./screens/Ticket";
import Client from "./screens/Client";
import AdminPlan from "./screens/AdminPlan";
import Setting from "screens/Setting";
import About from "screens/About";
import Contact from "screens/Contact";
import Transaction from "screens/Transaction";
import Header from "screens/Header";
import Footer from "screens/Footer";
import ChargeWallet from "screens/ChargeWallet";
import Download from "screens/Download";
import Email from "screens/Email";
import Terms from "screens/Terms";
import Faq from "screens/FAQ";
import PaidLog from "screens/PaidLog";
import Seo from "components/Seo";
import Help from "screens/Help";
import Promote from "screens/Promote";
import UpgradeToPro from "screens/UpgradeToPro";
function App() {
  React.useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <HelmetProvider>
      <LoadingProvider>
        <WalletProvider>
          <UserProvider>
            <TransactionProvider>
              <QueryProvider>
              
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Page title="" seo="home">
                        <Home header="home" />
                      </Page>
                    )}
                  />
                  <Route
                    path="/test"
                    render={() => (
                      <Page title="" seo="test">
                        <Promote />
                        <UpgradeToPro />
                      </Page>
                    )}
                  />
                  <Route
                    path="/faq"
                    render={() => (
                      <Page title="" seo="faq">
                        <Faq />
                      </Page>
                    )}
                  />
                  <Route
                    path="/download"
                    render={() => <Download name="mql" />}
                  />
                  <Route
                    path="/master/mql/download"
                    render={() => <Download name="master-mql" />}
                  />
                  <Route
                    path="/helps"
                    render={() => (
                      <Page title="" seo="helps">
                        <Help />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    roles={[Role.SUPER_ADMIN]}
                    path="/masters/:id"
                    render={() => (
                      <Page title=""  seo="list masters">
                        <PaidLog />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    roles={[Role.SUPER_ADMIN]}
                    path="/masters"
                    render={() => (
                      <Page title="" seo="list masters">
                        <Admin />
                      </Page>
                    )}
                  />

                  <Auth
                    exact
                    path="/email"
                    render={() => (
                      <Page title="" seo="list email">
                        <Email />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/setting"
                    render={() => (
                      <Page title="" seo="settings">
                        <Setting />
                      </Page>
                    )}
                  />
                  <Route
                    exact
                    path="/terms"
                    render={() => (
                      <Page seo="terms">
                        <Terms />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/header"
                    render={() => (
                      <Page title="">
                        <Header />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/footer"
                    render={() => (
                      <Page title="">
                        <Footer />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/transaction"
                    render={() => (
                      <Page title="" seo="transaction">
                        <Transaction />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/accounts/:id"
                    render={({ match, ...props }) => {
                      console.log("props");
                      return (
                        <Page title="" seo="accounts">
                          <Account {...props} owner={match.params.id} />
                        </Page>
                      );
                    }}
                  />
                  <Auth
                    exact
                    path="/accounts"
                    render={() => (
                      <Page title="" seo="accounts">
                        <Account />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/ticket"
                    render={() => (
                      <Page title="" seo="ticket" >
                        <Ticket />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/users"
                    render={() => (
                      <Page title=""  seo="users">
                        <Client />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/account"
                    render={() => (
                      <Page title=""  seo="account">
                        <Account role="client" />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/plans"
                    render={() => (
                      <Page title=""  seo="plans">
                        <AdminPlan />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/profile"
                    render={() => (
                      <Page title=""  seo="profile">
                        <Profile />
                      </Page>
                    )}
                  />
                  <Auth
                    exact
                    path="/account-recharge"
                    render={() => (
                      <Page title=""  seo="recharge account">
                        <ChargeWallet />
                      </Page>
                    )}
                  />
                  <Route
                    exact
                    path="/sign-in"
                    render={() => <SignIn role="user" />}
                  />
                  <Route
                    exact
                    path="/:username/sign-in"
                    render={() => <SignIn role="master" />}
                  />
                  <Route
                    exact
                    path="/:username/forgot/password"
                    render={() => <ForgotPassword role="master" />}
                  />
                  <Route
                    exact
                    path="/forgot/password"
                    render={() => <ForgotPassword role="user" />}
                  />
                  <Route
                    exact
                    path="/sign-up"
                    render={() => <SignUp role="user" />}
                  />
                  <Route
                    exact
                    path="/verify/:token"
                    render={() => <Verify role="user" />}
                  />
                  <Route
                    exact
                    path="/verify/master/:token"
                    render={() => <Verify role="master" />}
                  />

                  <Route
                    exact
                    path="/about"
                    render={() => (
                      <Page  seo="about">
                        <About />
                      </Page>
                    )}
                  />
                  <Route
                    exact
                    path="/contact-us"
                    render={() => (
                      <Page  seo="contact us"> 
                        <Contact />
                      </Page>
                    )}
                  />
                  <Route
                    exact
                    path="/403"
                    render={() => (
                      <Page  seo="403">
                        <Page403 />
                      </Page>
                    )}
                  />
                  <Route
                    exact
                    path="*"
                    render={() => (
                      <Page  seo="404">
                        <Page404 />
                      </Page>
                    )}
                  />
                </Switch>
              </QueryProvider>
              <Loading />
              <TooltipGlodal />
              <ToastContainer />
            </TransactionProvider>
          </UserProvider>
        </WalletProvider>
      </LoadingProvider>
    </HelmetProvider>
  );
}

export default App;