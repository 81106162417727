import React, { useState } from "react";
import styles from "./ShowSetting.module.sass";
import cn from "classnames";
import Card from "components/Card";
import Icon from "components/Icon";
import Html from "components/Html";
import { IsAdmin, IsMaster, IsSuperAdmin, IsUser, NotAuth } from "context";
import { URL } from "api";

const ShowSetting = ({ title, className, data }) => {
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={title}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        {data && (
          <>
            <IsSuperAdmin>
              {data?.map((faq, index) => {
                return <Faq {...faq} key={index} />;
              })}
            </IsSuperAdmin>
            <IsMaster>
              {data?.map((faq, index) => {
                if (["all", "master"].includes(faq?.type || "all"))
                  return <Faq {...faq} key={index} />;
                return null;
              })}
            </IsMaster>
            <IsUser>
              {data?.map((faq, index) => {
                if (["all", "user"].includes(faq?.type || "all"))
                  return <Faq {...faq} key={index} />;
                return null;
              })}
            </IsUser>
            <NotAuth>
              {data?.map((faq, index) => {
                if (["all"].includes(faq?.type || "all"))
                  return <Faq {...faq} key={index} />;
                return null;
              })}
            </NotAuth>
          </>
        )}
      </Card>
    </>
  );
};
const Faq = ({ subject, video }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={cn(styles.faq, {
        [styles.active]: active,
      })}
    >
      <div
        className={styles.question}
        onClick={() =>
          setActive((s) => {
            return !s;
          })
        }
      >
        <span>{subject}</span>
        <span>
          <Icon name={active ? "arrow-up" : "arrow-down"} size={28} />
        </span>
      </div>
      <div className={styles.videos}>
        <video className={styles.video} controls>
          <source src={`${URL.CdnUrl}${video}`} />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
export default ShowSetting;
