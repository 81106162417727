import React, { useEffect, useState } from "react";
import styles from "./Ticket.module.sass";
import List from "./List";

import { useListTicket } from "api/useTicket";

const Ticket = (props) => {
  const data = useListTicket();
  return (
    <>
      <div className={styles.section}>
        <List {...data} role={props?.role} />
      </div>
    </>
  );
};

export default Ticket;
