import * as Types from "./types";

export const Reducer = (state, action) => {
  switch (action?.type) {
    
    case Types.CreateTransaction: {
      return {
        ...state,
        trx:action.value,
      };
    }
    case Types.MODAL: {
      return {
        ...state,
        trxModal:!!action.value,
      };
    }
    case Types.PAID: {
      return {
        ...state,
        paid: action?.value,
      };
    }
   
    default:
      throw new Error(`Transaction Unhandled action type: ${action.type}`);
  }
};
