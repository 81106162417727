import React from "react";
import styles from "../PayModal.module.sass";
import cn from "classnames";
import { Img, NoImage } from "components/Image";
import { EPlanType, EVolumeType, URL } from "api";
import VolumeValue from "screens/AdminPlan/List/Row/VolumeValue";

export default function CardPlan({ plan }) {
  return (
    <div className={cn(styles.card)}>
      <div className={styles.item}>
        <div className={styles.left}>
          <div className={styles.top}>
            <div className={styles.preview}>
              {plan.image ? (
                <Img src={`${URL.CdnUrl}${plan.image}`} alt="Product" />
              ) : (
                <NoImage />
              )}
            </div>
            <div>
              <div className={styles.win}>{"Price"}</div>
              {/* <div className={styles.win}>{EPlanType[plan.plan.type]}</div> */}
              {/* <div className={styles.win_number}>{plan.plan.value || ''} X</div> */}
              <div className={styles.win_number}>{plan.price || "Free"} $</div>
              <div className={styles.type}>
                {plan.testVerstion && (
                  <div className={cn("status-aptos", styles.status)}>Demo</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.title}>
              {plan.name}
              &nbsp; &nbsp;
            </div>
            <div className={styles.stage}>
              Plan({EPlanType[plan.plan.type]}) : {plan.plan.value} |  
              Volume(
                {EVolumeType[plan.volume.type]}) : <VolumeValue {...plan?.volume} />
            </div>
            <div
              className={styles.stage}
              dangerouslySetInnerHTML={{ __html: plan?.description?.replace(/\r?\n/ig,'<br />') }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
