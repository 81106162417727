import React, { useEffect, useState } from "react";
import styles from "./AdminPlan.module.sass";
import List from "./List";
import { useListAdminPlanClient } from "../../api";

const AdminPlan = (props) => {
  const  data = useListAdminPlanClient();
  return (
    <>
      <div className={styles.section}>
        
        <List {...data} />
      </div>
    </>
  );
};

export default AdminPlan;
