import React, { useEffect, useState } from "react";
import styles from "./Filter.module.sass";
import cn from "classnames";
import Card from "components/Card";
import { useLoadingDispatch } from "context";
import { useGetSetting, useUpdateSetting } from "api";
import { Notify } from "components/Notify";
import Checkbox from "components/Checkbox";
const Filter = ({ className }) => {
  const { data: filters, refetch: rfch } = useGetSetting("filter");
  const [filter, setFilter] = useState(false);
  const [box, setBox] = useState(false);
  const loading = useLoadingDispatch();
  const _ = useUpdateSetting();
  useEffect(() => {
    if (filters) {
      setFilter(filters?.filter);
      setBox(filters?.box);
    }
  }, [filters]);

  const onUpdateFilter = async () => {
    loading.show();

    const response = await _.mutateAsync({
      // ...filters,
      filter,
      box,
      name: "filter",
    });
    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      rfch && (await rfch());
    }
    loading.hide();
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Filter"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => onUpdateFilter()}
            >
              Update
            </button>
          </>
        }
      >
        <Checkbox
          value={filter}
          onChange={(e) => setFilter(e.target.checked)}
          content="Show filter in home"
        />
        <br />
        <Checkbox
          value={box}
          onChange={(e) => setBox(e.target.checked)}
          content="Show box in home"
        />
      </Card>
    </>
  );
};

export default Filter;
