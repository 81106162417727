import React from "react";
import styles from "./Mql.module.sass";
import cn from "classnames";
import Card from "components/Card";
import { useLoadingDispatch } from "context";
import UploadFile from "components/UploadFile";
import { useGetSetting, useUpdateSetting, useUploadMqlFile } from "api";
import { Notify } from "components/Notify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const MqlMaster = ({ className }) => {
  const TAG='master-mql'
  const { data, refetch } = useGetSetting(TAG);
  const loading = useLoadingDispatch();
  const _ = useUpdateSetting();
  const _file = useUploadMqlFile();
  const onUpdateSetting = async (__file, key) => {
    loading.show();
    const file = await _file.mutateAsync({ file: __file });
    if (file?.error) {
      loading.hide();
      return Notify.error(
        "Sorry, Mql size must be less than 8 MB and allowed formats are EXE"
      );
    }
    const response = await _.mutateAsync(
      data
        ? { ...data, [key]: file, name: TAG }
        : { [key]: file, name: TAG }
    );

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  // name.ex4
  // name.ex5
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Upload MQL master file and instruction.allowed format: zip , rar"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Link to="/master/mql/download" className={cn("button-small", styles.button)}>
              Download page
            </Link>
          </>
        }
      >
        <div className={styles.row}>Master Mql 5 File</div>
        <UploadFile
          value={data?.mql5}
          onDelete={() => {
            //form.setValue("image", null);
          }}
          onSelectFile={(f) => onUpdateSetting(f, "mql5")}
        />
        <div className={styles.row}>Master Mql 4 File</div>
        <UploadFile
          value={data?.mql4}
          onDelete={() => {
            //form.setValue("image", null);
          }}
          onSelectFile={(f) => onUpdateSetting(f, "mql4")}
        />
      </Card>
    </>
  );
};

export default MqlMaster;
