import React, { useEffect, useState } from "react";
import styles from "./Admin.module.sass";
import List from "./List";
import { useListAdmin } from "../../api/useAdmin";

const Admin = (props) => {
  const  data = useListAdmin();
  return (
    <>
      <div className={styles.section}>
        <List {...data} />
      </div>
    </>
  );
};

export default Admin;
