import React, { useEffect, useState } from "react";
import styles from "./Footer.module.sass";

import { useGetSetting, useUpdateSetting } from "api";
import EditSetting from "./EditSetting";
import { useLoadingDispatch, useUserState } from "context";
import { Notify } from "components/Notify";
import ShowSetting from "./ShowSetting";
const Footer = () => {
  const { data, refetch } = useGetSetting("footer");
  const loading = useLoadingDispatch();
  const state = useUserState();
  const _ = useUpdateSetting();
  const [txt, setTxt] = useState("");
  useEffect(() => {
    if (data?.txt) {
      setTxt(data?.txt);
    }
  }, [data?.txt]);
  const onSave = async (txt) => {
    loading.show();
    const response = await _.mutateAsync({ txt, name: "footer" });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      // refetch && (await refetch());
    }
    loading.hide();
  };
  return (
    <>
      <div className={styles.section}>
        {state.isSuperAdmin ? (
          <>
            <EditSetting
              {...{ txt, setTxt }}
              title={"Footer"}
              onSave={onSave}
              data={data}
            />
            <br />
            <ShowSetting title={"Preview"} data={{ txt }} />
          </>
        ) : (
          <ShowSetting title={"Footer"} data={data} />
        )}
      </div>
    </>
  );
};

export default Footer;
