import React, { useEffect, useState } from "react";
import styles from "./EditSetting.module.sass";
import cn from "classnames";
import Card from "components/Card";
import TextEdit from "components/Textarea";
import { TextInput } from "components/TextInput";
import Icon from "components/Icon";
import { Notify } from "components/Notify";
import Select, { SelectType } from "components/Select";

const EditSetting = ({
  title,
  faq,
  className,
  onSave,
  onDownIndex,
  onUpIndex,
  onDelFaq,
}) => {
  const [type, setType] = useState("all");
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  useEffect(() => {
    if (faq) {
      if (faq?.answer) {
        setAnswer(faq.answer);
      }
      if (faq?.question) {
        setQuestion(faq.question);
      }
      if (faq?.type) {
        setType(faq.type);
      }
    }
  }, [faq]);
  const resetForm = () => {
    setAnswer("");
    setQuestion("");
    setType("all");
  };
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={title}
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            {onDelFaq && (
              <button
                className={cn("button-red", styles.buttonDown)}
                onDoubleClick={onDelFaq}
                onClick={() =>
                  Notify.warning("please DoubleClick for delete item")
                }
              >
                <Icon name="trash" />
              </button>
            )}
            <button
              className={cn("button-small", styles.button)}
              onClick={() => onSave({ answer, question, type }, resetForm)}
            >
              {faq ? "Edit" : "Add"} question
            </button>

            {onUpIndex && (
              <button
                className={cn("button-purple", styles.buttonDown)}
                onClick={onUpIndex}
              >
                <Icon name="arrow-top" />
              </button>
            )}
            {onDownIndex && (
              <button
                className={cn("button-purple", styles.buttonDown)}
                onClick={onDownIndex}
              >
                <Icon name="arrow-bottom" />
              </button>
            )}
          </>
        }
      >
        <div className={styles.table}>
          <SelectType
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={type}
            onChange={(v) => setType(v)}
          />
          <br />
          <TextInput
            value={question}
            placeholder="Please enter question"
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            icon={"message"}
          />
          <br />
          <TextEdit
            label={"Answer"}
            value={answer}
            placeholder="Please enter answer"
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default EditSetting;
