import React, { useState } from "react";
import styles from "./ListTransaction.module.sass";
import cn from "classnames";
import Card from "components/Card";
import Dropdown from "components/Dropdown";
import { Loader } from "components/Loader";
import Modal from "components/Modal";
import Filters from "components/Filters";
import Row from "./Row";
import TextInput from "components/TextInput";
import { IsAdmin, IsSuperAdmin, useUserDispatch } from "context";
import Checkbox from "components/Checkbox";
const intervals = ["15 in page", "25 in page", "50 in page"];
const ListTransaction = ({
  className,
  isLoading,
  page,
  data,
  refetch,
  filters,
  changeFilter,
  changePage,
  changeCount,
}) => {
  const [count, setCount] = useState(intervals[0]);
  const onChangeCount = (count) => {
    setCount(count);
    changeCount(count.replace(" in page", ""));
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Transaction"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Checkbox
              content="Paid"
              className={styles.query}
              onChange={(e) => {
                changeFilter("paid")(`${e.target.checked}`);
              }}
            />
            <TextInput
              className={styles.query}
              type="text"
              onChange={(e) => changeFilter("query")(e.target.value)}
              placeholder="search name"
            />
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={count}
              setValue={onChangeCount}
              options={intervals}
              small
            />
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row} id="sticky">
            <div className={styles.col}>Id</div>
            <div className={styles.col}>Status</div>
            <IsAdmin>
              <>
                <div className={styles.col}>Client</div>
                <IsSuperAdmin>
                  <div className={styles.col}> Admin</div>
                </IsSuperAdmin>
              </>
            </IsAdmin>
            <div className={styles.col}>Hash</div>
            <div className={styles.col}>From Address</div>
            <div className={styles.col}>To Address</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Paid</div>
            <div className={styles.col}>CratedAt</div>
            <div className={styles.col}></div>
          </div>
          {data?.map((x, index) => (
            <Row refetch={refetch} item={x} key={index} />
          ))}
        </div>
        <div className={styles.foot}>
          <button
            onClick={() => changePage(page + 1)}
            className={cn("button-stroke button-small", styles.button)}
          >
            {isLoading && <Loader className={styles.loader} />}
            <span>Load more</span>
          </button>
        </div>
      </Card>
    </>
  );
};

export default ListTransaction;
