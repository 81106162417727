import React, { useEffect, useState } from "react";
import styles from "./Client.module.sass";
import List from "./List";
import { useListAccountClient } from "../../api";

const Account = ({ owner, ...props }) => {
  const data = useListAccountClient(
    props?.role,
    owner ? 1000 : 15,
    owner ? { owner } : {}
  );
  return (
    <>
      <div className={styles.section}>
        <List
          {...data}
          role={props?.role}
          owner={owner ? data?.data?.at(0) : null}
        />
      </div>
    </>
  );
};

export default Account;
