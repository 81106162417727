/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";

import Control from "./Control";
import classNames from "classnames";

import { Department, Priority, TicketStatus } from "enum";
import { IsSuperAdmin } from "context";

const Row = ({ expired, item, role, user, refetch }) => {
  return (
    <div
      className={classNames(styles.row, {
        "suspend-light": TicketStatus.Close === item?.status,
      })}
    >
      <div className={styles.col}>
        <div className={styles.label}>Subject</div>
        <div className={styles.col}>
          <div className={styles.post} style={{ paddingLeft: "8px" }}>
            {item?.subject || " "}{" "}
          </div>
        </div>
      </div>
      <IsSuperAdmin>
        <div className={styles.col}>
          <div className={styles.label}>Owner</div>
          <div className={styles.col}>
            <div className={styles.post} style={{ paddingLeft: "8px" }}>
              {item?.owner?.name || " "}&nbsp;
              {item.role === "3" ? (
                <span className="status status-green">Admin</span>
              ) : (
                <span className="status status-blue">Client</span>
              )}
            </div>
          </div>
        </div>
      </IsSuperAdmin>
      <div className={styles.col}>
        <div className={styles.label}>Replies</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.replies} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Status</div>
        <div className={styles.col}>
          <div className={styles.post}>{TicketStatus[item?.status || 1]} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Department</div>
        <div className={styles.col}>
          <div className={styles.post}>{Department[item?.department]} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Priority</div>
        <div className={styles.col}>
          <div className={styles.post}>{Priority[item?.priority]} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Create Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.createdAt?.substr(0, 16)?.replace("T", " ")}
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control
              role={role}
              refetch={refetch}
              className={styles.control}
              item={item}
              user={user?.user}
              isSuperAdmin={user?.isSuperAdmin}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
