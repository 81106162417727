import { useMemo } from "react";
import { useMutation } from "react-query";
import { URL } from "./url.enum";
import { usePagesWithLoadeMore } from "./usePages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getAuthHeader } from "./useAuth";
import { http } from "./http.config";
import { EVolumeType } from "./volume.enum";
import { EPlanType } from "./plan.enum";

const useCAWithAdminApi = () => {
  return useMutation(async (data) => {
    try {
      console.table(data);
      const sendData = {
        accountNumber: data.accountNumber,
        options: {
          contact: data?.contact,
          name: data?.name,
        },
        plan: {
          type: EPlanType[data?.plan.type?.toUpperCase()],
          value: data?.plan.value,
        },
        volume: {
          type: EVolumeType[data?.volume.type?.toUpperCase()],
          value: data?.volume.value,
        },
        testVerstion: data?.testVerstion,
      };
      console.table(sendData);
      debugger;
      const res = await http.post(
        URL.CLIENT_ACCOUNT_WITH_ADMIN,
        sendData,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useCreateAccountWithAdminForm = () => {
  const schema = yup
    .object({
      accountNumber: yup
        .number()
        .typeError("Account number must be a number")
        .required(),
      name: yup.string().max(150).optional(), //it is options
      contact: yup.string().max(200).optional(), //it is options
      plan: yup
        .object({
          type: yup.mixed().oneOf(EPlanType.keys()).required(),
          value: yup
            .number()
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .typeError("Plan type must be a number")
            .min(0)
            .optional(),
        })
        .required(),
      volume: yup
        .object({
          type: yup.mixed().oneOf(EVolumeType.keys()).required(),
          value: yup.mixed().required(),
        })
        .required(),
    })
    .required();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const _ = useCAWithAdminApi();
  return useMemo(() => {
    return [
      {
        register,
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useCreateAccount = () => {
  return useMutation(async (data) => {
    try {
      const sendData = {
        accountNumber: data.accountNumber,
        lastPlan: data.plan,
      };
      const header = getAuthHeader();
      const res = await http.post(URL.CLIENT_ACCOUNT, sendData, header);
      return res?.data;
    } catch (error) {
      debugger;
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useUpdateAccountByClient = () => {
  return useMutation(async (data) => {
    try {
      const sendData = {};
      if (data.accountNumber) {
        sendData["accountNumber"] = data.accountNumber;
      }
      if ("autoRenewal" in data) {
        sendData["autoRenewal"] = data.autoRenewal;
      }
      const header = getAuthHeader();
      const res = await http.put(
        URL.CLIENT_ACCOUNT + "/" + data?._id,
        sendData,
        header
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};

const useEAWithAdminApi = () => {
  return useMutation(async ({ role, ...data }) => {
    try {
      const sendData = {};
      if (data.accountNumber) {
        sendData["accountNumber"] = data.accountNumber;
      }
      if ("testVerstion" in data) {
        sendData["testVerstion"] = data.testVerstion;
      }
      if ("status" in data) {
        sendData["status"] = data.status;
      }
      if ("contact" in data || "name" in data) {
        sendData["options"] = {
          contact: data?.contact,
          name: data?.name,
        };
      }
      if ("plan" in data) {
        sendData["plan"] = {
          type: EPlanType[data?.plan.type?.toUpperCase()],
          value: data?.plan.value,
        };
      }
      if ("volume" in data) {
        sendData["volume"] = {
          type: EVolumeType.get(data?.volume.type),
          value: data?.volume.value,
        };
      }
      const path = role
        ? URL.CLIENT_ACCOUNT + "/" + data?._id
        : URL.CLIENT_ACCOUNT_WITH_ADMIN + "/" + data?._id;
      const res = await http.put(path, sendData, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useDeleteClient = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.delete(
        URL.CLIENT_ACCOUNT_WITH_ADMIN + "/" + data?._id,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useEditAccountWithAdminForm = (defaultValues) => {
  const schema = yup
    .object({
      accountNumber: yup
        .number()
        .typeError("Account number must be a number")
        .required(),
      name: yup.string().max(150).optional(), //it is options
      contact: yup.string().max(200).optional(), //it is options
      plan: yup
        .object({
          type: yup.mixed().oneOf(EPlanType.keys()).required(),
          value: yup
            .number()
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .typeError("Plan type must be a number")
            .min(0)
            .optional(),
        })
        .required(),
      volume: yup
        .object({
          type: yup.mixed().oneOf(EVolumeType.keys()).required(),
          value: yup.mixed().required(),
        })
        .required(),
    })
    .required();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: defaultValues?.accountNumber,
      name: defaultValues?.options?.name || "",
      contact: defaultValues?.options?.contact || "",
      status: defaultValues?.status,
      plan: {
        type: EPlanType[defaultValues?.plan?.type],
        value: defaultValues?.plan?.value,
      },
      volume: {
        type: EVolumeType[defaultValues?.volume?.type],
        value: defaultValues?.volume?.value,
      },
    },
  });
  const _ = useEAWithAdminApi();
  return useMemo(() => {
    return [
      {
        register,
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useListAccountClient = (
  role = "admin",
  count = 15,
  filter = {}
) => {
  return usePagesWithLoadeMore(
    role === "client" ? URL.CLIENT_ACCOUNT : URL.CLIENT_ACCOUNT_WITH_ADMIN,
    true,
    0,
    count,
    filter
  );
};
/**
 * @param adminplan _id
 **/
export const useBuyPlanByClientBalance = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.CLIENT_ACCOUNT + "/plan/" + data?._id,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};

/**
 * @param account id
 **/
export const useExtensionPlanClient = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.patch(
        URL.CLIENT_ACCOUNT + "/extension/" + data?._id,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
