import { useUserState } from "./context";

export const NotAuth = ({ children }) => {
  const state = useUserState();
  if (state?.isAuth) {
    return <></>;
  }
  return <>{children}</>;
};
export const IsAdmin = ({ children }) => {
  const state = useUserState();
  if (!state) {
    return <></>;
  } else if (!state?.isAuth) {
    return <></>;
  } else if (!state?.isAdmin) {
    return <></>;
  }
  return <>{children}</>;
};
export const IsMaster = ({ children }) => {
  const state = useUserState();
  if (!state) {
    return <></>;
  } else if (!state?.isAuth) {
    return <></>;
  } else if (!state?.isAdmin) {
    return <></>;
  } else if (state?.isSuperAdmin) {
    return <></>;
  }
  return <>{children}</>;
};
export const IsUser = ({ children }) => {
  const state = useUserState();
  if (!state) {
    return <></>;
  } else if (!state?.isAuth) {
    return <></>;
  } else if (!state?.isUser) {
    return <></>;
  }
  return <>{children}</>;
};
export const CanAdmin = ({ children, can }) => {
  const state = useUserState();
  let is = false;
  if ((state?.menu === "admin" || state?.menu === "adminPart") && can) {
    if (state.user?.access && can in state.user.access) {
      is = state.user.access[can];
    } else {
      is = false;
    }
  }
  if (!is) {
    return <></>;
  }
  return <>{children}</>;
};
export const NotCanAdmin = ({ children, can }) => {
  const state = useUserState();
  let is = false;
  if ((state?.menu === "admin" || state?.menu === "adminPart") && can) {
    if (state.user?.access && can in state.user.access) {
      is = state.user.access[can];
    } else {
      is = false;
    }
  }
  if (is) {
    return <></>;
  }
  return <>{children}</>;
};
export const IsSuperAdmin = ({ children, path }) => {
  const state = useUserState();
  if (!state) {
    return <>{path || ""}</>;
  } else if (!state?.isAuth) {
    return <>{path || ""}</>;
  } else if (!state?.isAdmin) {
    return <>{path || ""}</>;
  } else if (!state?.isSuperAdmin) {
    return <>{path || ""}</>;
  }
  return <>{children}</>;
};
export const NotSuperAdmin = ({ children, path }) => {
  const state = useUserState();
  if (!state) {
    return <>{path || ""}</>;
  } else if (!state?.isAuth) {
    return <>{path || ""}</>;
  } else if (state?.isSuperAdmin) {
    return <>{path || ""}</>;
  }
  return <>{children}</>;
};
