import React from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import Seo from "../../components/Seo";
import { URL, useGetSetting } from "api";

const Download = ({ name }) => {
  const { data, refetch } = useGetSetting(name);
  return (
    <>
      <div className={styles.section}>
        <div className={cn(styles.card)} title="">
          <div className={styles.info}>
            <div className={styles.h2}>Mql 5</div>
            <br />
            <div className={styles.h1}> File related to MQL5 software </div>
            <div className={styles.h3}>
              To download the file, please click on the link below
            </div>
            <br />
            <div>
              <a
                alt="mqlcopytrade"
                href={URL.CdnUrl + data?.mql5}
                target="_blink"
                className={cn("status-dark", styles.status)}
              >
                Download now
              </a>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.h2}>Mql 4</div>
            <br />
            <div className={styles.h1}> File related to MQL4 software </div>
            <div className={styles.h3}>
              To download the file, please click on the link below
            </div>
            <br />
            <div>
              <a
                alt="mqlcopytrade"
                href={URL.CdnUrl + data?.mql4}
                target="_blink"
                className={cn("status-dark", styles.status)}
              >
                Download now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Download;
