/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./Row.module.sass";
import Icon from "../../../../components/Icon";
import { URL } from "../../../../api";
import Control from "./Control";
import classNames from "classnames";
import { starText } from "common";
import { Tooltip } from "recharts";

const Row = ({ item, refetch }) => {
  const name=`${item?.name}(${item?.account})`
  return (
    <div
      className={classNames(styles.row, {
        suspend: item?.status === -1,
      })}
    >
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            {item?.profile?.image ? (
              <img src={`${URL.CdnUrl}${item?.profile?.image}`} alt="Image" />
            ) : (
              <img src={`noimage.png`} alt="Image" />
            )}
            <div className={styles.icon}>
              <Icon name={"image"} />
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.post} style={{flexDirection:"column"}}>
            {name ? (
              <div data-tip={name} data-place={"top"}>
                {starText(name, 2, 12)}
              </div>
            ) : (
              ""
            )}
               <div style={{opacity:"0.5"}}>
               {item?.createdAt?.substr(0, 16)?.replace("T", " ")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Email</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.email ? (
              <span data-tip={item?.email} data-place={"top"}>
                {starText(item?.email, 2, 2)}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Plan #</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.stats?.planTotal || "0 #"}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Client</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.stats?.userTotal || "0 #"}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Master share </div>
        <div className={styles.col}>
          <div className={styles.post}>
            {(item?.stats?.traderShare || 0) - (item?.stats?.paidTotal || 0)} $
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Super Admin share </div>
        <div className={styles.col}>
          <div className={styles.post}>
            {(item?.stats?.transactionTotal || 0) -
              (item?.stats?.traderShare || 0)}{" "}
            $
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Total revenue </div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.stats?.transactionTotal || "0 "} $
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Commission super admin </div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.commission || "0"} %</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Total paid master</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.stats?.paidTotal || "0"} $</div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control refetch={refetch} className={styles.control} item={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
