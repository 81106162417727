import React, { useState } from "react";
import styles from "./Wallet.module.sass";
import cn from "classnames";
import Card from "components/Card";
import Dropdown from "components/Dropdown";
import { Loader } from "components/Loader";
import Modal from "components/Modal";
import Filters from "components/Filters";
import Row from "./Row";
import NewAdmin from "./New";
import TextInput from "components/TextInput";
import { useUserDispatch } from "context";
const Wallet = ({
  className,
  isLoading,
  page,
  data,
  refetch,
  filters,
  changeFilter,
  changePage,
  changeCount,
}) => { 
  const [visibleModal, setVisibleModal] = useState(false);
  const action = useUserDispatch();
 
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Wallet"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <TextInput
              className={styles.query}
              type="text"
              onChange={(e) => changeFilter("query")(e.target.value)}
              placeholder="search name"
            /> 
            {action.canSuperAdmin() && (
              <button
                className={cn("button-small", styles.button)}
                onClick={() => setVisibleModal(true)}
              >
                New Wallet
              </button>
            )}
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Address</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Priority</div>
            <div className={styles.col}>Free</div>
            <div className={styles.col}>Created</div>
            <div className={styles.col}>Action</div>
          </div>
          {data?.map((x, index) => (
            <Row refetch={refetch} item={x} key={index} />
          ))}
        </div>
        <div className={styles.foot}>
          <button
            onClick={() => changePage(page + 1)}
            className={cn("button-stroke button-small", styles.button)}
          >
            {isLoading && <Loader className={styles.loader} />}
            <span>Load more</span>
          </button>
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewAdmin onClose={() => setVisibleModal(false)} refetch={refetch} />
      </Modal>
    </>
  );
};

export default Wallet;
