/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./Row.module.sass";
import Icon from "../../../../components/Icon";
import { EPlanType, EVolumeType, Role, URL } from "../../../../api";
import Control from "./Control";
import classNames from "classnames";
import Image, { Img } from "components/Image";

const Row = ({ expired, item, role, user, refetch }) => {
  return (
    <div className={classNames(styles.row, { expired: item?.status === -1 })}>
      <div className={styles.col}>
        <div className={styles.label}>Name</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.name || " "}</div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Email</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.email || " "} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Status</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.status === 1 && "Active"}
            {item?.status === -1 && "Deleted"}
            {`${item?.status}` === "0" && "Pending"}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Balance</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.balance || 0} $</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Account</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.account || 0} #</div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Create Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.createdAt?.substr(0, 16)?.replace("T", " ")}
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control
              role={role}
              refetch={refetch}
              className={styles.control}
              item={item}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
