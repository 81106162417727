/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import classNames from "classnames";
import { EmailType } from "enum";

const Row = ({ expired, item, role, user, refetch }) => {
  const [show, setShow] = useState(false);
  return (
    <div className={classNames(styles.row, { expired })}>
      <div className={styles.col}>
        <div className={styles.label}>Subject</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.subject || " "} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Type</div>
        <div className={styles.col}>
          <div className={styles.post}>{EmailType[item?.type]} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Message</div>
        <div className={styles.col}>
          <div className={styles.post} style={{ maxWidth: "40%" }}>
            {item?.message?.length > 20 ? (
              <>
                {show ? (
                  <> 
                  <span dangerouslySetInnerHTML={{__html: item?.message}}></span>
                    <span
                      className="link text-green"
                      onClick={() => setShow(!show)}
                    >
                      ...less
                    </span>
                  </>
                ) : (
                  <>
                    {item?.message?.substr(0, 20) + "..."}
                    <span
                      className="link text-blue"
                      onClick={() => setShow(!show)}
                    >
                      .more
                    </span>
                  </>
                )}
              </>
            ) : (
              <>{item?.message} </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Count</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.count || "0 #"} </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Create Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.createdAt?.substr(0, 16)?.replace("T", " ")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
