import React, { useEffect, useState } from "react";
import styles from "./Ticket.module.sass";
import List from "./List";

import { useListEmail } from "api/useEmail";

const Email = (props) => {
  const data = useListEmail();
  return (
    <>
      <div className={styles.section}>
        <List {...data} role={props?.role} />
      </div>
    </>
  );
};

export default Email;
