import { BaseUrlEnv, CdnUrlEnv } from "../env";
const isDev = process.env.NODE_ENV === "development";
export const URL = Object.freeze({
  // PROJECT_ID: "1f79224db7428cf21be372689f6d6ed4",
  BaseUrl: BaseUrlEnv,
  CdnUrl: CdnUrlEnv,
  CLIENT: "/client",
  CLIENT_PROFILE: "/client/profile",
  CLIENT_LOGIN: "/client/login",
  CLIENT_VERIFY: "/client/verify",
  CLIENT_FORGET_PASSWORD: "/client/forget/password",
  CLIENT_CHANGE_PASSWORD: "/client/change/password",

  MASTER: "/master", //POST,GET
  MASTER_PAID: "/master/paid", //POST,GET
  MASTER_ACCOUNT: "/master/account", //POST,GET
  MASTER_PROFILE: "/master/profile", //GET,PUT
  MASTER_LOGIN: "/master/login",
  MASTER_VERIFY: "/master/verify",
  MASTER_FORGET_PASSWORD: "/master/forget/password",
  MASTER_CHANGE_PASSWORD: "/master/change/password",
  // MASTER_VERIFY: "/master/change/password",
  UPLOAD_IMAGE: "/v1/upload",
  UPLOAD_LOGO_DARK: "/v1/upload/logo/dark",
  UPLOAD_LOGO_LIGHT: "/v1/upload/logo/light",
  UPLOAD_MQL: "/v1/upload/mql",
  UPLOAD_VIDEO: "/v1/upload/video",
  
  CLIENT_ACCOUNT: "/client-account", //POST
  CLIENT_ACCOUNT_WITH_ADMIN: "/client-account/admin", //GET,POST,PUT,DELETE
  MY_ADMIN_PLAN: "/admin-plan/me", //GET
  ADMIN_PLAN: "/admin-plan", //GET(Client),POST,PUT,DELETE
  WALLET: "/wallet", //GET(Client),POST,PUT,DELETE
  MY_WALLET: "/wallet/me", //GET
  SETTING: "/setting",
  TRANSACTION: "/transaction",
  EMAIL: "/emails",
  TICKET: "/ticket",
  TICKET_REPLY: "/ticket/reply",
});
