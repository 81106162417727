import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Modal from "../../../../../components/Modal";
import Icon from "../../../../../components/Icon";
import EditAdmin from "../../Edit";
import DelAdmin from "../../Del";
import Tooltip from "components/Tooltip";
import TooltipGlodal from "components/TooltipGlodal";
import { useUserDispatch } from "context";

const Control = ({ role, className, refetch, item }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const action = useUserDispatch();
  const actions = [];
  if (role !== "client") {
    if (action?.canAdmin()) {
      if (!item?.lastPlan || action?.canAdmin("volumeUpdate")) {
        actions.push({
          icon: "edit",
          tooltip: "Edit account",
          action: () => setVisibleModal("edit"),
        });
      }
    } else {
      actions.push({
        icon: "edit",
        tooltip: "Edit account",
        action: () => setVisibleModal("edit"),
      });
    }
    if (action?.canAdmin()) {
      if (action?.canAdmin("clientRemove"))
        actions.push({
          icon: "trash",
          tooltip: "Delete account",
          action: () => setDelModal("delete"),
        });
    } else {
      actions.push({
        icon: "trash",
        tooltip: "Delete account",
        action: () => setDelModal("delete"),
      });
    }
  } else {
    if (["0", "1"].includes(`${item?.status}`)) {
      actions.push({
        icon: "edit",
        tooltip: "Edit account",
        action: () => setVisibleModal("edit"),
      });
      if (item?.lastPlan && !item.lastPlan?.testVerstion)
        actions.push({
          icon: "repeat",
          tooltip: "Extened Plan",
          action: () => setVisibleModal("wallet"),
        });
    } else {
      if (item?.lastPlan && !item.lastPlan?.testVerstion)
        actions.push({
          icon: "repeat",
          tooltip: "Extened Plan",
          action: () => setVisibleModal("wallet"),
        });
    }
  }

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button
            type="button"
            className={styles.button}
            key={index}
            onClick={x.action}
          >
            <Tooltip
              className={styles.tooltip}
              title={x.tooltip}
              icon={x.icon}
              place={"top"}
            />
          </button>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <>
          {item && (
            <EditAdmin
              item={item}
              role={role}
              type={visibleModal}
              onClose={() => setVisibleModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <Modal visible={delModal} onClose={() => setDelModal(false)}>
        <>
          {item && (
            <DelAdmin
              item={item}
              onClose={() => setDelModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <TooltipGlodal />
    </>
  );
};

export default Control;
