import React from "react";
import cn from "classnames";
import styles from "./Renewal.module.sass";

const Renewal = ({ className, toggle, onToggle }) => {
  return (
    <label className={cn(className, styles.renewal)}>
      <input
        className={styles.input}
        checked={toggle}
        onChange={onToggle}
        type="checkbox"
      />
      <span className={styles.inner}>
        <span className={styles.box}>Yes</span>
        <span className={styles.box}>No</span>
      </span>
    </label>
  );
};

export default Renewal;
