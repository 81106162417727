import { URL } from "api";
import React from "react";
import { Helmet } from "react-helmet-async";

const Seo = ({ title, description }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="icon" href={URL.CdnUrl+"/logo/logo-dark.svg"}/>
      {/* twitter */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="" /> */}
      <meta name="twitter:title" content={title} />
      {/* <meta name="twitter:creator" content="@magicmint" />
      <meta name="twitter:site" content="@magicmint" /> */}
      <meta name="twitter:description" content={description} />
      {/* facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="magicmint.io" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* <meta
        property="og:image"
        content=""
      /> */}
    </Helmet>
  );
};

export default Seo;
