import { Notify } from "components/Notify";
import { useEffect, useState } from "react";

/**
 *
 * @param {*} text
 * @param {*} l Left
 * @param {*} r Right
 * @returns
 */
export const starText = (text, l, r, star = 3) => {
  const reg = new RegExp(`(.{${l}}).+(.{${r}})`, "ig");
  let txt = "$1";
  for (let i = 1; i <= star; i++) {
    txt += "*";

  }
  txt += "$2"
  return text.replace(reg, txt);
};
export const copy = (value) => {
  try {
    window.navigator.clipboard.writeText(value);
    Notify.success(`${value} add to clipboard`);
  } catch (error) {
    unsecuredCopyToClipboard(value);
  }
};
export const useForm = (item) => {
  const [form, setForm] = useState({});
  useEffect(() => {
    if (item) {
      setForm(item);
    }
    return () => {
      setForm({});
    };
  }, [item]);

  return [
    {
      ...form,
      data: form,
      register: (key, defaultValue) => {
        let value =
          key in form && form[key] && typeof form[key] === "string"
            ? form[key]
            : "";
        if (defaultValue && !(key in form)) {
          value = defaultValue;
        }
        return {
          value: value,
        };
      },
    },
    (key) => (e) => {
      if (typeof e === "string") return setForm({ ...form, [key]: e });
      if ("target" in e) return setForm({ ...form, [key]: e?.target?.value });
      else return setForm({ ...form, [key]: e });
    },
    (data) => setForm(data || {}),
  ];
};
function unsecuredCopyToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
    Notify.success(`${text} add to clipboard`);
  } catch (err) {
    Notify.error("Sorry. copy is incorrect");
  }
  document.body.removeChild(textArea);
}
const about = {
  title: "About",
  icon: "about",
  url: "/about",
};
const help = {
  title: "Help",
  icon: "help",
  url: "/helps",
};
const contact = {
  title: "Contact us",
  icon: "contact",
  url: "/contact-us",
};
const terms = {
  title: "Terms",
  icon: "news",
  url: "/terms",
};
const faq = {
  title: "FAQ",
  icon: "faq",
  url: "/faq",
};
const navigation = [
  {
    title: "Home",
    icon: "home",
    url: "/",
  },
  about,
  contact,
  terms,
  faq,
  help,
];
const ticket = {
  title: "Ticket",
  icon: "messages",
  url: "/ticket",
};
const transaction = {
  title: "Transaction",
  icon: "transaction",
  url: "/transaction",
};
export const Menus = {
  navigation,
  admin: [
    {
      title: "Home",
      icon: "home",
      url: "/",
    },
    {
      title: "Profile",
      icon: "profile",
      url: "/profile",
    },
    {
      title: "Plan",
      icon: "plans",
      url: "/plans",
    },
    {
      title: "Accounts",
      icon: "user",
      url: "/accounts",
    },
    transaction,
    about,
    contact,
    terms,
    faq,
    help,
  ],
  adminPart: [
    {
      title: "Home",
      icon: "home",
      url: "/",
    },
    {
      title: "Profile",
      icon: "profile",
      url: "/profile",
    },
    {
      title: "Plan",
      icon: "plans",
      url: "/plans",
    },
    {
      title: "Accounts",
      icon: "user",
      url: "/accounts",
    },
    ticket,
    transaction,
    about,
    contact,
    terms,
    faq,
    help,
  ],
  user: [
    {
      title: "Home",
      icon: "home",
      url: "/",
    },
    {
      title: "Profile",
      icon: "profile",
      url: "/profile",
    },
    {
      title: "Account",
      icon: "account",
      url: "/account",
    },
    ticket,
    {
      title: "Account recharge",
      icon: "wallet",
      iconProps: {
        viewBox: "0 0 24 24",
        size: 24,
      },
      url: "/account-recharge",
    },

    transaction,

    about,
    contact,
    terms,
    faq,
    help,
  ],
  super_admin: [
    {
      title: "Home",
      icon: "home",
      url: "/",
    },
    {
      title: "Profile",
      icon: "profile",
      url: "/profile",
    },
    {
      title: "Master",
      icon: "masters",
      url: "/masters",
    },
    {
      title: "Plan",
      icon: "plans",
      url: "/plans",
    },

    {
      title: "Account",
      icon: "account",
      url: "/accounts",
    },
    {
      title: "Users",
      icon: "user",
      url: "/users",
    },
    ticket,
    transaction,
    {
      title: "Setting",
      icon: "setting",
      url: "/setting",
    },
    {
      title: "Email",
      icon: "mail",
      url: "/email",
    },
    about,
    contact,
    {
      title: "Header",
      icon: "header",
      url: "/header",
      iconProps: {
        size: 20,
      },
    },
    {
      title: "Footer",
      icon: "footer",
      url: "/footer",
      iconProps: {
        size: 20,
      },
    },
    terms,
    faq,
    help,
  ],
};
