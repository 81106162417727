import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Verify.module.sass";
import Card from "../../components/Card";
import { Link, useHistory, useParams } from "react-router-dom";
import Seo from "../../components/Seo";
import { setAuth, useVerifyAccount } from "../../api";
import { useLoadingDispatch, useUserDispatch } from "../../context";
import { Notify } from "../../components/Notify";

const Verify = ({ role }) => {
  const { token } = useParams();
  const _ = useVerifyAccount();
  const loading = useLoadingDispatch();
  const user = useUserDispatch();
  const history = useHistory();
  const onVerify = async () => {
    loading.show();
    const response = await _.mutateAsync({
      token,
      role,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      user.onSignIn(response?.data);
      setAuth(response?.data);
      Notify.success("Verify successfull!");
      history.push(`/profile`);
      return;
    }
  };
  useEffect(() => {
    if (token && role) onVerify();
  }, [token, role]);

  return (
    <>
      
      <div className={styles.section}>Loading for Verify account</div>
    </>
  );
};

export default Verify;
