import React from "react";
import styles from "../PayModal.module.sass";
import cn from "classnames";

import { Img, NoImage } from "components/Image";
import {
  EPlanType,
  EVolumeType,
  URL,
  useCreateAccount,
  useUpdateAccountByClient,
} from "api";
import { useLoadingDispatch, useUserState } from "context";
import Icon from "components/Icon";
import { TextInput } from "components/TextInput";
import { Notify } from "components/Notify";
export function FreePrice({ plan, paid, onClose, loading }) {
    const [accountNumber, setAccount] = React.useState();
    const _ = useCreateAccount();
    const _up = useUpdateAccountByClient();
  
    const buyNow = async () => {
      loading.show();
      const response = await _.mutateAsync({
        plan: plan?._id,
        accountNumber,
      });
      loading.hide();
      if (response?.error) {
        return Notify.error(
          typeof response?.message === "string"
            ? response?.message
            : response?.message?.pop()
        );
      } else {
        Notify.success("Add account successfull!");
        return onClose();
      }
    };
    const buyNowUp = async () => {
      loading.show();
      const response = await _up.mutateAsync({
        _id: paid?._id,
        accountNumber,
      });
      loading.hide();
      if (response?.error) {
        return Notify.error(
          typeof response?.message === "string"
            ? response?.message
            : response?.message?.pop()
        );
      } else {
        Notify.success("Add account successfull!");
        return onClose();
      }
    };
    return (
      <>
        <TextInput
          className={styles.field}
          name="account"
          type="number"
          placeholder="Your account number"
          required
          icon={{ name: "wallet", viewBox: "0 0 24 24", size: 24 }}
          onChange={(e) => setAccount(e.target.value)}
        />
        <div className={styles.foot}>
          <button
            onClick={paid ? buyNowUp : buyNow}
            className={cn("button", "button-green")}
          >
            <span className="white">{paid ? "Add account" : "Buy Now"}</span>
            <Icon name="wallet" size="24" viewBox="0 0 24 24" />
          </button>
        </div>
      </>
    );
  }