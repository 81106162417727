export const DEFAULT_MAIN_CHAINS = [
  // mainnets
  "eip155:1",
  "eip155:10",
  "eip155:100",
  "eip155:137",
  "eip155:42161",
  "eip155:42220",
  "cosmos:cosmoshub-4",
  "solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
  "polkadot:91b171bb158e2d3848fa23a9f1c25182",
  "mvx:1",
  "tron:0x2b6653dc",
  "tezos:mainnet",
];

export const DEFAULT_TEST_CHAINS = [
  // testnets
  "eip155:5",
  "eip155:420",
  "eip155:80001",
  "eip155:421611",
  "eip155:44787",
  "solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
  "polkadot:e143f23803ac50e8f6f8e62695d1ce9e",
  "near:testnet",
  "mvx:D",
  "tron:0xcd8690dc",
  "tezos:testnet",
];

export const DEFAULT_CHAINS = [...DEFAULT_MAIN_CHAINS, ...DEFAULT_TEST_CHAINS];

export const DEFAULT_PROJECT_ID =
  process.env.REACT_APP_PROJECT_ID || "517cb86def334258adb9d0e2a8d5dee3";
export const DEFAULT_RELAY_URL =
  process.env.REACT_APP_RELAY_URL || "wss://relay.walletconnect.com";

export const DEFAULT_LOGGER = "debug";

export const DEFAULT_APP_METADATA = {
  name: "Test",
  description: "Mql copytrade",
  url: "http://localhost:3000",
  // url: "https://walletconnect.com/",
  // url: 'https://app.justlend.org/',
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

/**
 * EIP155
 */
export const DEFAULT_EIP155_METHODS = {
  ETH_SEND_TRANSACTION: "eth_sendTransaction",
  ETH_SIGN_TRANSACTION: "eth_signTransaction",
  ETH_SIGN: "eth_sign",
  PERSONAL_SIGN: "personal_sign",
  ETH_SIGN_TYPED_DATA: "eth_signTypedData",
};

export const DEFAULT_EIP_155_EVENTS = {
  ETH_CHAIN_CHANGED: "chainChanged",
  ETH_ACCOUNTS_CHANGED: "accountsChanged",
};

/**
 * COSMOS
 */
export const DEFAULT_COSMOS_METHODS = {
  COSMOS_SIGN_DIRECT: "cosmos_signDirect",
  COSMOS_SIGN_AMINO: "cosmos_signAmino",
};

export const DEFAULT_COSMOS_EVENTS = {};

/**
 * SOLANA
 */
export const DEFAULT_SOLANA_METHODS = {
  SOL_SIGN_TRANSACTION: "solana_signTransaction",
  SOL_SIGN_MESSAGE: "solana_signMessage",
};

export const DEFAULT_SOLANA_EVENTS = {};

/**
 * POLKADOT
 */
export const DEFAULT_POLKADOT_METHODS = {
  POLKADOT_SIGN_TRANSACTION: "polkadot_signTransaction",
  POLKADOT_SIGN_MESSAGE: "polkadot_signMessage",
};

export const DEFAULT_POLKADOT_EVENTS = {};

/**
 * NEAR
 */
export const DEFAULT_NEAR_METHODS = {
  NEAR_SIGN_IN: "near_signIn",
  NEAR_SIGN_OUT: "near_signOut",
  NEAR_GET_ACCOUNTS: "near_getAccounts",
  NEAR_SIGN_AND_SEND_TRANSACTION: "near_signAndSendTransaction",
  NEAR_SIGN_AND_SEND_TRANSACTIONS: "near_signAndSendTransactions",
};

export const DEFAULT_NEAR_EVENTS = {};

/**
 * MULTIVERSX
 */
export const DEFAULT_MULTIVERSX_METHODS = {
  MULTIVERSX_SIGN_TRANSACTION: "mvx_signTransaction",
  MULTIVERSX_SIGN_TRANSACTIONS: "mvx_signTransactions",
  MULTIVERSX_SIGN_MESSAGE: "mvx_signMessage",
  MULTIVERSX_SIGN_LOGIN_TOKEN: "mvx_signLoginToken",
  MULTIVERSX_SIGN_NATIVE_AUTH_TOKEN: "mvx_signNativeAuthToken",
};

export const DEFAULT_MULTIVERSX_EVENTS = {};

/**
 * TRON
 */
export const DEFAULT_TRON_METHODS = {
  TRON_SIGN_TRANSACTION: "tron_signTransaction",
  TRON_SIGN_MESSAGE: "tron_signMessage",
};

export const DEFAULT_TRON_EVENTS = {};

/**
 * TEZOS
 */
export const DEFAULT_TEZOS_METHODS = {
  TEZOS_GET_ACCOUNTS: "tezos_getAccounts",
  TEZOS_SEND: "tezos_send",
  TEZOS_SIGN: "tezos_sign",
};

export const DEFAULT_TEZOS_EVENTS = {};

export const DEFAULT_GITHUB_REPO_URL =
  "https://github.com/WalletConnect/web-examples/tree/main/dapps/react-dapp-v2";

export const REGIONALIZED_RELAYER_ENDPOINTS = [
  {
    value: DEFAULT_RELAY_URL,
    label: "Default",
  },

  {
    value: "wss://us-east-1.relay.walletconnect.com",
    label: "US",
  },
  {
    value: "wss://eu-central-1.relay.walletconnect.com",
    label: "EU",
  },
  {
    value: "wss://ap-southeast-1.relay.walletconnect.com",
    label: "Asia Pacific",
  },
];
