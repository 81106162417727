import React, { useState } from "react";
import styles from "./New.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useLoadingDispatch } from "../../../../context";
import { Notify } from "../../../../components/Notify";
import { useCreateAccountWithAdminForm } from "../../../../api";
import TextInput from "../../../../components/TextInput";
import { Controller } from "react-hook-form";
import { Plan } from "../../../../components/Plan";
import { Volume } from "../../../../components/Volume";
import Checkbox from "components/Checkbox";

const New = ({ refetch, onClose }) => {
  const loading = useLoadingDispatch();
  const [testVerstion, setTestVerstion] = useState(false);
  const [form, onForm, _] = useCreateAccountWithAdminForm({});
  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync({ ...data, testVerstion });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };

  const onCheckbox = async (e) => {
    const checked = e.target.checked;
    setTestVerstion(checked);
  };
  return (
    <form onSubmit={onForm(onSubmit)}>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>New Client</div>

        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Account number"
            icon="ticket"
            type="number"
            error={form.errors.accountNumber?.message}
            {...form?.register("accountNumber")}
          />
        </div>

        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Client name"
            icon="info-stroke"
            error={form.errors.name?.message}
            {...form.register("name")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Client email or phone"
            icon="share"
            error={form.errors.contact?.message}
            {...form.register("contact")}
          />
        </div>
        <div className={styles.field}>
          <Checkbox onChange={onCheckbox} content="Test Verstion" />
        </div>
        <Controller
          render={({ field }) => <Plan {...field} error={form?.errors?.plan} />}
          control={form.control}
          name="plan"
          // defaultValue={0}
        />
        <Controller
          render={({ field }) => (
            <Volume {...field} error={form?.errors?.volume} />
          )}
          control={form.control}
          name="volume"
          // defaultValue={0}
        />

        <div className={styles.foot}>
          <button className={cn("button", styles.button)}>
            <span className="white">Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default New;
