import React, { useEffect, useState } from "react";
import styles from "./Profile.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { useUserDispatch, useUserState } from "../../context";
import Overview from "./Overview";
import ProfileForm from "./ProfileForm";
import UserProfileForm from "./UserProfileForm";
import { useMasterProfile } from "../../api/useAdmin";
import { useClientProfile } from "api";

const Profile = (props) => {
  const state = useUserState();
  return <>{state?.isUser ? <ProfileUser /> : <ProfileAdmin />}</>;
};
const ProfileAdmin = (props) => {
  const state = useUserState();
  const action = useUserDispatch();
  const { data, isLoading } = useMasterProfile();
  useEffect(() => {
    if (data && "error" in data && !data.error) {
      action.onProfile(data?.data);
    }
  }, [data]);
  return (
    <>
      {/* <h1>Welcome {state?.user?.name}.</h1> */}
      <div className={styles.section}>
        <Overview
          className={styles.card}
          state={state}
          stats={
            data?.data?.stats || {
              adminTotal: 0,
              transactionTotal: 0,
              userTotal: 0,
            }
          }
        />
        {data && <ProfileForm user={data?.data} />}
      </div>
    </>
  );
};
const ProfileUser = (props) => {
  const state = useUserState();
  const action = useUserDispatch();

  const { data, isLoading } = useClientProfile();
  useEffect(() => {
    if (data && "error" in data && !data.error) {
      action.onProfile(data?.data);
    }
  }, [data]);
  return (
    <>
      {/* <h1>Welcome {state?.user?.name}.</h1> */}
      <div className={styles.section}>
        {data && (
          <UserProfileForm onProfile={action.onProfile} user={data?.data} />
        )}
      </div>
    </>
  );
};

export default Profile;
