import React from "react";
import {
  Hydrate,
  QueryClient,
  useIsFetching,
  QueryClientProvider,
} from "react-query";

export function QueryProvider({ children, ...pageProps }) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retryDelay: (attemptIndex) =>
              Math.min(1000 * 2 ** attemptIndex, 30000),
          },
        },
      })
  );
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Hydrate state={pageProps?.dehydratedState}> */}
      {children}
      {/* </Hydrate> */}
    </QueryClientProvider>
  );
}
export function useLoading() {
  return useIsFetching();
}
export function parseError(response) {
  return typeof response?.message === "string"
    ? response?.message
    : response?.message?.pop();
}
