import { useMutation } from "react-query";
import { URL } from "./url.enum";
import { useFindOne, usePagesWithLoadeMore } from "./usePages";
import { http } from "./http.config";
import { getAuthHeader } from "./useAuth";
export function useCreateTicket() {
  return useMutation(async (data) => {
    try {
      const res = await http.post(`${URL.TICKET}`, data, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
}
export function useUpdateTicket() {
  return useMutation(async ({ uuid, ...data }) => {
    try {
      const res = await http.put(
        `${URL.TICKET}/${uuid}`,
        data,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
}
export function useCloseTicket() {
  return useMutation(async ({ uuid, ...data }) => {
    try {
      const res = await http.delete(`${URL.TICKET}/${uuid}`, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
}
export const useShowTicket = (ticket) => {
  return useFindOne(URL.TICKET, ticket, true);
};
export const useListTicket = () => {
  return usePagesWithLoadeMore(URL.TICKET, true, 0, 15, {});
};
export const useListTicketReply = (ticket) => {
  return usePagesWithLoadeMore(
    URL.TICKET_REPLY + "/" + ticket,
    true,
    0,
    15,
    {}
  );
};
export function useCreateTicketReply() {
  return useMutation(async ({ ticket, ...data }) => {
    try {
      const res = await http.post(
        `${URL.TICKET_REPLY}/${ticket}`,
        data,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
}
export function useDeleteTicketReply() {
  return useMutation(async ({ uuid, ticket }) => {
    try {
      const res = await http.delete(
        `${URL.TICKET_REPLY}/${ticket}/${uuid}`,
        {},
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
}
