import { useContext, createContext } from "react";

export const UserStateContext = createContext();
export const UserDispatchContext = createContext();

/**
 * User State Managment
 * @returns state
 */
export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
};

/**
 * User Action Managment
 * @returns Actions
 */
export const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
};
