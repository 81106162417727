import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Modal from "components/Modal";
import EditAdmin from "../../Edit";
import DelAdmin from "../../Del";
import { lockedMaster, setAuth, useLoginAdminWithId } from "api";
import { useLoadingDispatch, useUserDispatch } from "context";
import { Notify } from "components/Notify";
import { useHistory } from "react-router-dom";
import Tooltip from "components/Tooltip";
import TooltipGlodal from "components/TooltipGlodal";
import { copy } from "common";

const Control = ({ className, refetch, item }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const loading = useLoadingDispatch();
  const user = useUserDispatch();
  const history = useHistory();
  const _ = useLoginAdminWithId();

  const actions = [
    {
      icon: "transaction",
      tooltip: "Master paid log",
      action: () => history.push("/masters/" + item?._id),
    },
    {
      icon: "edit",
      tooltip: "Master edit profile",
      action: () => setVisibleModal("edit"),
    },
    {
      icon: "lock",
      tooltip: "Master login account",
      action: async () => {
        loading.show();
        const response = await _.mutateAsync(item?._id);
        if (response?.error) {
          loading.hide();
          return Notify.error(response?.message);
        } else {
          const data = { isMasterLocked: true, ...response?.data };
          user.onSignIn(data);
          lockedMaster();
          setAuth(data);
          Notify.success("SignIn successfull!");
          window.location.href=`${window.location.origin}/profile`;
          setTimeout(()=>loading.hide(),3000)
          
          return;
        }
      },
    },
    {
      icon: "copy",
      tooltip: "Copy login URL",
      action: () => {
        const value = `${window.location.origin}/${item?.account}/sign-in`;
        copy(value);
      },
    },
    {
      icon: "trash",
      tooltip: item.status === -1 ? "Recycle master" : "Delete master",
      action: () => setDelModal("delete"),
    },
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Tooltip
              className={styles.tooltip}
              title={x.tooltip}
              icon={x.icon}
              place={"top"}
            />
          </button>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <>
          {item && (
            <EditAdmin
              item={{...item,description: item?.profile?.description}}
              onClose={() => setVisibleModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
      <Modal visible={delModal} onClose={() => setDelModal(false)}>
        <>
          {item && (
            <DelAdmin
              item={item}
              onClose={() => setDelModal(false)}
              refetch={refetch}
            />
          )}
        </>
      </Modal>
     
      <TooltipGlodal />
    </>
  );
};

export default Control;
