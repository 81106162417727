import { Notify } from "components/Notify";
import * as Types from "./types";
import {
  ProposalNamespace,
  onSignClient,
  web3Modal,
} from "components/Wallet/Web3Modal";

export const handleCreateClient = (dispatch) => async () => {
  try {
    const signClient = await onSignClient();
    const sessions = signClient.find({
      requiredNamespaces: ProposalNamespace,
    });
    dispatch(Types.SignClient, signClient);
    subscribeToEvents(dispatch, signClient);
    if (sessions && sessions.length > 0) {
      const session = sessions[0];
      dispatch(Types.Connect, true);
      dispatch(Types.SetSession, session);
      dispatch(
        Types.SetAccount,
        session.namespaces.eip155.accounts[0].slice(9)
      );
    }
  } catch (e) {
    // Notify.error("Sorry. Failed to connect to walletconnect");
    console.log(e);
  }
};
export const handleConnect = (dispatch, state) => async () => {
  if (!state?.signClient) return Notify.error("Sorry.Client is not set");
  try {
    const { uri, approval } = await state.signClient.connect({
      pairingTopic: state?.pairing?.topic,
      requiredNamespaces: ProposalNamespace,
    });
    debugger;
    if (uri) {
      web3Modal.openModal({ uri, standaloneChains: "tron:0x2b6653dc" });
      debugger
      const session = await approval();
      debugger
      dispatch(Types.SetSession, session);
      dispatch(
        Types.SetAccount,
        session.namespaces.eip155.accounts[0].slice(9)
      );
      dispatch(Types.Connect, true);
      web3Modal.closeModal();
      Notify.success("You are connected to walletconnect");
    }
  } catch (e) {
    // Notify.error("Sorry. Failed to connect to walletconnect");
    console.log(e);
  }
};

export const handleDisconnect = (dispatch, state) => async () => {
  try {
    await state.signClient.disconnect({
      topic: state.session.topic,
      message: "User disconnected",
      code: 6000,
    });
    dispatch(Types.Connect, false);
    handleReset(dispatch)();
  } catch (e) {
    Notify.error("Sorry. Failed to disconnect to walletconnect");
    console.log(e);
  }
};
/**
 * Reset Account
 * @param {*} dispatch
 * @returns
 */
export const handleReset = (dispatch) => () => {
  dispatch(Types.SetSession, null);
  dispatch(Types.SetAccount, null);
};
export async function subscribeToEvents(dispatch, signClient) {
  if (!signClient)
    throw Error("Unable to subscribe to events. Client does not exist.");
  try {
    signClient.on("session_delete", () => {
      Notify.info("The user has disconnected the session from their wallet.");
      handleReset(dispatch);
    });
    //add other events
  } catch (e) {
    console.log(e);
  }
}
export const handleSendTransaction = (dispatch, state) => async () => {
  //   console.log('account',account)
  //   if (!account.length) throw Error("No account found");
  //   try {
  //     const tx = {
  //       from: account,
  //       // to: "0xBDE1EAE59cE082505bB73fedBa56252b1b9C60Ce",
  //       // from: "0xbB7e4Ac1c027B5B1106b26087Ea427e3acA27bbC",
  //       to: "TFp2c1sKVj8PFVQCoJzChshCqQFWrY8MX5",
  //       data: "0x",
  //       gasPrice: "0x029104e28c",
  //       gasLimit: "0x5208",
  //       value: "0x00",
  //       chainId: 1,
  //     };
  //     console.log('tx',tx)
  //     const result = await signClient.request({
  //       topic: session.topic,
  //       chainId: "eip155:1",
  //       request: {
  //         method: "eth_sendTransaction",
  //         params: [tx],
  //       },
  //     });
  //     console.log(result)
  //     setTxnUrl(result);
  //   } catch (e) {
  //     console.log(e);
  //   }
};
