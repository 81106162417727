import React from "react";
import styles from "./New.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { useLoadingDispatch } from "context";
import { Notify } from "components/Notify";
import { parseError, useCreateWalletForm } from "api";
import TextInput from "components/TextInput";
import { Controller } from "react-hook-form";
import Textarea from "components/Textarea";
import Select from "components/Select";

const New = ({ refetch, onClose }) => {
  const loading = useLoadingDispatch();
  const [form, onForm, _] = useCreateWalletForm();
  const onSubmit = async (data) => {
    loading.show();
    const response = await _.mutateAsync({ ...data, active: true });
    loading.hide();
    if (response?.error) {
      return Notify.error(parseError(response));
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success("Create successfull!");
      return;
    }
  };

  return (
    <form onSubmit={onForm(onSubmit)}>
      <div className={styles.post}>
        <div className={cn("title-green", styles.title)}>New Wallet</div>

        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Name"
            // icon="info-stroke"
            error={form.errors.name?.message}
            {...form.register("name")}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Address"
            // icon="ticket"
            type="string"
            error={form.errors.address?.message}
            {...form?.register("address")}
          />
        </div>
        <div className={styles.field}>
          <Controller
            render={({ field }) => (
              <Select
                option="name"
                id="id"
                error={form.errors.type?.message}
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={field?.value}
                setValue={(v) => field.onChange(v)}
                options={[
                  {
                    id: 1,
                    name: "USDT",
                  },
                ]}
              />
            )}
            control={form.control}
            name="type"
            defaultValue={1}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.field}
            placeholder="Priority"
            // icon="ticket"
            type="number"
            error={form.errors.priority?.message}
            {...form?.register("priority")}
          />
        </div>
        <div className={styles.field}>
          <Controller
            render={({ field }) => (
              <Textarea
                placeholder="What you would like to share?"
                onChange={(v) => {
                  console.log("log", v.target.value);
                  field.onChange(v.target.value);
                }}
              />
            )}
            control={form.control}
            name="description"
            // defaultValue={0}
          />
        </div>
        <div className={styles.foot}>
          <button className={cn("button", styles.button)}>
            <span className="white">Save</span>
            <Icon name="arrow-right" size="24" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default New;
