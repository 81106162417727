import React from "react";
import styles from "./Del.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import { useLoadingDispatch } from "../../../../context";
import { Notify } from "../../../../components/Notify";
import { useDeleteAdmin } from "../../../../api/useAdmin";

const Del = ({ refetch, onClose, item }) => {
  const loading = useLoadingDispatch();
  const _ = useDeleteAdmin();

  const onSubmit = async () => {
    loading.show();
    const response = await _.mutateAsync({
      _id: item?._id,
      status: item.status === -1 ? 1 : -1,
    });
    loading.hide();
    if (response?.error) {
      return Notify.error(response?.message);
    } else {
      refetch && refetch();
      onClose && onClose();
      Notify.success(`${item.status === -1 ? "Recycle" : "Delete"} successfull!`);
      return;
    }
  };

  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>
        {item.status === -1 ? "Recycle" : "Delete"} Master
      </div>

      <div className={styles.field}>
        Do you want {item.status === -1 ? "recycle" : "delete"} {item?.name}{" "}
        master?
      </div>

      <div className={styles.foot}>
        <button onClick={onSubmit} className={cn("button", styles.button)}>
          <span>Yes</span>
          <Icon name="trash" size="24" />
        </button>
        <button onClick={onClose} className={cn("button", styles.button)}>
          <span>No</span>
          <Icon name="close" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Del;
