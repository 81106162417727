import React, { useCallback, useEffect } from "react";
import styles from "./CardQr.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import { TextInput } from "components/TextInput";
import { QRCodeSVG } from "qrcode.react";
import { Timer } from "components/Timer";
import { useExtendTransaction } from "api";
import Modal from "components/Modal";
import Del from "../Del";

export function CardQr({ item, expired, onClose, onDenyTrx, onExtend, plan }) {
  const [time, setTime] = React.useState(100);
  const addExpired = useCallback(async () => {
    await onExtend();
  });

  return (
    <>
      <div className={cn(styles.card)}>
        <div className={styles.info}>
          <Icon name="info" size="24" />
          <span>
            Only USDT (TRC-20 token) can be sent to this TRON address or your
            payment will be lost.
          </span>
        </div>
      </div>
      <div className={cn(styles.card)}>
        <div className={styles.win}> Send {plan.price} USDT</div>
        <div className={styles.qrcode}>
          <div className={styles.preview}>
            <QRCodeSVG value={item?.address} />
          </div>
        </div>
        <div className={styles.type} style={{ width: "100%", marginTop: 20 }}>
          <TextInput
            copy
            className={styles.field}
            name="account"
            type="text"
            placeholder="Your account address"
            required
            value={item?.address}
            icon={{ name: "wallet", viewBox: "0 0 24 24", size: 24 }}
            // onChange={(e) => setAccount(e.target.value)}
          />
        </div>
        <div className={styles.await}>
          <div className={styles.text}>
            <span>Awaiting payment after &nbsp;</span>{" "}
            <span className={styles.time}>
              <Timer
                all={30 * 60 * 1000}
                expired={expired}
                onEndTime={(t) => {
                  //setTime(0);
                }}
                onChangeTime={(t) => {
                  setTime(t);
                }}
              />
            </span>
            &nbsp;
            {time < 20 && (
              <span
                onClick={addExpired}
                className={styles.btn}
                style={{
                  padding: "4px",
                  marginTop: "-4px",
                }}
              >
                add 5min
              </span>
            )}
            {/* <span onClick={addExpired} className={styles.btn}>
                add 5min
              </span> */}
          </div>
          <div className={styles.line} style={{ width: `${time}%` }}></div>
        </div>
      </div>
      <div className={styles.foot}>
        <button
          onClick={() => {
            onClose();
            onDenyTrx(true);
          }}
          className={cn("button", styles.button_red)}
        >
          {/* <Icon name="arrow-left" size="24" /> */}
          <span className="white">Cancel </span>
        </button>
      </div>
      
    </>
  );
}
