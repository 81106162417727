/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import styles from "./Row.module.sass";
import Icon from "../../../../components/Icon";
import { EPlanType, EVolumeType, EWalletType, URL } from "../../../../api";
import Control from "./Control";
import classNames from "classnames";

const Row = ({ item, refetch }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.label}>Name</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.name || " "}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Address</div>
        <div className={styles.col}>
          <div className={styles.post}>{`${item?.address.substr(
            0,
            8
          )}...${item?.address.substr(item?.address.length - 8, 8)}`}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Type</div>
        <div className={styles.col}>
          <div className={styles.post}>{EWalletType[item?.type]}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Priority</div>
        <div className={styles.col}>
          <div className={styles.post}>{item?.priority || "0"}</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Free</div>
        <div
          className={classNames(
            styles.col,
            item?.active ? {} : styles.lock
          )}
        >
          <div className={styles.post}>
            {item?.active ? "Free" :  "Locked"}
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Create Date</div>
        <div className={styles.col}>
          <div className={styles.post}>
            {item?.createdAt?.substr(0, 16)?.replace("T", " ")}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Action</div>
        <div className={styles.col}>
          <div className={styles.post}>
            <Control refetch={refetch} className={styles.control} item={item} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
