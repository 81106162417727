import React from "react";
import styles from "./Edit.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import Status from "../Row/Status";
import { IsAdmin, IsSuperAdmin } from "context";
import Copy from "components/Copy";
import { copy } from "common";
import PlanCard from "screens/Home/AdminCard/PlanCard";
import CardPlan from "screens/Home/AdminCard/PayModal/component/CardPlan";

const Edit = ({ refetch, onClose, item }) => {
  return (
    <div className={styles.post}>
      <div className={cn("title-green", styles.title)}>
        Transaction information
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Id</div>
        <Copy className={styles.post} isIcon={item?.authority || false}>
          {item?.authority || "---"}
        </Copy>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Status</div>
        <div className={styles.post}>
          <Status value={item?.status} />
        </div>
      </div>
      <IsAdmin>
        <>
          <div className={styles.col}>
            <div className={styles.label}>Client</div>
            <div className={styles.post}>
              {item?.client?.name || "---"}
              &nbsp; ({item?.client?.email || "---"})
            </div>
          </div>
          <IsSuperAdmin>
            <div className={styles.col}>
              <div className={styles.label}>Admin</div>

              <div className={styles.post}>
                {item?.admin ? (
                  <>
                    {item?.admin?.name || "---"} &nbsp; (
                    {item?.admin?.email || "---"})
                  </>
                ) : (
                  <>---</>
                )}
              </div>
            </div>
          </IsSuperAdmin>
        </>
      </IsAdmin>

      <div className={styles.col}>
        <div className={styles.label}>Hash</div>
        <Copy className={styles.post} isIcon={item?.details?.hash || false}>
          {item?.details?.hash || "---"}
        </Copy>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>From Address</div>
        <Copy className={styles.post} isIcon={item?.details?.from || false}>
          {item?.details?.from || "---"}
        </Copy>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>To Address</div>
        <Copy className={styles.post} isIcon={item?.details?.to || false}>
          {item?.details?.to || "---"}
        </Copy>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Amount</div>
        <div className={styles.col}>{item?.amount} $</div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Paid</div>
        <div className={styles.post}>
          {item?.paid ? (
            <span className="status text-green">Paid</span>
          ) : (
            <span className="status text-red">noPaid</span>
          )}
        </div>
      </div>

      <div className={styles.col}>
        <div className={styles.label}>Date (GMT)</div>
        <div className={styles.post}>
          {`${item?.createdAt}`.replace(/(T)|(Z)/gi, " ").substring(0, 19)}{" "}
        </div>
      </div>

      {item?.plan && <CardPlan plan={item?.plan} />}

      <div className={styles.foot}>
        <button onClick={onClose} className={cn("button", styles.button)}>
          <span className="white">Close</span>
          <Icon name="close" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Edit;
