export * from "./Blockchain";
export const EmailType = Object.freeze({
  All: 1,
  Admin: 2,
  Clients: 3,
  ClientPaid: 4,
  ClientGeneral: 5,
  1: "All",
  2: "Admin",
  3: "Clients",
  5: "ClientPaid",
  4: "ClientGeneral",
  values: () => [1, 2, 3, 4],
  keys: () => ["All", "Admin", "Clients", "ClientPaid", "ClientGeneral"],
  get: (i) => ["All", "Admin", "Clients", "ClientPaid", "ClientGeneral"][i - 1],
  checked: (a, b) => `${a}` === `${b}`,
});
export const Department = Object.freeze({
  General: 1,
  Finance: 2,
  Feedback: 3,
  1: "General",
  2: "Finance",
  3: "Feedback",
  values: () => [1, 2, 3],
  keys: () => ["General", "Finance", "Feedback"],
  get: (i) => ["General", "Finance", "Feedback"][i - 1],
  checked: (a, b) => `${a}` === `${b}`,
});
export const TicketStatus = Object.freeze({
  Close: 1,
  Open: 2,
  ReOpen: 3,
  1: "Close",
  2: "Open",
  3: "ReOpen",
  values: () => [1, 2, 3],
  keys: () => ["Close", "Open", "ReOpen"],
  get: (i) => ["Close", "Open", "ReOpen"][i - 1],
  checked: (a, b) => `${a}` === `${b}`,
});
export const Priority = Object.freeze({
  Normal: 1,
  NonSignificant: 2,
  Important: 3,
  1: "Normal",
  2: "NonSignificant",
  3: "Important",
  values: () => [1, 2, 3],
  keys: () => ["Normal", "NonSignificant", "Important"],
  get: (i) => ["Normal", "NonSignificant", "Important"][i - 1],
  checked: (a, b) => `${a}` === `${b}`,
});

export const TransactionStatus = Object.freeze({
  EXPIRED: "-2",
  CANCEL: "-1",
  PENDING: "0",
  ACTIVE: "1",
  PAID: "2",
  PAID_WITH_ADMIN: "3",
  PARTIAL_PAYMENT: "4",//PartialPayment
});