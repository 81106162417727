import React, { useEffect, useState } from "react";
import styles from "./Terms.module.sass";

import { useGetSetting, useUpdateSetting } from "api";
import EditSetting from "./EditSetting";
import { useLoadingDispatch, useUserState } from "context";
import { Notify } from "components/Notify";
import ShowSetting from "./ShowSetting";
const FAQ = "Faq";
const Faq = () => {
  const { data, refetch } = useGetSetting(FAQ);
  const loading = useLoadingDispatch();
  const state = useUserState();
  const _ = useUpdateSetting();
  const [faq, setFAQ] = useState([]);
  useEffect(() => {
    if (data?.faq) {
      setFAQ(data?.faq);
    }
  }, [data?.faq]);
  const onAddFaq = async ({ answer, question ,type}, resetForm) => {
    if (!question && !answer) {
      return Notify.error(`answer and question is requierd`);
    }
    loading.show();
    const response = await _.mutateAsync({
      faq: [{ answer, question,type }, ...faq],
      name: FAQ,
    });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Add successfull!");
      refetch && (await refetch());
      resetForm && resetForm();
    }
    loading.hide();
  };
  const onEditFaq =
    (index) =>
    async ({ answer, question,type }) => {
      if (!question && !answer) {
        return Notify.error(`answer and question is requierd`);
      }
      loading.show();
      let f = faq;
      f[index] = { answer, question ,type};
      const response = await _.mutateAsync({
        faq: f,
        name: FAQ,
      });

      if (response?.error) {
        Notify.error(response?.message);
      } else {
        Notify.success("Update successfull!");
        refetch && (await refetch());
      }
      loading.hide();
    };
  const onDelFaq = (index) => async () => {
    loading.show();
    let f = faq.filter((i, ind) => `${ind}` !== `${index}`);

    const response = await _.mutateAsync({
      faq: f,
      name: FAQ,
    });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  const onUpIndex = (index) => async () => {
    loading.show();
    let f = faq;
    const a = f[index];
    const b = f[index - 1];
    f[index] = b;
    f[index - 1] = a;
    const response = await _.mutateAsync({
      faq: f,
      name: FAQ,
    });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  const onDownIndex = (index) => async () => {
    loading.show();
    let f = faq;
    const a = f[index];
    const b = f[index + 1];
    f[index] = b;
    f[index + 1] = a;
    const response = await _.mutateAsync({
      faq: f,
      name: FAQ,
    });

    if (response?.error) {
      Notify.error(response?.message);
    } else {
      Notify.success("Update successfull!");
      refetch && (await refetch());
    }
    loading.hide();
  };
  return (
    <>
      <div className={styles.section}>
        {state.isSuperAdmin ? (
          <>
            <EditSetting title={"FAQ"} onSave={onAddFaq} />
            <br />
            {faq && (
              <>
                {faq.map((f, index) => {
                  return (
                    <EditSetting
                      key={index}
                      faq={f}
                      title={index + 1 + "-" + f.question}
                      onSave={onEditFaq(index)}
                      onDelFaq={onDelFaq(index)}
                      onDownIndex={
                        index + 1 !== faq.length && onDownIndex(index)
                      }
                      onUpIndex={index !== 0 && onUpIndex(index)}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          <ShowSetting title={"FAQ"} data={faq} />
        )}
      </div>
    </>
  );
};

export default Faq;
