import { useMemo } from "react";
import { useMutation } from "react-query";
import { URL } from "./url.enum";
import { usePagesWithLoadeMore } from "./usePages";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getAuthHeader } from "./useAuth";
import { http } from "./http.config";
import { EVolumeType } from "./volume.enum";
import { EPlanType } from "./plan.enum";

export const EWalletType = Object.freeze({
  USDT: 1,
  1: "Tether",
  values: () => [1],
  keys: () => ["USDT"],
  checked: (a, b) => `${a}` === `${b}`,
});
const useCreateWalletApi = () => {
  return useMutation(async (data) => {
    try {
      const sendData = {
        name: data.name,
        address: data.address,
        type: data.type,
        description: data?.description,
        active: data?.active,
        priority: data?.priority,
      };
      const res = await http.post(URL.WALLET, sendData, getAuthHeader());
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useCreateWalletForm = () => {
  const schema = yup
    .object({
      priority: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .typeError("Priority must be a number")
        .required(),
      name: yup.string().max(150).required(), //it is options
      address: yup.string().required(), //it is options
      description: yup.string().max(1500).optional(), //it is options
      type: yup.mixed().required(), //it is options

      // active: data?.active,
    })
    .required();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const _ = useCreateWalletApi();
  return useMemo(() => {
    return [
      {
        register,
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};

export const useEditWalletApi = () => {
  return useMutation(async (data) => {
    try {
      const sendData = {
        priority: parseInt(data.priority || 1),
        active: data.active,
        name: data?.name,
        address: data?.address,
        description: data?.description,
        type: data?.type,
      };
      console.table(sendData);
      const res = await http.put(
        URL.WALLET + "/" + data?._id,
        sendData,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
export const useEditWalletForm = (data) => {
  const schema = yup
    .object({
      priority: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .typeError("Priority must be a number")
        .optional(),
      name: yup.string().max(150).optional(), //it is options
      address: yup.string().optional(), //it is options
      description: yup.string().max(1500).optional(), //it is options
      type: yup.mixed().optional(), //it is options
    })
    .optional();
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      priority: data.priority,
      active: data.active,
      name: data?.name,
      address: data?.address,
      description: data?.description,
      type: data?.type,
    },
  });
  const _ = useEditWalletApi();
  return useMemo(() => {
    return [
      {
        register,
        setValue,
        getValue: getValues,
        errors,
        control,
      },
      handleSubmit,
      _,
    ];
  }, [register, handleSubmit, errors]);
};
export const useListWalletClient = () => {
  return usePagesWithLoadeMore(URL.WALLET, false, 0, 15, {});
};
export const useListWallet = () => {
  return usePagesWithLoadeMore(URL.MY_WALLET, true, 0, 15, {});
};
export const useDeleteWallet = () => {
  return useMutation(async (data) => {
    try {
      const res = await http.delete(
        URL.WALLET + "/" + data?._id,
        getAuthHeader()
      );
      return res?.data;
    } catch (error) {
      if (error?.response?.data) return error.response.data;
      return {
        error: true,
        message: error?.message,
      };
    }
  });
};
