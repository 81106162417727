import React from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Icon from "../Icon";

const Balance = ({ className, value, background }) => {
  const size= 20;
  return value > 0 ? (
    <div
      className={cn(
        styles.positive,
        { [styles.background]: background },
        className
      )}
    >
      <Icon name="arrow-top" size={size}/>
      {value}%
    </div>
  ) : value < 0 ? (
    <>
      <div
        className={cn(
          styles.negative,
          { [styles.background]: background },
          className
        )}
      >
        <Icon name="arrow-bottom" size={size}/>
        {String(value).slice(1)}%
      </div>
    </>
  ) : (
    <div
      className={cn(
        styles.zero,
        { [styles.background]: background },
        className
      )}
    >
      <Icon name="arrows-up-down" size={size} />
      {value}%
    </div>
  );
};

export default Balance;
